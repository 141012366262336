import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { useAddToast } from "@cortexglobal/rla-components";

import {
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
} from "../../../components/FormComponents";

import LoadingPage from "../../../components/LoadingPage";
import ErrorPage from "../../../components/ErrorPage";

const Schema = Yup.object().shape({
    uuid: Yup.string().nullable(true),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
});

const UserForm = ({ user, refreshUser, successCallback }) => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);

    const addToast = useAddToast();

    const handleOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);

        const submit = !user.uuid
            ? axios.post(`/api/v1/users`, values)
            : axios.put(`/api/v1/users/${user.uuid}`, values);

        submit
            .then(({ data }) => {
                setLoaded(true);
                setError(false);
                refreshUser(data.data);

                setSubmitting(false);
                setSubmitSuccessful(true);

                addToast({
                    type: "success",
                    content: "User updated successfully.",
                    showFor: 5000,
                });

                successCallback();
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);

                addToast({
                    type: "alert",
                    content: "There was an error processing user details.",
                    showFor: 5000,
                });
            });
    };

    if (!loaded) {
        return <LoadingPage title="Loading user details" />;
    }

    if (error) {
        return (
            <ErrorPage>
                <p>
                    An error occurred whilst loading the user details, please
                    try again later.
                </p>
            </ErrorPage>
        );
    }

    return (
        <Formik
            initialValues={{
                uuid: user.uuid,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
            }}
            validationSchema={Schema}
            onSubmit={handleOnSubmit}
        >
            {({ errors, touched }) => {
                return (
                    <Form>
                        <h3>User Details</h3>

                        <StyledLabel htmlFor="first_name">
                            First Name <Required />
                        </StyledLabel>
                        <StyledField id="first_name" name="first_name" />
                        {errors.first_name && touched.first_name ? (
                            <Error>
                                {errors.first_name || "First name is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="last_name">
                            Last Name <Required />
                        </StyledLabel>
                        <StyledField id="last_name" name="last_name" />
                        {errors.last_name && touched.last_name ? (
                            <Error>
                                {errors.last_name || "Last name is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="email">
                            Email <Required />
                        </StyledLabel>
                        <StyledField id="email" name="email" />
                        {errors.email && touched.email ? (
                            <Error>{errors.email || "Email is required"}</Error>
                        ) : null}

                        {submitError && <Error>{submitError}</Error>}
                        {submitSuccessful && (
                            <div
                                style={{
                                    padding: "3rem 0 0",
                                }}
                            >
                                <h4>User updated successfully</h4>
                            </div>
                        )}
                        <SubmitSection>
                            <SubmitButton type="submit" disabled={submitting}>
                                Submit
                            </SubmitButton>
                            {submitting && <Loader />}
                        </SubmitSection>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UserForm;
