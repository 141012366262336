import React from "react";
import styled, { keyframes } from "styled-components";

export const Table = styled.table`
    margin: 0 auto;
    padding: 0;
    width: 100%;
    min-width: 400px;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
`;

export const TableRow = styled.tr`
    transition: height 500ms;

    &:hover td {
        background-color: #f2f2f2;
    }

    &:last-of-type {
        border-bottom: 3px solid #f2f2f2;
    }

    &:hover {
        background-color: #f2f2f2;
    }
`;

export const TableData = styled.td`
    padding: 10px;
    border-top: 1px solid #f2f2f2;
    margin: 0;

    &.center {
        text-align: center;
    }

    &.heading {
        font-weight: bold;
        width: 20%;
    }
`;

export const TableHeader = styled.th`
    text-align: left;
    padding: 20px;
    font-weight: bold;
    margin: 0;
    position: relative;
    background-color: #f2f2f2;

    &:first-of-type {
        border-top-left-radius: 5px;
    }

    &:last-of-type {
        border-top-right-radius: 5px;
    }

    &.center {
        text-align: center;
    }

    ${({ canOrder }) =>
        canOrder &&
        `
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    `}
`;
