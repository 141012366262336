import React from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import Error from "./Error";

const ErrorPage = (props) => {
    return (
        <div style={{ padding: "2em 0 1em" }}>
            <Row style={{ textAlign: "center", padding: "2em 0 1em" }}>
                <Column>
                    <h1>{props.title || "Oops, something went wrong."}</h1>
                </Column>
            </Row>
            <Row>
                <Column>
                    <Error>{props.children || "Please try again later."}</Error>
                </Column>
            </Row>
        </div>
    );
};

export default ErrorPage;
