import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import {
    useLocation
} from "react-router-dom";

import { CubeLoader } from "../index";

import CompanySales from "./CompanySales";
import queryString from "querystring";
import CompanyContacts from "./CompanyContacts";
import CompanyRewardOrders from "./CompanyRewardOrders";
import CompanyLinkedAccounts from "./CompanyLinkedAccounts";
import CompanyCommunications from "./CompanyCommunications";

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div`
    padding: 1em;
`;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const BoldTd = styled.td`
    font-weight: bold;
    padding-right: 1em;
`;

const PanelSection = styled.div`
    padding: 2em;
`;

const Details = styled.div`
    padding-right: 1.2rem
`;

const CompanyContainer = styled.div`
    display: flex;
`;

const CompanyActions = styled.div``;


const ViewCompany = ({ match }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(true);
    const [companyData, setCompanyData] = useState();

    const {
        salesPerPage,
        salesPage,
        contactsPerPage,
        contactsPage,
        communicationsPage,
        communicationsPerPage
    } = queryString.parse(useLocation().search.substring(1));

    const [salesData, setSalesData] = useState([]);
    const [salesMeta, setSalesMeta] = useState();
    const [salesLoading, setSalesLoading] = useState(false);
    const [salesPerPageValue, setSalesPerPageValue] = useState(salesPerPage || 20);
    const [selectedSalesPageValue, setSelectedSalesPageValue] = useState(salesPage === undefined ? 1 : salesPage);

    const [communicationData, setCommunicationData] = useState([]);
    const [communicationMeta, setCommunicationMeta] = useState([]);
    const [communicationsLoading, setCommunicationsLoading] = useState(false);
    const [communicationsPerPageValue, setCommunicationsPerPageValue] = useState(communicationsPerPage || 20);
    const [selectedCommunicationsPageValue, setSelectedCommunicationPageValue] = useState(communicationsPage === undefined ? 1 : communicationsPage);

    const [contactsData, setContactsData] = useState([]);
    const [contactsMeta, setContactsMeta] = useState();
    const [contactsLoading, setContactsLoading] = useState(false);
    const [contactsPerPageValue, setContactsPerPageValue] = useState(contactsPerPage || 20);
    const [selectedContactsPageValue, setSelectedContactsPageValue] = useState(contactsPage === undefined ? 1 : contactsPage);

    const [rewardOrdersData, setRewardOrdersData] = useState([]);
    const [rewardOrdersMeta, setRewardOrdersMeta] = useState();
    const [rewardOrdersLoading, setRewardOrdersLoading] = useState(false);
    const [rewardOrdersPerPageValue, setRewardOrdersPerPageValue] = useState(contactsPerPage || 20);
    const [selectedRewardOrdersPageValue, setSelectedRewardOrdersPageValue] = useState(contactsPage === undefined ? 1 : contactsPage);

    const [linkedAccountsData, setLinkedAccountsData] = useState([]);
    const [linkedAccountsMeta, setLinkedAccountsMeta] = useState();
    const [linkedAccountsLoading, setLinkedAccountsLoading] = useState(false);
    const [linkedAccountsPerPageValue, setLinkedAccountsPerPageValue] = useState(contactsPerPage || 20);
    const [selectedLinkedAccountsPageValue, setSelectedLinkedAccountsPageValue] = useState(contactsPage === undefined ? 1 : contactsPage);

    const getSales = (newData) => {
        setSalesLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/invoices`, { params: {
                    page: selectedSalesPageValue,
                    per_page: salesPerPageValue ? salesPerPageValue : 20,
                    ...newData
                }})
            .then(({ data }) => {
                setSalesData(data.data);
                setSalesMeta(data.meta);
                setSalesLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting sales data.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const getCommunications = (newData) => {
        setCommunicationsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/communications`, { params: {
                    page: selectedCommunicationsPageValue,
                    per_page: communicationsPerPageValue ? communicationsPerPageValue : 20,
                    ...newData
                }})
            .then(({ data }) => {
                setCommunicationData(data.data);
                setCommunicationMeta(data.meta);
                setCommunicationsLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting communication data.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const getContacts = (newData) => {
        setContactsLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/contacts`, { params: {
                    page: selectedContactsPageValue,
                    per_page: contactsPerPageValue ? contactsPerPageValue : 20,
                    ...newData
                }})
            .then(({ data }) => {
                setContactsData(data.data);
                setContactsMeta(data.meta);
                setContactsLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting contacts.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const getRewardOrders = (newData) => {
        setRewardOrdersLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/reward-orders`, { params: {
                    page: selectedRewardOrdersPageValue,
                    per_page: rewardOrdersPerPageValue ? rewardOrdersPerPageValue : 20,
                    ...newData
                }})
            .then(({ data }) => {
                setRewardOrdersData(data.data);
                setRewardOrdersMeta(data.meta);
                setRewardOrdersLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting reward orders.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const getLinkedAccounts = (newData) => {
        setRewardOrdersLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}/accounts`, { params: {
                    page: selectedLinkedAccountsPageValue,
                    per_page: linkedAccountsPerPageValue ? linkedAccountsPerPageValue : 20,
                    ...newData
                }})
            .then(({ data }) => {
                setLinkedAccountsData(data.data);
                setLinkedAccountsMeta(data.meta);
                setLinkedAccountsLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting linked accounts.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const getCompany = () => {
        setLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}`)
            .then(({ data }) => {
                setCompanyData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting the company.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getCompany();
        getSales();
        getCommunications();
        getContacts();
        getRewardOrders();
        getLinkedAccounts();
    }, []);

    const setParams = (newData) => {
        const data = {
            sales_per_page: salesPerPageValue,
            sales_page: selectedSalesPageValue,
            contacts_per_page: contactsPerPageValue,
            contacts_page: selectedContactsPageValue,
            reward_orders_per_page: rewardOrdersPerPageValue,
            reward_orders_page: selectedContactsPageValue,
            linked_accounts_per_page: linkedAccountsPerPageValue,
            linked_accounts_page: selectedLinkedAccountsPageValue,
            communications_per_page: communicationsPerPageValue,
            communications_page: selectedCommunicationsPageValue,
            ...newData
        };

        history.push({
            search: `?salesPage=${data.sales_page}&salesPerPage=${data.sales_per_page}&contactsPage=${data.contacts_page}&contactsPerPage=${data.contacts_per_page}&rewardOrdersPage=${data.reward_orders_page}&rewardOrdersPerPage=${data.reward_orders_per_page}&linkedAccountsPage=${data.linked_accounts_page}&linkedAccountsPerPage=${data.linked_accounts_per_page}&communicationsPage=${data.communications_page}&communicationsPerPage=${data.communications_per_page}`
        });
    }

    const handleSalesPerPage = ({ value }) => {
        if (value) {
            setSalesPerPageValue(value);

            setParams({
                sales_per_page: value,
                sales_page: 1
            });
            getSales({
                per_page: value,
                page: 1
            });
        }
    }

    const handleSalesPageChange = ({ selected }) => {
        setSelectedSalesPageValue(selected);

        setParams({
            sales_page: selected + 1
        });
        getSales({
            page: selected + 1
        });
    }

    const handleContactsPerPage = ({ value }) => {
        if (value) {
            setContactsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1
            });
            getContacts({
                per_page: value,
                page: 1
            });
        }
    }

    const handleContactsPageChange = ({ selected }) => {
        setSelectedContactsPageValue(selected);

        setParams({
            contacts_page: selected + 1
        });
        getContacts({
            page: selected + 1
        });
    }

    const handleRewardOrdersPerPage = ({ value }) => {
        if (value) {
            setRewardOrdersPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1
            });
            getRewardOrders({
                per_page: value,
                page: 1
            });
        }
    }

    const handleRewardOrdersPageChange = ({ selected }) => {
        setSelectedRewardOrdersPageValue(selected);

        setParams({
            contacts_page: selected + 1
        });
        getRewardOrders({
            page: selected + 1
        });
    }

    const handleLinkedAccountsPerPage = ({ value }) => {
        if (value) {
            setLinkedAccountsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1
            });
            getLinkedAccounts({
                per_page: value,
                page: 1
            });
        }
    }

    const handleCommunicationsPerPage = ({ value }) => {
        if (value) {
            setLinkedAccountsPerPageValue(value);

            setParams({
                contacts_per_page: value,
                contacts_page: 1
            });
            getLinkedAccounts({
                per_page: value,
                page: 1
            });
        }
    }

    const handleLinkedAccountsPageChange = ({ selected }) => {
        setSelectedLinkedAccountsPageValue(selected);

        setParams({
            contacts_page: selected + 1
        });
        getLinkedAccounts({
            page: selected + 1
        });
    }

    const handleCommunicationsPageChange = ({ selected }) => {
        setSelectedCommunicationPageValue(selected);

        setParams({
            contacts_page: selected + 1
        });
        getCommunications({
            page: selected + 1
        });
    }
    if (loading || salesLoading || contactsLoading || rewardOrdersLoading || linkedAccountsLoading) return <CubeLoader />

    return (
        <>
            <PageTitle title={companyData.trade_team_customer}>
                <Link to="/companies">
                    <Button>Back to companies</Button>
                </Link>
            </PageTitle>

            <CompanyContainer>
                <CompanyActions>
                    <Row>
                        <Column>
                            <Panel style={{ padding: '0 0 1rem 0', marginTop: '2em' }}>
                                <Tabordion current={0}>
                                    {!!rewardOrdersData.length &&
                                    <PanelSection
                                        heading="Reward Orders"
                                        style={{ padding: '0' }}
                                    >
                                        <CompanyRewardOrders
                                            handlePageChange={handleRewardOrdersPageChange}
                                            handlePerPage={handleRewardOrdersPerPage}
                                            perPageValue={rewardOrdersPerPageValue}
                                            selectedPageValue={selectedRewardOrdersPageValue}
                                            rewardOrders={rewardOrdersData}
                                            meta={rewardOrdersMeta}
                                        />
                                    </PanelSection>
                                    }

                                    {!!salesData.length &&
                                    <PanelSection
                                        heading="Contacts"
                                        style={{ padding: '0' }}
                                    >
                                        <CompanyContacts
                                            handlePageChange={handleContactsPageChange}
                                            handlePerPage={handleContactsPerPage}
                                            perPageValue={contactsPerPageValue}
                                            selectedPageValue={selectedContactsPageValue}
                                            contacts={contactsData}
                                            meta={contactsMeta}
                                        />
                                    </PanelSection>
                                    }

                                    {!!linkedAccountsData.length &&
                                    <PanelSection
                                        heading="Linked Accounts"
                                        style={{ padding: '0' }}
                                    >
                                        <CompanyLinkedAccounts
                                            handlePageChange={handleLinkedAccountsPageChange}
                                            handlePerPage={handleLinkedAccountsPerPage}
                                            perPageValue={linkedAccountsPerPageValue}
                                            selectedPageValue={selectedLinkedAccountsPageValue}
                                            linkedAccounts={linkedAccountsData}
                                            meta={linkedAccountsMeta}
                                        />
                                    </PanelSection>
                                    }

                                    {!!communicationData.length &&
                                    <PanelSection
                                        style={{ padding: '0' }}
                                        heading="Communications">
                                        <CompanyCommunications
                                            handlePageChange={handleCommunicationsPageChange}
                                            handlePerPage={handleContactsPerPage}
                                            perPageValue={communicationsPerPageValue}
                                            selectedPageValue={selectedCommunicationsPageValue}
                                            communications={communicationData}
                                            meta={communicationMeta}
                                        />
                                    </PanelSection>
                                    }

                                    {!!salesData.length &&
                                    <PanelSection
                                        style={{ padding: '0' }}
                                        heading="Sales">
                                        <CompanySales
                                            handlePageChange={handleSalesPageChange}
                                            handlePerPage={handleSalesPerPage}
                                            perPageValue={salesPerPageValue}
                                            selectedPageValue={selectedSalesPageValue}
                                            sales={salesData}
                                            meta={salesMeta}
                                        />
                                    </PanelSection>
                                    }
                                </Tabordion>
                            </Panel>
                        </Column>
                    </Row>
                </CompanyActions>
                {companyData &&
                <Details>
                    <StyledPanel>
                        <PanelHeader>
                            Company Details
                        </PanelHeader>
                        <PanelBody>
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <BoldTd>Distrigo Number:</BoldTd>
                                    <td>{companyData.trade_team_id}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Trade Team Customer:</BoldTd>
                                    <td>{companyData.trade_team_customer}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Customer Type:</BoldTd>
                                    <td>{companyData.trade_team_customer_type}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Address:</BoldTd>
                                    <td>{companyData.address_1}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Postcode:</BoldTd>
                                    <td>{companyData.postcode}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Total Sales:</BoldTd>
                                    <td>{companyData.counts.total_sales}</td>
                                </tr>
                                </tbody>
                            </table>
                        </PanelBody>
                    </StyledPanel>
                    {companyData.primary_contact &&
                    <StyledPanel>
                        <PanelHeader>
                            Primary Contact
                        </PanelHeader>
                        <PanelBody>
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <BoldTd>Name:</BoldTd>
                                    <td>{companyData.primary_contact.first_name} {companyData.primary_contact.last_name}</td>
                                </tr>
                                {companyData.primary_contact.job_title &&
                                <tr>
                                    <BoldTd>Job Title:</BoldTd>
                                    <td>{companyData.primary_contact.job_title}</td>
                                </tr>
                                }
                                <tr>
                                    <BoldTd>Email:</BoldTd>
                                    <td>{companyData.primary_contact.email}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Phone Number:</BoldTd>
                                    <td>{companyData.primary_contact.phone}</td>
                                </tr>
                                <tr>
                                    <BoldTd>Mobile Number:</BoldTd>
                                    <td>{companyData.primary_contact.mobile_phone}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div style={{paddingTop: '1rem'}}>
                                <Link to={`/contacts/${companyData.primary_contact.id}`}>
                                    <Button>View Contact</Button>
                                </Link>
                            </div>
                        </PanelBody>
                    </StyledPanel>
                    }
                </Details>
                }
            </CompanyContainer>

        </>
    );
};

export default ViewCompany;
