import React, { useEffect, useState } from "react";
import {Button, Modal, Pagination, SelectField, useAddToast} from "@cortexglobal/rla-components";
import axios from "axios";
import { CubeLoader } from "../index";
import queryString from "querystring";
import {
    useLocation
} from "react-router-dom";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
} from "../table/StyledTable";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanySales = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    sales,
    meta,
}) => {
    const [lineData, setLineData] = useState();

    return (
        <>
            {!lineData &&
                <>
                <StyledTable
                    className="sales-table"
                >
                    <thead>
                    <tr>
                        <StyledTableHeader
                            name="invoice_date"
                        >
                            Invoice Date
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="dealer_code"
                        >
                            Dealer Code
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="total_cost"
                        >
                            Total Cost
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="total_price"
                        >
                            Total Price
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="total_quantity"
                        >
                            Total Quantity
                        </StyledTableHeader>
                        <StyledTableHeader/>
                    </tr>
                    </thead>
                    <tbody>
                    {sales.map(({invoice}) => (
                        <StyledTableRow
                            key={invoice.id}
                        >
                            <td>
                                {new Date(invoice.invoice_date).toDateString()}
                            </td>
                            <td>
                                {invoice.dealer}
                            </td>
                            <td>
                                {invoice.total_cost}
                            </td>
                            <td>
                                {invoice.total_price}
                            </td>
                            <td>
                                {invoice.total_quantity}
                            </td>
                            <td>
                                <Button onClick={() => {
                                    setLineData(invoice.invoice_line)
                                }}>View Lines</Button>
                            </td>
                        </StyledTableRow>
                    ))}
                    </tbody>
                </StyledTable>
                <PaginationContainer>
                    <PerPageContainer>
                        Per Page:
                        <SelectField
                            name="perPage"
                            style={{ margin: '0 0 0 1em' }}
                            value={perPageValue}
                            options={[
                                {value: 10, text: '10'},
                                {value: 20, text: '20'},
                                {value: 30, text: '30'},
                                {value: 40, text: '40'},
                                {value: 50, text: '50'},
                            ]}
                            onChange={handlePerPage}
                        />
                    </PerPageContainer>
                    <Pagination
                        currentPage={selectedPageValue - 1}
                        total={meta.total}
                        pageCount={meta.last_page}
                        onPageChange={handlePageChange}
                        previousLabel="&laquo;"
                        nextLabel="&raquo;"
                    />
                    <TotalContainer>Total: {meta.total}</TotalContainer>
                </PaginationContainer>
                </>
            }
            {lineData &&
            <>
                <div style={{ textAlign: 'right', paddingRight: '1rem' }}>
                    <Button onClick={() => setLineData()}>Back</Button>
                </div>
                <StyledTable
                    className="lines-table"
                >
                    <thead>
                    <tr>
                        <StyledTableHeader
                            name="dealer_name"
                        >
                            Dealer Name
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="account_number"
                        >
                            Account Number
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="category"
                        >
                            Category
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="sub_category"
                        >
                            Subcategory
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="invoice_date"
                        >
                            Invoice Date
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="total_cost"
                        >
                            Total Cost
                        </StyledTableHeader>
                        <StyledTableHeader
                            name="part_number"
                        >
                            Part Number
                        </StyledTableHeader>
                        <StyledTableHeader/>
                    </tr>
                    </thead>
                    <tbody>
                    {lineData.map(line => (
                        <StyledTableRow
                            key={line.invoice_number}
                        >
                            <td>
                                {line.dealer}
                            </td>
                            <td>
                                {line.account_number}
                            </td>
                            <td>
                                {line.category_id}
                            </td>
                            <td>
                                {line.sub_category_id}
                            </td>
                            <td>
                                {new Date(line.invoice_date).toDateString()}
                            </td>
                            <td>
                                {line.total_cost}
                            </td>
                            <td>
                                {line.part_number}
                            </td>

                        </StyledTableRow>
                    ))}
                    </tbody>
                </StyledTable>
            </>
            }
        </>
    );
};

export default CompanySales;