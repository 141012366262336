import React, { useState } from "react";
import axios from "axios";
import { isEmpty } from "lodash";

import { Modal, useAddToast } from "@cortexglobal/rla-components";
import { Loader, SubmitButton } from "../../../components/FormComponents";

import ModelParagraph from "./components/ModelParagraph";
import ModalErrorMessage from "./components/ModalErrorMessage";
import ModalButtons from "./components/ModelButtons";

const RenewalModal = ({
    registration,
    refreshRegistration,
    visible,
    setVisible,
    maxWidth = "50%",
    setUpdating,
}) => {
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const handleRenewal = (registration) => {
        setLoading(true);
        setUpdating(true);

        return axios
            .post(`api/v1/registrations/${registration.uuid}/renew`)
            .then(({ data }) => {
                refreshRegistration(data.data);
                setVisible(false);

                addToast({
                    type: "success",
                    content: "Registration successfully renewed.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setValidationErrors(data.errors);
                    }

                    if (data.message !== undefined) {
                        setErrorMessage(data.message);
                    }
                }

                addToast({
                    type: "alert",
                    content: "There was an error renewing this registration.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setLoading(false);
                setUpdating(false);
            });
    };

    return (
        <Modal
            visible={visible}
            onClose={() => setVisible(false)}
            maxWidth={maxWidth}
        >
            <div style={{ padding: "2rem" }}>
                <h3>Registration Renewal</h3>

                <ModelParagraph>
                    This will renew the registration for another two years.
                </ModelParagraph>
                <ModelParagraph>
                    Please note: this will automatically dispatch a renewal
                    confirmation email to the registered email address.
                </ModelParagraph>

                {!isEmpty(errorMessage) && (
                    <ModalErrorMessage>{errorMessage}</ModalErrorMessage>
                )}

                <ModalButtons>
                    <SubmitButton
                        style={{ marginRight: "1rem" }}
                        onClick={() => {
                            setVisible(false);
                        }}
                    >
                        Cancel
                    </SubmitButton>
                    <SubmitButton
                        hasArrow
                        color="green"
                        onClick={() => handleRenewal(registration)}
                    >
                        Renew
                    </SubmitButton>
                    {loading && <Loader />}
                </ModalButtons>
            </div>
        </Modal>
    );
};

export default RenewalModal;
