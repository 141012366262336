import React from "react";
import styled from "styled-components";

const StepBullet = styled.div`
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({active}) => active ? "#d5001c" : "#c7c7c7"};
    color: ${({active}) => active ? "white" : "black"};
    font-weight: bold;
    margin-bottom: 5px;
`;

const StepContainer = styled.div`
    text-align: center;
    display: inline-block;
    font-size: 1.1em;
    position: relative;
    
    display: flex;
    flex-direction: column;
    
    ${({ last }) => last && `
        align-items: end;
    `}
    
    ${({ clickable }) => clickable && `
        cursor: pointer;
    `}
`;

const StepText = styled.span`
    font-size: 0.7em;
`;

const Step = ({
    number,
    text,
    active,
    clickable,
    backFunction
}) => {
    return (
        <StepContainer
            onClick={() => {
                if (clickable) {
                    backFunction();
                }
            }}
            clickable={clickable}
            last={number === 4}>
            <StepBullet active={active}>
                <span style={{ zIndex: "10" }}>
                    {number}
                </span>
            </StepBullet>
            <StepText>
                {text}
            </StepText>
        </StepContainer>
    )
};

const StepsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    position: absolute;
    width: 100%;
    z-index: 1;
`;

const LineContainer = styled.div`
    display: flex;  
    position: absolute;
    width: 100%;
    top: 16px;
`;

const Line = styled.div`
    height: 2px;
    background: ${({active}) => active ? "#d5001c" : "#c7c7c7"};
    width: 32%;
`;

const ProgressContainer = styled.div`
    position: relative;
    height: 100px;
`;

const ProgressBar = ({ step, setStep }) => {
    return (
        <>
            <ProgressContainer>
                <StepsContainer>
                    <Step
                        active={step >= 1}
                        clickable={step >= 2}
                        backFunction={() => setStep(1)}
                        number={1}
                        text="Vehicle"
                    />
                    <Step
                        active={step >= 2}
                        clickable={step >= 3}
                        backFunction={() => setStep(2)}
                        number={2}
                        text="Details"
                    />
                    <Step
                        active={step >= 3}
                        clickable={step >= 4}
                        backFunction={() => setStep(3)}
                        number={3}
                        text="Centre"
                    />
                    <Step
                        active={step >= 4}
                        backFunction={() => {}}
                        number={4}
                        text="Confirmation"
                    />
                </StepsContainer>

                <LineContainer>
                    <Line active={step >= 2} />
                    <Line active={step >= 3} />
                    <Line active={step >= 4} />
                </LineContainer>
            </ProgressContainer>
        </>
    );
};

export default ProgressBar;