import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";

import Logo from "../../components/Logo";
import LoadingPage from "../../components/LoadingPage";

import {
    Container,
    StyledField,
    StyledLabel,
    SmallLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    FlexBox,
    Required,
    LogoContainer,
    CenterSelect,
} from "../../components/FormComponents";

import {
    UnstyledButton,
    RadioCheck,
    CustomRadio,
    RadioContainer,
    Checkmark,
    ThankyouBanner,
    TermsConditions,
    RadioContainerOuter,
    InfoButton,
    InfoContainer,
    PopperElement,
} from "../../components/registration/registrationComponents";
import { usePopper } from "react-popper";
import ErrorPage from "../../components/ErrorPage";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address_1: Yup.string().required("Address is required"),
    address_2: Yup.string(),
    town: Yup.string().required("Town is required"),
    county: Yup.string().required("County is required"),
    postcode: Yup.string().required("Postcode is required"),
    telephone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Telephone is required"),
    email: Yup.string().required("Email is required"),
    centre_uuid: Yup.string().required("Centre is required"),
    registration: Yup.string().required("Registration is required"),
    confirmed_membership: Yup.string().required(
        "Confirmed membership is required"
    ),
    privacy_policy: Yup.string().required("Privacy policy is required"),
    vin: Yup.string().required("VIN Number is required"),
    model: Yup.string().required("Model is required"),
    year: Yup.string().required("Year is required"),
});

const RegisterForm = () => {
    let history = useHistory();

    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const [addressList, setAddressList] = useState([]);

    const [manuallyEnterAddress, setManuallyEnterAddress] = useState(false);
    const [chooseDifferentCentre, setChooseDifferentCentre] = useState(false);

    const [settings, setSettings] = useState({
        state: "loading",
        data: {},
        distancesLoaded: false,
    });

    const [postcodeError, setPostcodeError] = useState(false);
    const [postcodeLoading, setPostcodeLoading] = useState(false);

    const [vinCorrect, setVinCorrect] = useState(false);
    const [vinLoading, setVinLoading] = useState(false);
    const [vinDetails, setVinDetails] = useState();
    const [vinError, setVinError] = useState("");
    const [vinSearched, setVinSearched] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const [isInfoShowing, setIsInfoShowing] = useState(false);
    const wrapperRef = useRef(null);
    const buttonWrapperRef = useRef(null);

    const [centreDetails, setCentreDetails] = useState({
        centre: {
            email: "",
        },
        membership_start_at: "",
    });

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                buttonWrapperRef.current &&
                !buttonWrapperRef.current.contains(event.target)
            ) {
                setIsInfoShowing(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        axios
            .get(`/api/v1/register/settings`)
            .then(({ data }) => {
                data.state = "loaded";
                setSettings({ ...settings, ...data });
            })
            .catch(({ response: { data } }) => {
                data.state = "error";
                setSettings({ ...settings, ...data });
            });
    };

    const handleOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        axios
            .post(`/api/v1/register`, {
                ...values,
                registration: values.registration.toUpperCase(),
            })
            .then(({ data }) => {
                setCentreDetails(data.data);
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    const enterManually = (e) => {
        e.preventDefault();

        setManuallyEnterAddress(true);
    };

    const closeEnterManually = (e) => {
        e.preventDefault();

        setManuallyEnterAddress(false);
    };

    const showDifferentCentres = (e) => {
        e.preventDefault();

        setChooseDifferentCentre(true);
    };

    const hideDifferentCentres = (e) => {
        e.preventDefault();

        setChooseDifferentCentre(false);
    };

    const handlePostcodeSearch = (postcode) => {
        setPostcodeError(false);
        setPostcodeLoading(true);

        axios
            .get(`/api/v1/register/postcode/${postcode}`)
            .then(({ data }) => {
                setAddressList(data.addresses);
                setPostcodeLoading(false);
                setSettings({
                    ...settings,
                    data: {
                        ...settings.data,
                        centres: data.centres.sort(
                            (a, b) => a.distance - b.distance
                        ),
                    },
                    distancesLoaded: true,
                });
            })
            .catch(({ response }) => {
                setPostcodeLoading(false);
                setPostcodeError(true);
            });
    };

    const handleVehicleSearch = (vin) => {
        if (vin === "") {
            return;
        }
        setVinLoading(true);
        setVinError("");

        return axios
            .get(`/api/v1/register/vin?vin=${vin}`)
            .then(({ data }) => {
                setVinDetails(data.data);
                setVinLoading(false);
                setVinCorrect(true);
                setVinSearched(true);
                return data.data;
            })
            .catch(({ response: { data } }) => {
                setVinLoading(false);
                setVinCorrect(false);

                if (data.message === "The given data was invalid.") {
                    setVinError(
                        "Sorry, we've not been able to verify your VIN Number, please manually enter your vehicle details."
                    );
                } else {
                    setVinError(data.message);
                }

                setVinSearched(true);
            });
    };

    const render = (state) => {
        if (state === "loading") {
            return <LoadingPage />;
        }

        if (state === "error") {
            return (
                <ErrorPage>
                    There was an error whilst loading the Porsche Classic
                    Register application form, please call your local Porsche
                    Centre for assistance or try again later.
                </ErrorPage>
            );
        }

        return (
            <>
                {submitSuccessful ? (
                    <>
                        <ThankyouBanner />
                        <Container>
                            <div style={{ padding: "3rem 0 " }}>
                                <h1>Thank you for signing up</h1>
                                <p>
                                    You are one step closer to enjoying the
                                    benefits of the Porsche Classic Register.
                                    Once we have finished reviewing your request
                                    we will issue your membership pack which
                                    will arrive in for 6-8 weeks.
                                </p>
                                {/*<p>*/}
                                {/*    Would you also like to receive our Porsche*/}
                                {/*    Classic newsletter?{" "}*/}
                                {/*    <a*/}
                                {/*        style={{ color: "black" }}*/}
                                {/*        href="https://contact.porsche.com/uk/accessoriesandservice/classic/newsletter/subscribe/"*/}
                                {/*    >*/}
                                {/*        Click here*/}
                                {/*    </a>*/}
                                {/*</p>*/}
                                <p>
                                    In the meantime, should you have any queries
                                    please do not hesitate to contact us on{" "}
                                    <a
                                        href={`mailto:${centreDetails.centre.email}`}
                                    >
                                        {centreDetails.centre.email}
                                    </a>
                                    .
                                </p>
                            </div>
                        </Container>

                        <TermsConditions>
                            <Container>
                                <p>
                                    &copy; 2021 All rights reserved to Porsche
                                    Cars Great Britain Limited and its
                                    licensors.&nbsp;
                                    <a
                                        style={{ color: "white" }}
                                        href="https://www.porsche.com/uk/privacy-policy/"
                                        target="_blank"
                                    >
                                        Privacy Notice
                                    </a>
                                    &nbsp;|&nbsp;
                                    <a
                                        style={{ color: "white" }}
                                        href="https://www.porsche.com/filestore.aspx/download.pdf?pool=uk&type=download&id=classic-register-termsandconditions&lang=none&filetype=default"
                                        target="_blank"
                                    >
                                        Terms and Conditions
                                    </a>
                                </p>
                            </Container>
                        </TermsConditions>
                    </>
                ) : (
                    <Container>
                        <>
                            <h1
                                className="title"
                                style={{ marginBottom: "3rem" }}
                            >
                                Signup to Porsche Classic Register
                            </h1>
                            <Formik
                                initialValues={{
                                    title: "",
                                    first_name: "",
                                    last_name: "",
                                    address_1: "",
                                    address_2: "",
                                    town: "",
                                    county: "",
                                    postcode: "",
                                    telephone: "",
                                    email: "",
                                    centre_uuid: "",
                                    vin: "",
                                    registration: "",
                                    confirmed_membership: "",
                                    privacy_policy: "",
                                    model: "",
                                    year: "",
                                }}
                                validationSchema={Schema}
                                onSubmit={handleOnSubmit}
                            >
                                {({
                                    errors,
                                    touched,
                                    setFieldValue,
                                    values,
                                }) => {
                                    const titles =
                                        state === "loading"
                                            ? ["Loading..."]
                                            : settings.data.titles;
                                    const centres =
                                        state === "loading"
                                            ? { "": "Loading..." }
                                            : settings.data.centres;
                                    const models =
                                        state === "loading"
                                            ? { "": "Loading..." }
                                            : settings.data.models;

                                    return (
                                        <Form>
                                            <h3>Personal Details</h3>

                                            <StyledLabel htmlFor="title">
                                                Title <Required />
                                            </StyledLabel>
                                            <StyledField
                                                error={
                                                    errors.title &&
                                                    touched.title
                                                }
                                                style={{ width: "auto" }}
                                                as="select"
                                                name="title"
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "title",
                                                        value.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Select</option>

                                                {titles.map((title) => {
                                                    return (
                                                        <option
                                                            value={title}
                                                            key={title}
                                                        >
                                                            {title}
                                                        </option>
                                                    );
                                                })}
                                            </StyledField>
                                            {errors.title && touched.title ? (
                                                <Error>{errors.title}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="first_name">
                                                First name <Required />
                                            </StyledLabel>
                                            <StyledField
                                                error={
                                                    errors.first_name &&
                                                    touched.first_name
                                                }
                                                id="first_name"
                                                name="first_name"
                                            />
                                            {errors.first_name &&
                                            touched.first_name ? (
                                                <Error>
                                                    {errors.first_name}
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="last_name">
                                                Last Name <Required />
                                            </StyledLabel>
                                            <StyledField
                                                error={
                                                    errors.last_name &&
                                                    touched.last_name
                                                }
                                                id="last_name"
                                                name="last_name"
                                            />
                                            {errors.last_name &&
                                            touched.last_name ? (
                                                <Error>
                                                    {errors.last_name}
                                                </Error>
                                            ) : null}

                                            <SmallLabel
                                                style={{
                                                    marginTop: "2em",
                                                    marginBottom: "-1em",
                                                }}
                                            >
                                                Please enter the address where
                                                you would like your membership
                                                pack sent to
                                            </SmallLabel>

                                            <StyledLabel htmlFor="postcode">
                                                Postcode <Required />
                                            </StyledLabel>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: "1em",
                                                }}
                                            >
                                                <StyledField
                                                    error={
                                                        errors.postcode &&
                                                        touched.postcode
                                                    }
                                                    style={{
                                                        maxWidth: "300px",
                                                        marginRight: "1rem",
                                                        marginBottom: "0",
                                                    }}
                                                    id="postcode"
                                                    name="postcode"
                                                />

                                                <SubmitButton
                                                    type="button"
                                                    hasArrow={true}
                                                    color="grey"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handlePostcodeSearch(
                                                            values.postcode
                                                        );
                                                    }}
                                                >
                                                    Find Address
                                                </SubmitButton>
                                                {postcodeLoading && <Loader />}
                                            </div>

                                            {postcodeError && (
                                                <Error>
                                                    Enter valid postcode
                                                </Error>
                                            )}

                                            {errors.postcode &&
                                            touched.postcode ? (
                                                <Error>{errors.postcode}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="address">
                                                Select Address <Required />
                                            </StyledLabel>
                                            <SmallLabel>
                                                Input postcode and press Find
                                                Address, then select your
                                                address from the list
                                            </SmallLabel>
                                            <StyledField
                                                disabled={!addressList.length}
                                                error={
                                                    (errors.address_1 &&
                                                        touched.address_1) ||
                                                    (errors.county &&
                                                        touched.county) ||
                                                    (errors.town &&
                                                        touched.town)
                                                }
                                                as="select"
                                                name="address"
                                                onChange={(value) => {
                                                    setFieldValue(
                                                        "address_1",
                                                        addressList[
                                                            value.target.value
                                                        ].line_1
                                                    );
                                                    setFieldValue(
                                                        "address_2",
                                                        addressList[
                                                            value.target.value
                                                        ].line_2
                                                    );
                                                    setFieldValue(
                                                        "town",
                                                        addressList[
                                                            value.target.value
                                                        ].town_or_city
                                                    );
                                                    setFieldValue(
                                                        "county",
                                                        addressList[
                                                            value.target.value
                                                        ].county
                                                    );
                                                }}
                                            >
                                                <option value="">Select</option>

                                                {addressList.map(
                                                    (address, key) => {
                                                        return (
                                                            <option
                                                                value={key}
                                                                key={`${address.line_1}, ${address.town_or_city}, ${address.county}`}
                                                            >
                                                                {`${address.line_1}, ${address.town_or_city}, ${address.county}`}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </StyledField>
                                            {manuallyEnterAddress ? (
                                                <SmallLabel>
                                                    <UnstyledButton
                                                        onClick={
                                                            closeEnterManually
                                                        }
                                                    >
                                                        Click here to close
                                                        manual address entry
                                                    </UnstyledButton>
                                                </SmallLabel>
                                            ) : (
                                                <SmallLabel>
                                                    Can't find your address?{" "}
                                                    <UnstyledButton
                                                        onClick={enterManually}
                                                    >
                                                        Click here to enter it
                                                        manually
                                                    </UnstyledButton>
                                                </SmallLabel>
                                            )}

                                            {manuallyEnterAddress && (
                                                <>
                                                    <StyledLabel htmlFor="address_1">
                                                        Address line 1{" "}
                                                        <Required />
                                                    </StyledLabel>
                                                    <StyledField
                                                        error={
                                                            errors.address_1 &&
                                                            touched.address_1
                                                        }
                                                        id="address_1"
                                                        name="address_1"
                                                    />
                                                    {errors.address_1 &&
                                                    touched.address_1 ? (
                                                        <Error>
                                                            {errors.address_1}
                                                        </Error>
                                                    ) : null}

                                                    <StyledLabel htmlFor="address_2">
                                                        Address line 2{" "}
                                                    </StyledLabel>
                                                    <StyledField
                                                        id="address_2"
                                                        name="address_2"
                                                    />

                                                    <StyledLabel htmlFor="town">
                                                        Town <Required />
                                                    </StyledLabel>
                                                    <StyledField
                                                        error={
                                                            errors.town &&
                                                            touched.town
                                                        }
                                                        id="town"
                                                        name="town"
                                                    />
                                                    {errors.town &&
                                                    touched.town ? (
                                                        <Error>
                                                            {errors.town}
                                                        </Error>
                                                    ) : null}

                                                    <StyledLabel htmlFor="county">
                                                        County <Required />
                                                    </StyledLabel>
                                                    <StyledField
                                                        error={
                                                            errors.county &&
                                                            touched.county
                                                        }
                                                        id="county"
                                                        name="county"
                                                    />
                                                    {errors.county &&
                                                    touched.county ? (
                                                        <Error>
                                                            {errors.county}
                                                        </Error>
                                                    ) : null}
                                                </>
                                            )}

                                            <StyledLabel htmlFor="telephone">
                                                Telephone <Required />
                                            </StyledLabel>
                                            <StyledField
                                                error={
                                                    errors.telephone &&
                                                    touched.telephone
                                                }
                                                id="telephone"
                                                name="telephone"
                                            />
                                            {errors.telephone &&
                                            touched.telephone ? (
                                                <Error>
                                                    {errors.telephone}
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="email">
                                                Email <Required />
                                            </StyledLabel>
                                            <StyledField
                                                error={
                                                    errors.email &&
                                                    touched.email
                                                }
                                                id="email"
                                                name="email"
                                            />
                                            {errors.email && touched.email ? (
                                                <Error>{errors.email}</Error>
                                            ) : null}

                                            <h3 style={{ marginTop: "2rem" }}>
                                                Centre Details
                                            </h3>

                                            {settings.distancesLoaded && (
                                                <>
                                                    <StyledLabel>
                                                        Centre Locator
                                                        <Required />
                                                    </StyledLabel>
                                                    <SmallLabel>
                                                        Select your preferred
                                                        centre from the list
                                                        below
                                                    </SmallLabel>

                                                    <RadioContainerOuter>
                                                        <RadioContainer>
                                                            <CustomRadio
                                                                onChange={() => {
                                                                    setFieldValue(
                                                                        "centre_uuid",
                                                                        settings
                                                                            .data
                                                                            .centres[0]
                                                                            .uuid
                                                                    );
                                                                }}
                                                                checked={
                                                                    values.centre_uuid ===
                                                                    settings
                                                                        .data
                                                                        .centres[0]
                                                                        .uuid
                                                                }
                                                                value={
                                                                    settings
                                                                        .data
                                                                        .centres[0]
                                                                        .uuid
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <RadioCheck className="checkmark" />
                                                            {
                                                                settings.data
                                                                    .centres[0]
                                                                    .name
                                                            }{" "}
                                                            (1st closest)
                                                        </RadioContainer>
                                                        {settings.data.centres[0].distance.toFixed(
                                                            1
                                                        )}{" "}
                                                        Miles
                                                    </RadioContainerOuter>
                                                    <RadioContainerOuter>
                                                        <RadioContainer>
                                                            <CustomRadio
                                                                onChange={() => {
                                                                    setFieldValue(
                                                                        "centre_uuid",
                                                                        settings
                                                                            .data
                                                                            .centres[1]
                                                                            .uuid
                                                                    );
                                                                }}
                                                                checked={
                                                                    values.centre_uuid ===
                                                                    settings
                                                                        .data
                                                                        .centres[1]
                                                                        .uuid
                                                                }
                                                                value={
                                                                    settings
                                                                        .data
                                                                        .centres[1]
                                                                        .uuid
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <RadioCheck className="checkmark" />
                                                            {
                                                                settings.data
                                                                    .centres[1]
                                                                    .name
                                                            }{" "}
                                                            (2nd closest)
                                                        </RadioContainer>
                                                        {settings.data.centres[1].distance.toFixed(
                                                            1
                                                        )}{" "}
                                                        Miles
                                                    </RadioContainerOuter>
                                                    <RadioContainerOuter>
                                                        <RadioContainer>
                                                            <CustomRadio
                                                                onChange={() => {
                                                                    setFieldValue(
                                                                        "centre_uuid",
                                                                        settings
                                                                            .data
                                                                            .centres[2]
                                                                            .uuid
                                                                    );
                                                                }}
                                                                checked={
                                                                    values.centre_uuid ===
                                                                    settings
                                                                        .data
                                                                        .centres[2]
                                                                        .uuid
                                                                }
                                                                value={
                                                                    settings
                                                                        .data
                                                                        .centres[2]
                                                                        .uuid
                                                                }
                                                                type="checkbox"
                                                            />
                                                            <RadioCheck className="checkmark" />
                                                            {
                                                                settings.data
                                                                    .centres[2]
                                                                    .name
                                                            }{" "}
                                                            (3rd closest)
                                                        </RadioContainer>
                                                        {settings.data.centres[2].distance.toFixed(
                                                            1
                                                        )}{" "}
                                                        Miles
                                                    </RadioContainerOuter>

                                                    {chooseDifferentCentre ? (
                                                        <UnstyledButton
                                                            onClick={
                                                                hideDifferentCentres
                                                            }
                                                        >
                                                            Close different
                                                            centre selector
                                                        </UnstyledButton>
                                                    ) : (
                                                        <UnstyledButton
                                                            onClick={
                                                                showDifferentCentres
                                                            }
                                                        >
                                                            Click here if you
                                                            would like to select
                                                            a different centre
                                                        </UnstyledButton>
                                                    )}
                                                </>
                                            )}

                                            {(!settings.distancesLoaded ||
                                                chooseDifferentCentre) && (
                                                <div
                                                    style={{
                                                        marginTop: "2rem",
                                                    }}
                                                >
                                                    <StyledLabel htmlFor="centre_uuid">
                                                        Select Centre
                                                    </StyledLabel>

                                                    <StyledField
                                                        value={
                                                            values.centre_uuid
                                                        }
                                                        as="select"
                                                        name="centre_uuid"
                                                        onChange={(value) =>
                                                            setFieldValue(
                                                                "centre_uuid",
                                                                value.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <option value="">
                                                            Select
                                                        </option>

                                                        {centres.map(
                                                            ({
                                                                uuid,
                                                                name,
                                                                distance,
                                                            }) => (
                                                                <option
                                                                    value={uuid}
                                                                    key={uuid}
                                                                >
                                                                    {name}{" "}
                                                                    {typeof distance !==
                                                                        "undefined" &&
                                                                        `${distance.toFixed(
                                                                            2
                                                                        )} miles`}
                                                                </option>
                                                            )
                                                        )}
                                                    </StyledField>
                                                </div>
                                            )}

                                            {errors.centre_uuid &&
                                            touched.centre_uuid ? (
                                                <Error
                                                    style={{
                                                        marginTop: "1rem",
                                                    }}
                                                >
                                                    {errors.centre_uuid}
                                                </Error>
                                            ) : null}

                                            <h3 style={{ marginTop: "2rem" }}>
                                                Vehicle Details
                                            </h3>

                                            <StyledLabel
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                htmlFor="vin"
                                            >
                                                <span>
                                                    VIN Number <Required />
                                                </span>
                                                <span
                                                    onMouseEnter={() =>
                                                        setIsInfoShowing(true)
                                                    }
                                                    onMouseLeave={() =>
                                                        setIsInfoShowing(false)
                                                    }
                                                    ref={buttonWrapperRef}
                                                >
                                                    <InfoButton
                                                        type="button"
                                                        ref={
                                                            setReferenceElement
                                                        }
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            setIsInfoShowing(
                                                                !isInfoShowing
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faInfo}
                                                        />
                                                    </InfoButton>
                                                </span>
                                                {isInfoShowing && (
                                                    <PopperElement
                                                        ref={setPopperElement}
                                                        style={styles.popper}
                                                        {...attributes.popper}
                                                    >
                                                        <InfoContainer
                                                            ref={wrapperRef}
                                                        >
                                                            The most popular
                                                            spot is on the front
                                                            of the dashboard on
                                                            the driver’s side.
                                                            You can often see it
                                                            if you stand outside
                                                            of the vehicle and
                                                            look through the
                                                            windshield. If you
                                                            are not finding it
                                                            there, then you
                                                            should check the
                                                            driver’s side door
                                                            pillar.
                                                        </InfoContainer>
                                                    </PopperElement>
                                                )}
                                            </StyledLabel>
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginBottom: "1rem",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "inline",
                                                            position:
                                                                "relative",
                                                            marginRight: "1rem",
                                                        }}
                                                    >
                                                        <StyledField
                                                            error={
                                                                errors.vin &&
                                                                touched.vin
                                                            }
                                                            successful={
                                                                vinCorrect
                                                            }
                                                            style={{
                                                                maxWidth:
                                                                    "300px",
                                                                marginBottom:
                                                                    "0",
                                                            }}
                                                            id="vin"
                                                            name="vin"
                                                        />
                                                        {vinCorrect && (
                                                            <Checkmark />
                                                        )}
                                                    </div>

                                                    <SubmitButton
                                                        type="button"
                                                        hasArrow
                                                        color={
                                                            vinCorrect
                                                                ? "green"
                                                                : "grey"
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleVehicleSearch(
                                                                values.vin
                                                            ).then((data) => {
                                                                if (data) {
                                                                    setFieldValue(
                                                                        "model",
                                                                        data.model
                                                                    );
                                                                    setFieldValue(
                                                                        "year",
                                                                        data.year_of_manufacture
                                                                    );
                                                                }
                                                            });
                                                        }}
                                                        disabled={
                                                            values.vin === ""
                                                        }
                                                    >
                                                        Find Vehicle
                                                    </SubmitButton>
                                                    {vinLoading && <Loader />}
                                                </div>

                                                {vinCorrect && (
                                                    <p>
                                                        <b>
                                                            {vinDetails.make}{" "}
                                                            {vinDetails.model}
                                                        </b>
                                                        ,{" "}
                                                        {
                                                            vinDetails.year_of_manufacture
                                                        }
                                                    </p>
                                                )}
                                            </div>

                                            {errors.vin && touched.vin ? (
                                                <Error>{errors.vin}</Error>
                                            ) : null}

                                            {vinError && (
                                                <Error>
                                                    {vinError ||
                                                        "Sorry we've not been able to verify your VIN Number, please manually enter your vehicle details."}
                                                </Error>
                                            )}

                                            <StyledLabel htmlFor="model">
                                                Model <Required />
                                            </StyledLabel>

                                            <StyledField
                                                disabled={!vinSearched}
                                                error={
                                                    errors.model &&
                                                    touched.model
                                                }
                                                id="model"
                                                name="model"
                                            />

                                            {errors.model && touched.model ? (
                                                <Error>{errors.model}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="year">
                                                Year <Required />
                                            </StyledLabel>

                                            <StyledField
                                                type="number"
                                                disabled={!vinSearched}
                                                error={
                                                    errors.year && touched.year
                                                }
                                                id="year"
                                                name="year"
                                            />

                                            {errors.model && touched.model ? (
                                                <Error>{errors.model}</Error>
                                            ) : null}

                                            <StyledLabel htmlFor="registration">
                                                Vehicle Registration Number{" "}
                                                <Required />
                                            </StyledLabel>

                                            <StyledField
                                                style={{
                                                    textTransform: "uppercase",
                                                }}
                                                error={
                                                    errors.registration &&
                                                    touched.registration
                                                }
                                                id="registration"
                                                name="registration"
                                            />

                                            {errors.registration &&
                                            touched.registration ? (
                                                <Error>
                                                    {errors.registration}
                                                </Error>
                                            ) : null}

                                            <p style={{ margin: "2rem 0 0 0" }}>
                                                Please read and accept the full
                                                Terms and Conditions & Privacy
                                                Notice in the links provided
                                                below
                                            </p>
                                            <RadioContainerOuter>
                                                <RadioContainer>
                                                    <CustomRadio
                                                        onChange={(value) => {
                                                            setFieldValue(
                                                                "confirmed_membership",
                                                                value.target
                                                                    .checked
                                                            );
                                                        }}
                                                        checked={
                                                            values.confirmed_membership
                                                        }
                                                        value={
                                                            values.confirmed_membership
                                                        }
                                                        type="checkbox"
                                                    />
                                                    <RadioCheck
                                                        square={true}
                                                        className="checkmark"
                                                    />
                                                    I agree to the{" "}
                                                    <a
                                                        href="https://www.porsche.com/filestore.aspx/download.pdf?pool=uk&type=download&id=classic-register-termsandconditions&lang=none&filetype=default"
                                                        target="_blank"
                                                    >
                                                        Terms and Conditions
                                                    </a>
                                                    <Required />
                                                </RadioContainer>
                                            </RadioContainerOuter>
                                            {errors.confirmed_membership &&
                                            touched.confirmed_membership ? (
                                                <Error>
                                                    {
                                                        errors.confirmed_membership
                                                    }
                                                </Error>
                                            ) : null}

                                            <RadioContainerOuter>
                                                <RadioContainer>
                                                    <CustomRadio
                                                        onChange={(value) => {
                                                            setFieldValue(
                                                                "privacy_policy",
                                                                value.target
                                                                    .checked
                                                            );
                                                        }}
                                                        checked={
                                                            values.privacy_policy
                                                        }
                                                        value={
                                                            values.privacy_policy
                                                        }
                                                        type="checkbox"
                                                    />
                                                    <RadioCheck
                                                        square={true}
                                                        className="checkmark"
                                                    />
                                                    I agree to the{" "}
                                                    <a
                                                        href="https://www.porsche.com/uk/privacy-policy/"
                                                        target="_blank"
                                                    >
                                                        Privacy Notice
                                                    </a>{" "}
                                                    <Required />
                                                </RadioContainer>
                                            </RadioContainerOuter>
                                            {errors.privacy_policy &&
                                            touched.privacy_policy ? (
                                                <Error>
                                                    {errors.privacy_policy}
                                                </Error>
                                            ) : null}

                                            {submitError && (
                                                <Error>{submitError}</Error>
                                            )}
                                            <SubmitSection>
                                                <SubmitButton
                                                    hasArrow
                                                    color="red"
                                                    type="submit"
                                                >
                                                    Submit
                                                </SubmitButton>
                                                {submitting && <Loader />}
                                            </SubmitSection>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>
                    </Container>
                )}
            </>
        );
    };
    return (
        <>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            {render(settings.state)}
        </>
    );
};

export default RegisterForm;
