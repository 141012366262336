import React, { useState } from "react";

import { SubmitButton } from "../../../components/FormComponents";
import { TextareaField } from "@cortexglobal/rla-components"
import axios from "axios";

const RenewCheck = ({ setStep, id }) => {
    const [showingCancelRenewReason, setShowingCancelRenewReason] = useState(false);
    const [showingThankyouPage, setShowingThankyouPage] = useState(false);
    const [cancelRenewReason, setCancelRenewReason] = useState("");


    // function needs updating to use correct end point
    const submitReason = () => {
        setShowingCancelRenewReason(false);
        // setShowingThankyouPage(true);

        axios
            .post(`/api/v1/cancel-renewal/${id}`, {
                reason: showingCancelRenewReason
            })
            .then((data) => {
                setShowingThankyouPage(true);
            })
            .catch((e) => {
                // console.log(e);
                setShowingThankyouPage(true);
            });
    };

    if (showingThankyouPage) {
        return (
            <>
                <h3>
                    Thankyou for your time at Porsche Classic Register.
                </h3>
            </>
        )
    }

    if (showingCancelRenewReason) {
        return (
            <>
                <h3>
                    What is the reason for cancelling your renewal?
                </h3>

                <TextareaField
                    value={cancelRenewReason}
                    onChange={({ value }) => setCancelRenewReason(value)}
                />

                <SubmitButton
                    onClick={submitReason}
                    hasArrow
                    color="red"
                    type="button"
                >
                    Submit Reason
                </SubmitButton>
            </>
        )
    }

    return (
        <>
            <h3>
                Do you own want to renew your Porsche Classic Register?
            </h3>

            <SubmitButton
                onClick={() => setShowingCancelRenewReason(true)}
                style={{ marginRight: "1em" }}
                color="grey"
                type="button"
            >
                No
            </SubmitButton>
            <SubmitButton
                onClick={() => setStep(1)}
                hasArrow
                color="red"
                type="button"
            >
                Yes
            </SubmitButton>
        </>
    );
};

export default RenewCheck;