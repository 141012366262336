import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import PublicHeader from "./PublicHeader";
import RegisterForm from "../../modules/registration/RegisterForm";
import RenewalForm from "../../modules/renewal/RenewalForm";
import PreferencesApp from "../../modules/preferences/PreferencesApp";

const PublicApp = () => {
    return (
        <>
            <PublicHeader />
            <Switch>
                <Route path="/renewal" component={RenewalForm} />
                <Route path="/preferences" component={PreferencesApp} />
                <Route path="/" component={RegisterForm} />
            </Switch>
        </>
    );
};

export default PublicApp;
