import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import axios from "axios";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    DatePicker,
} from "../../../components/FormComponents";

const Schema = Yup.object().shape({
    activity_type: Yup.string().required("Required"),
    redemption_date: Yup.date(),
});

const DatePickerContainer = styled.div`
    .react-datepicker__input-container input {
        height: 50px;
    }
`;

const ActivityLogForm = ({
    options,
    registration,
    setAdding,
    activity,
    refreshRegistrations,
    refreshRegistration,
    onSubmitted,
    setEditingActivityUuid,
}) => {
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        if (activity) {
            return axios
                .put(`api/v1/activity-logs/${activity.uuid}`, values)
                .then(({ data }) => {
                    setSubmitting(false);

                    refreshRegistration({
                        ...registration,
                        activity_logs: registration.activity_logs.map((log) =>
                            log.uuid === activity.uuid ? data.data : log
                        ),
                    });
                    onSubmitted();
                })
                .catch((e) => {
                    parseValidationErrors(e).then(({ errors }) => {
                        setErrors(errors);
                    });

                    setSubmitting(false);
                });
        }

        return axios
            .post(`api/v1/activity-logs`, values)
            .then(({ data }) => {
                setSubmitting(false);

                refreshRegistration({
                    ...registration,
                    activity_logs: [data.data, ...registration.activity_logs],
                });
                onSubmitted();
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    return (
        <Formik
            initialValues={{
                registration_uuid: registration.uuid,
                activity_type: activity ? activity.activity_type : "",
                redemption_date: activity
                    ? moment(activity.redemption_date).format()
                    : moment().format(),
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, setFieldValue, values }) => {
                return (
                    <Form>
                        <StyledLabel htmlFor="title">
                            Type <Required />
                        </StyledLabel>
                        <StyledField
                            style={{ width: "auto" }}
                            as="select"
                            name="activity_type"
                            value={values.activity_type}
                            onChange={(value) =>
                                setFieldValue(
                                    "activity_type",
                                    value.target.value
                                )
                            }
                        >
                            <option value="">Select</option>

                            {Object.entries(options).map(([value, name]) => {
                                return (
                                    <option value={value} key={value}>
                                        {name}
                                    </option>
                                );
                            })}
                        </StyledField>
                        {errors.activity_type && touched.activity_type ? (
                            <Error>Type is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="redemption_date">
                            Claim Date
                        </StyledLabel>
                        <DatePickerContainer>
                            <DatePicker
                                maxDate={moment().toDate()}
                                minDate={(registration.approved_at
                                    ? moment(registration.approved_at)
                                    : moment()
                                ).format()}
                                id="redemption_date"
                                name="redemption_date"
                                value={
                                    values.redemption_date !== undefined
                                        ? values.redemption_date
                                        : "2021-12-14T00:00:00+00:00"
                                }
                            />
                        </DatePickerContainer>

                        {errors.redemption_date && touched.redemption_date ? (
                            <Error>Claim Date is required</Error>
                        ) : null}

                        <SubmitSection>
                            <SubmitButton
                                style={{ marginRight: "1em" }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setAdding(false);
                                    setEditingActivityUuid();
                                }}
                            >
                                Cancel
                            </SubmitButton>
                            <SubmitButton
                                hasArrow
                                color="red"
                                type="submit"
                                disabled={submitting}
                            >
                                Submit
                            </SubmitButton>
                            {submitting && <Loader />}
                        </SubmitSection>
                    </Form>
                );
            }}
        </Formik>
    );
};
export default ActivityLogForm;
