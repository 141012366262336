import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";

import {
    Pagination,
    Button,
    SelectField,
    LoadingIconSmall,
} from "@cortexglobal/rla-components";

import { ButtonDropdown } from "@cortexglobal/lens-components";

import { Table, TableHeader, TableRow } from "../../components/TableComponents";
import RegistrationRow from "./RegistrationRow";

import {
    LimitedWidthRow,
    FlexRow,
    LittleLoader,
} from "../../components/LayoutComponents";

import {
    PaginationContainer,
    PerPageContainer,
} from "../../components/PaginationComponents";

import { StyledInput } from "../../components/FormComponents";

import LoadingPage from "../../components/LoadingPage";
import { AuthContext } from "@cortexglobal/lens-auth";

export const getFriendlyStatus = (status) => {
    switch (status) {
        case "awaiting-approval":
            return "Awaiting Approval";
        case "approved":
            return "Approved";
        case "declined":
            return "Declined";
        case "terminated":
            return "Terminated";
        default:
            return "N/A";
    }
};
const statuses = [
    { name: "Awaiting Approval", value: "awaiting-approval" },
    { name: "Approved", value: "approved" },
    { name: "Declined", value: "declined" },
    { name: "Terminated", value: "terminated" },
];

const DatePickerWrapper = styled.div`
    margin-right: 1rem;

    .react-datepicker__input-container input {
        padding: 0.8em;
        max-width: 100px;
        box-sizing: border-box;
    }

    .react-datepicker-wrapper {
        margin-bottom: 0;
    }
`;

const OrderArrow = styled(FontAwesomeIcon)`
    margin-left: 5px;
    position: absolute;
    top: calc(50% - 8px);
    right: 0;
    color: ${({ active }) => (active ? "black" : "#BBBBBB")};
`;

const OrderIcon = ({ dir, active }) => (
    <div>
        <OrderArrow
            active={active && dir === "asc"}
            style={{ top: "calc(50% - 15px)" }}
            icon={faCaretUp}
        />
        <OrderArrow
            active={active && dir === "desc"}
            style={{ top: "calc(50% - 4px)" }}
            icon={faCaretDown}
        />
    </div>
);

const SelectedRegistrations = styled.div`
    background: white;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: fixed;
    box-sizing: border-box;
`;

const Dashboard = (props) => {
    const [registrations, setRegistrations] = useState({
        loaded: false,
        loading: false,
        data: [],
        pagination: {},
    });

    const [selectedRegistrations, setSelectedRegistrations] = useState([]);

    const [centres, setCentres] = useState([]);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [selectedCentre, setSelectedCentre] = useState("");
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [other, setOther] = useState("");

    const [multiLoading, setMultiLoading] = useState(false);

    const [order, setOrder] = useState("identifier");
    const [dir, setDir] = useState("desc");

    const [activities, setActivities] = useState({
        loaded: false,
        data: [],
    });

    const { user } = useContext(AuthContext);
    const { centre } = user;

    useEffect(() => {
        loadRegistrations();
        getCentres();
    }, []);

    const getCentres = () => {
        return axios
            .get(`api/v1/centre`)
            .then(({ data }) => {
                setCentres(data.data);
            })
            .catch((e) => {
                // console.log(e);
            });
    };

    const loadRegistrations = (
        page = 1,
        perPage = 25,
        status = "",
        search = "",
        order = "identifier",
        dir = "desc",
        selectedCentre = "",
        other = ""
    ) => {
        setRegistrations({ ...registrations, loading: true, loaded: false });

        let qsFrom = buildDateQry(fromDate, "from");
        let qsTo = buildDateQry(toDate, "to");

        return axios
            .get(
                `api/v1/registrations?page=${page}&per_page=${perPage}&search=${search}&status=${status}${qsFrom}${qsTo}&order=${order}&dir=${dir}&selectedCentre=${selectedCentre}&other=${other}`
            )
            .then(({ data }) => {
                setRegistrations({
                    loaded: true,
                    loading: false,
                    data: data.data,
                    pagination: data.meta,
                });
            });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPage(value);

            loadRegistrations(
                page,
                value,
                status,
                search,
                order,
                dir,
                selectedCentre,
                other
            );
        }
    };

    const refreshRegistrations = () => {
        return loadRegistrations(
            page,
            perPage,
            status,
            search,
            order,
            dir,
            selectedCentre,
            other
        );
    };

    const refreshRegistration = (newRegistration) => {
        const newRegistrations = registrations.data.map((registration) =>
            newRegistration.uuid === registration.uuid
                ? { ...registration, ...newRegistration }
                : registration
        );

        setRegistrations({
            ...registrations,
            data: newRegistrations,
        });
    };

    const buildDateQry = (dte, key) => {
        if (dte) {
            return `&${key}=${moment(dte).format("DD-MM-YYYY")}`;
        }
        return "";
    };

    const downloadExport = () => {
        let qsFrom = buildDateQry(fromDate, "from");
        let qsTo = buildDateQry(toDate, "to");

        axios
            .get(
                `api/v1/my-registrations-export?search=${search}&status=${status}${qsFrom}${qsTo}&selectedCentre=${selectedCentre}`,
                { responseType: "blob" }
            )
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "my-registrations.xlsx");
                document.body.appendChild(link);
                link.click();
            });
    };

    const handleOrder = (orderBy) => {
        let direction;

        if (orderBy === order) {
            direction = dir === "asc" ? "desc" : "asc";
        } else {
            direction = "desc";
        }

        setDir(direction);
        setOrder(orderBy);

        loadRegistrations(
            page,
            perPage,
            status,
            search,
            orderBy,
            direction,
            selectedCentre,
            other
        );
    };

    const handleSelectRegistration = (uuid) => {
        if (selectedRegistrations.includes(uuid)) {
            setSelectedRegistrations([
                ...selectedRegistrations.filter(
                    (registration) => registration !== uuid
                ),
            ]);
        } else {
            setSelectedRegistrations([...selectedRegistrations, uuid]);
        }
    };

    const handleBulkSetStatus = (status, notes, bulk) => {
        setMultiLoading(true);

        let requests = [];

        selectedRegistrations.forEach((uuid) =>
            requests.push(
                axios.put(`api/v1/registrations/${uuid}/status/${status}`)
            )
        );

        axios.all(requests).finally((data) => {
            refreshRegistrations();
            setMultiLoading(false);
        });
    };

    const handleBulkWelcomePackDispatched = () => {
        setMultiLoading(true);

        let requests = [];

        selectedRegistrations.forEach((uuid) =>
            requests.push(
                axios.post(
                    `api/v1/registrations/${uuid}/welcome-pack-dispatched`
                )
            )
        );

        axios.all(requests).finally((data) => {
            refreshRegistrations();
            setMultiLoading(false);
        });
    };

    return (
        <>
            <LimitedWidthRow>
                <FlexRow style={{ marginBottom: "2rem" }}>
                    <h3 style={{ flexGrow: "1" }}>Registrations</h3>
                    {registrations.loading && (
                        <LittleLoader icon="circle-notch" spin />
                    )}
                    <DatePickerWrapper>
                        <DatePicker
                            placeholderText="From"
                            style={{
                                borderRadius: "3px",
                                padding: "0.8em",
                            }}
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                        />
                    </DatePickerWrapper>
                    <DatePickerWrapper>
                        <DatePicker
                            placeholderText="To"
                            style={{
                                borderRadius: "3px",
                                padding: "0.8em",
                            }}
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                        />
                    </DatePickerWrapper>

                    <StyledInput
                        style={{
                            width: "auto",
                            marginRight: "1rem",
                            marginBottom: "0",
                        }}
                        as="select"
                        name="status"
                        onChange={(value) => {
                            setStatus(value.target.value);
                            loadRegistrations(
                                page,
                                perPage,
                                value.target.value,
                                search,
                                order,
                                dir,
                                selectedCentre,
                                other
                            );
                        }}
                    >
                        <option value="">All Statuses</option>

                        {statuses.map(({ value, name }) => {
                            return (
                                <option value={value} key={value}>
                                    {name}
                                </option>
                            );
                        })}
                    </StyledInput>

                    <StyledInput
                        style={{
                            width: "auto",
                            marginRight: "1rem",
                            marginBottom: "0",
                        }}
                        as="select"
                        name="centre"
                        onChange={(value) => {
                            setSelectedCentre(value.target.value);
                            loadRegistrations(
                                page,
                                perPage,
                                status,
                                search,
                                order,
                                dir,
                                value.target.value,
                                other
                            );
                        }}
                    >
                        <option value="">All Centres</option>

                        {centres.map(({ uuid, name }) => {
                            return (
                                <option value={uuid} key={uuid}>
                                    {name}
                                </option>
                            );
                        })}
                    </StyledInput>

                    <StyledInput
                        style={{
                            width: "auto",
                            marginRight: "1rem",
                            marginBottom: "0",
                        }}
                        as="select"
                        name="other"
                        onChange={(value) => {
                            setOther(value.target.value);
                            loadRegistrations(
                                page,
                                perPage,
                                status,
                                search,
                                order,
                                dir,
                                selectedCentre,
                                value.target.value
                            );
                        }}
                    >
                        <option value="">Other</option>

                        <option value="welcome-pack-sent">Pack sent</option>

                        <option value="welcome-pack-not-sent">
                            Pack not sent
                        </option>
                    </StyledInput>

                    <FlexRow>
                        <StyledInput
                            style={{
                                marginBottom: "0",
                            }}
                            value={search}
                            onChange={(input) => setSearch(input.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    loadRegistrations(
                                        page,
                                        perPage,
                                        status,
                                        search,
                                        order,
                                        dir,
                                        selectedCentre,
                                        other
                                    );
                                }
                            }}
                        />
                        <Button
                            onClick={() =>
                                loadRegistrations(
                                    page,
                                    perPage,
                                    status,
                                    search,
                                    order,
                                    dir,
                                    selectedCentre,
                                    other
                                )
                            }
                        >
                            <FontAwesomeIcon icon="search" />
                        </Button>
                    </FlexRow>
                    <Button
                        onClick={downloadExport}
                        title="Download Registrations"
                    >
                        <FontAwesomeIcon icon="download" />
                    </Button>
                </FlexRow>
                {registrations.loaded ? (
                    registrations.data.length > 0 ? (
                        <>
                            <Table>
                                <thead>
                                    <TableRow>
                                        <TableHeader />
                                        <TableHeader
                                            canOrder
                                            onClick={() =>
                                                handleOrder("identifier")
                                            }
                                        >
                                            ID
                                            <OrderIcon
                                                active={order === "identifier"}
                                                dir={dir}
                                            />
                                        </TableHeader>
                                        <TableHeader
                                            canOrder
                                            onClick={() =>
                                                handleOrder("last_name")
                                            }
                                        >
                                            Name
                                            <OrderIcon
                                                active={order === "last_name"}
                                                dir={dir}
                                            />
                                        </TableHeader>

                                        {centre === null && (
                                            <TableHeader>Centre</TableHeader>
                                        )}

                                        <TableHeader
                                            canOrder
                                            onClick={() =>
                                                handleOrder("bp_number")
                                            }
                                        >
                                            BP Number
                                            <OrderIcon
                                                active={order === "bp_number"}
                                                dir={dir}
                                            />
                                        </TableHeader>
                                        <TableHeader>Registered</TableHeader>
                                        <TableHeader
                                            canOrder
                                            onClick={() =>
                                                handleOrder(
                                                    "membership_start_at"
                                                )
                                            }
                                        >
                                            Start
                                            <OrderIcon
                                                active={
                                                    order ===
                                                    "membership_start_at"
                                                }
                                                dir={dir}
                                            />
                                        </TableHeader>
                                        <TableHeader
                                            canOrder
                                            onClick={() =>
                                                handleOrder("membership_end_at")
                                            }
                                        >
                                            End
                                            <OrderIcon
                                                active={
                                                    order ===
                                                    "membership_end_at"
                                                }
                                                dir={dir}
                                            />
                                        </TableHeader>
                                        <TableHeader>
                                            Actions Required
                                        </TableHeader>

                                        <TableHeader>Status</TableHeader>
                                        <TableHeader
                                            style={{
                                                textAlign: "center",
                                                minWidth: "100px",
                                            }}
                                        >
                                            Actions
                                        </TableHeader>
                                    </TableRow>
                                </thead>
                                <tbody>
                                    {registrations.data.map((registration) => {
                                        return (
                                            <RegistrationRow
                                                isSelected={selectedRegistrations.includes(
                                                    registration.uuid
                                                )}
                                                handleSelectRegistration={
                                                    handleSelectRegistration
                                                }
                                                userCentre={centre}
                                                registration={registration}
                                                refreshRegistrations={
                                                    refreshRegistrations
                                                }
                                                refreshRegistration={
                                                    refreshRegistration
                                                }
                                                key={registration.uuid}
                                                activities={activities}
                                                setActivities={setActivities}
                                            />
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{
                                            border: "1px solid grey",
                                            margin: "0 0 0 1em",
                                            width: "auto",
                                            borderRadius: "3px",
                                        }}
                                        value={perPage || 25}
                                        options={[
                                            { value: 25, text: "25" },
                                            { value: 50, text: "50" },
                                            { value: 100, text: "100" },
                                            { value: "all", text: "All" },
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={page - 1}
                                    total={registrations.pagination.total}
                                    pageCount={
                                        registrations.pagination.last_page
                                    }
                                    onPageChange={({ selected }) => {
                                        setPage(selected + 1);
                                        loadRegistrations(
                                            selected + 1,
                                            perPage,
                                            status,
                                            search,
                                            order,
                                            dir,
                                            selectedCentre
                                        );
                                    }}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                            </PaginationContainer>
                        </>
                    ) : (
                        <div>No registrations found</div>
                    )
                ) : (
                    <LoadingPage title="Loading registrations" />
                )}

                {!!selectedRegistrations.length && (
                    <SelectedRegistrations>
                        <span>
                            <strong>
                                Selected {selectedRegistrations.length}{" "}
                                Registration
                                {selectedRegistrations.length === 1 ? "" : "s"}
                            </strong>
                        </span>

                        <span style={{ display: "flex", alignItems: "center" }}>
                            {!multiLoading ? (
                                <>
                                    <span style={{ marginRight: "1em" }}>
                                        <ButtonDropdown
                                            onClick={() =>
                                                setSelectedRegistrations([])
                                            }
                                            actions={[]}
                                            name="primary"
                                        >
                                            Cancel
                                        </ButtonDropdown>
                                    </span>
                                    <span>
                                        <ButtonDropdown
                                            hidePrimaryAction={true}
                                            name="bulk_actions"
                                            actions={[
                                                {
                                                    name:
                                                        "Welcome Pack Dispatched",
                                                    onClick: () =>
                                                        handleBulkWelcomePackDispatched(),
                                                },
                                                {
                                                    name: "Decline",
                                                    onClick: () =>
                                                        handleBulkSetStatus(
                                                            "decline"
                                                        ),
                                                },
                                            ]}
                                        />
                                    </span>
                                </>
                            ) : (
                                <span
                                    style={{
                                        marginLeft: "2em",
                                    }}
                                >
                                    <LoadingIconSmall />
                                </span>
                            )}
                        </span>
                    </SelectedRegistrations>
                )}
            </LimitedWidthRow>
        </>
    );
};

export default Dashboard;
