import React from "react";

import { Loader, SubmitButton } from "../../../components/FormComponents";
import { DetailsContainer, DetailsLabel } from "../RenewalForm";
import { UnstyledButton } from "../../../components/registration/registrationComponents";

export const PorscheCentreConfirmationBox = ({
    centre,
    newPorscheCentre
}) => {
    return (
        <DetailsContainer style={{ marginBottom: "2em" }}>
            <table>
                <tbody>
                    <tr>
                        <DetailsLabel>Name:</DetailsLabel>
                        <td>
                            {
                                newPorscheCentre ? newPorscheCentre : centre.name
                            }
                        </td>
                    </tr>
                    {/*<tr>*/}
                    {/*    <DetailsLabel>Address:</DetailsLabel>*/}
                    {/*    <td>*/}
                    {/*        {centre.address_1}, {centre.town}, {centre.county}*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                </tbody>
            </table>
        </DetailsContainer>
    );
};

const PorscheCentreConfirmation = ({
    centre,
    handleNewCentreClick,
    setStep,
    newCentresLoading,
    values,
    newPorscheCentre
}) => {
    return (
        <>
            <h3>Is this still the best Porsche centre for you?</h3>

            <PorscheCentreConfirmationBox
                newPorscheCentre={newPorscheCentre}
                centre={centre}
            />

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1em",
                }}
            >
                <SubmitButton
                    onClick={() => handleNewCentreClick(values.postcode)}
                    style={{ marginRight: "1em" }}
                    color="grey"
                    type="button"
                >
                    No, I would like to choose another centre
                </SubmitButton>
                <SubmitButton
                    onClick={() => setStep(4)}
                    hasArrow
                    color="red"
                    type="button"
                >
                    Yes
                </SubmitButton>

                {newCentresLoading && <Loader />}
            </div>

            <div onClick={() => setStep(2)} style={{ marginTop: "1em" }}>
                <UnstyledButton type="button">{"< Go Back"}</UnstyledButton>
            </div>
        </>
    );
};

export default PorscheCentreConfirmation;
