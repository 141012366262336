import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

class HeadContent extends Component {
    render() {
        return (
            <Helmet>
                <title>Porsche Classic Register</title>
                <meta name="description" content="Porsche Classic Register" />
            </Helmet>
        );
    }
}

export default HeadContent;
