import React from "react";
import styled, { keyframes, css } from "styled-components";
import { FormikDatePicker } from "@cortexglobal/rla-components";
import { Field } from "formik";

export const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
`;

export const Break = styled.div`
    height: 3px;
    background: #ddd;
    margin-bottom: 2em;
`;

export const StyledField = styled(Field)`
    width: 100%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;

    ${(props) =>
        props.successful &&
        `
        border: 2px solid #11a211;
    `}

    ${(props) =>
        props.error &&
        `
        border: 2px solid red;
    `}
    
    &:disabled {
        border: 1px solid #9c9c9c;
        background: #efefef;
    }
`;

export const StyledCheckboxField = styled(StyledField)`
    width: auto;
    transform: scale(1.75);
`;

export const DatePicker = styled(FormikDatePicker)`
    width: 100%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    border-radius: 0;
    border: 1px solid black;
    padding: 1.2em;
    outline: none;

    ${(props) =>
        props.successful &&
        `
    border: 2px solid #11a211;
`}

    ${(props) =>
        props.error &&
        `
    border: 2px solid red;
`}
`;

export const StyledInput = styled.input`
    width: 100%;
    border: 1px solid grey;
    border-radius: 3px;
    padding: 0.8em;
    margin-bottom: 1em;
    box-sizing: border-box;
    /* margin-bottom: 2rem; */
    position: relative;

    ${(props) =>
        props.successful &&
        `
        border: 2px solid #11a211;
    `}
`;

export const StyledCheck = styled.input`
    border: 1px solid grey;
    border-radius: 3px;
    padding: 0.8em;
    margin-bottom: 1em;
    box-sizing: border-box;
    width: auto;
    transform: scale(1.75);
`;

export const StyledLabel = styled.label`
    margin: 2rem 0 0.5em;
    display: block;
`;

export const Error = styled.div`
    color: red;
    margin-bottom: 1em;
`;

export const SubmitSection = styled.div`
    margin-top: 3em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
`;

const ArrowStylesBottom = css`
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 45%;
    left: 15px;
    transform: translateY(-100%) rotate(55deg);
`;

const ArrowStylesTop = css`
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    position: absolute;
    top: 55%;
    left: 15px;
    transform: translateY(100%) rotate(-55deg);
`;

export const SubmitButton = styled.button`
    outline: none;
    padding: 1.2em 2em;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    background: white;
    border: 1px solid black;
    border-radius: 0;

    transition: all 0.1s;
    position: relative;

    &:hover {
        background: #d5001c;
        border: 1px solid #d5001c;
        color: white;
    }

    ${({ color }) =>
        color === "grey" &&
        `
        background: #3e3e3e;
        border: 1px solid #3e3e3e;
        color: white;
    `}

    ${({ color }) =>
        color === "green" &&
        `
        background: #11a211;
        border: 1px solid #11a211;
        color: white;
        
        &:hover {
            background: #13a813;
            border: 1px solid #11a211;
        }
    `}
    
    ${({ color }) =>
        color === "red" &&
        `
        background: #d5001c;
        border: 1px solid #d5001c;
        color: white;
    `}
    
    ${({ hasArrow, color }) =>
        hasArrow &&
        `
        padding-left: 2.5rem;
    
        &:before {
            ${ArrowStylesBottom};
            background: ${color ? "white" : "black"};
        }
        
        &:after {
            ${ArrowStylesTop};
            background: ${color ? "white" : "black"};
        }
        
        &:hover {
            &:before {
                background: white !important;
                ${ArrowStylesBottom};
            }
            
            &:after {
                background: white !important;
                ${ArrowStylesTop};
            }
        }
    `}
    
    ${({ disabled }) =>
        disabled === true &&
        `
        opacity: 0.6;
        cursor: default;
    `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
    margin-left: 1em;
    border: 5px solid #e2e2e2;
    border-radius: 50%;
    border-top: 5px solid #5d5d5d;
    width: 30px;
    height: 30px;
    animation: ${rotate} 1.2s linear infinite;
`;

export const Title = styled.h1`
    font-size: 1.7em;
`;

export const Subtitle = styled.h2`
    font-size: 1.5em;
`;

export const FlexBox = styled.div`
    display: flex;
    margin-bottom: 1em;
    align-items: baseline;
`;

export const LogoContainer = styled.div`
    text-align: center;
    margin-bottom: 2em;
    margin-top: 1em;
`;

export const SmallLabel = styled.p`
    font-size: 0.8em;
`;

export const Required = () => {
    return <span style={{ color: "red" }}>*</span>;
};

export const CenterSelect = ({ name = "centre_uuid", onChange, options }) => {
    return (
        <StyledField
            as="select"
            name={name}
            onChange={(value) => onChange(name, value.target.value)}
        >
            <option value="">Select</option>

            {Object.keys(options).map((key) => {
                const centre = options[key];

                return (
                    <option value={key} key={key}>
                        {centre}
                    </option>
                );
            })}
        </StyledField>
    );
};
