import React, { useEffect, useState } from "react";
import {Button, Modal, Pagination, SelectField, useAddToast} from "@cortexglobal/rla-components";
import axios from "axios";
import { CubeLoader } from "../index";
import queryString from "querystring";
import {
    useLocation
} from "react-router-dom";
import { Link } from "react-router-dom";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
} from "../table/StyledTable";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyRewardOrders = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    rewardOrders,
    meta,
}) => {
    return (
        <>
            <StyledTable
                className="reward-orders-table"
            >
                <thead>
                <tr>
                    <StyledTableHeader
                        name="allocated_points"
                    >
                        Allocated Points
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="order_type"
                    >
                        Order Type
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="activated_at"
                    >
                        Activated At
                    </StyledTableHeader>
                    <StyledTableHeader/>
                </tr>
                </thead>
                <tbody>
                {rewardOrders.map((reward_order) => (
                    <StyledTableRow
                        key={reward_order.id}
                    >
                        <td>
                            {reward_order.allocated_points}
                        </td>
                        <td>
                            {reward_order.order_type}
                        </td>
                        <td>
                            {new Date(reward_order.activated_at).toDateString()}
                        </td>
                        <td>
                            <Link to={`/rewards/orders/${reward_order.id}`}>
                            <Button>View Reward Order</Button>
                        </Link>
                        </td>
                    </StyledTableRow>
                ))}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{ margin: '0 0 0 1em' }}
                        value={perPageValue}
                        options={[
                            {value: 10, text: '10'},
                            {value: 20, text: '20'},
                            {value: 30, text: '30'},
                            {value: 40, text: '40'},
                            {value: 50, text: '50'},
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={selectedPageValue - 1}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>
        </>
    );
};

export default CompanyRewardOrders;