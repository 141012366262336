import React, { useState } from "react";

import {
    Error,
    Loader,
    Required,
    SmallLabel,
    StyledField,
    StyledLabel,
    SubmitButton,
    SubmitSection
} from "../../../components/FormComponents";
import { UnstyledButton } from "../../../components/registration/registrationComponents";

const DetailsForm = ({
    setStep,
    touched,
    setFieldValue,
    titles,
    errors,
    values,
    handlePostcodeSearch,
    postcodeLoading,
    postcodeError,
    addressList
}) => {
    const [manuallyEnterAddress, setManuallyEnterAddress] = useState(false);


    const detailsFormDisabled = !!Object.entries(errors).length;


    return (
        <>
            <h3>Personal Details</h3>

            <StyledLabel htmlFor="title">
                Title <Required />
            </StyledLabel>
            <StyledField
                error={
                    errors.title &&
                    touched.title
                }
                style={{ width: "auto" }}
                as="select"
                name="title"
                value={values.title}
                onChange={(value) =>
                    setFieldValue(
                        "title",
                        value.target.value
                    )
                }
            >
                <option value="">Select</option>

                {titles.map((title) => {
                    return (
                        <option
                            value={title}
                            key={title}
                        >
                            {title}
                        </option>
                    );
                })}
            </StyledField>
            {errors.title && touched.title ? (
                <Error>{errors.title}</Error>
            ) : null}

            <StyledLabel htmlFor="first_name">
                First name <Required />
            </StyledLabel>
            <StyledField
                error={
                    errors.first_name &&
                    touched.first_name
                }
                id="first_name"
                name="first_name"
            />
            {errors.first_name &&
            touched.first_name ? (
                <Error>
                    {errors.first_name}
                </Error>
            ) : null}

            <StyledLabel htmlFor="last_name">
                Last Name <Required />
            </StyledLabel>
            <StyledField
                error={
                    errors.last_name &&
                    touched.last_name
                }
                id="last_name"
                name="last_name"
            />
            {errors.last_name &&
            touched.last_name ? (
                <Error>
                    {errors.last_name}
                </Error>
            ) : null}

            <SmallLabel
                style={{
                    marginTop: "2em",
                    marginBottom: "-1em",
                }}
            >
                Please enter the address where
                you would like your membership
                pack sent to
            </SmallLabel>

            <StyledLabel htmlFor="postcode">
                Postcode <Required />
            </StyledLabel>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1em",
                }}
            >
                <StyledField
                    error={
                        errors.postcode &&
                        touched.postcode
                    }
                    style={{
                        maxWidth: "300px",
                        marginRight: "1rem",
                        marginBottom: "0",
                    }}
                    id="postcode"
                    name="postcode"
                />

                <SubmitButton
                    type="button"
                    hasArrow={true}
                    color="grey"
                    onClick={(e) => {
                        e.preventDefault();
                        handlePostcodeSearch(
                            values.postcode
                        );
                    }}
                >
                    Find Address
                </SubmitButton>
                {postcodeLoading && <Loader />}
            </div>

            {postcodeError && (
                <Error>
                    Enter valid postcode
                </Error>
            )}

            {errors.postcode &&
            touched.postcode ? (
                <Error>{errors.postcode}</Error>
            ) : null}

            <StyledLabel htmlFor="address">
                Select Address <Required />
            </StyledLabel>
            <SmallLabel>
                Input postcode and press Find
                Address, then select your
                address from the list
            </SmallLabel>
            <StyledField
                disabled={!addressList.length}
                error={
                    (errors.address_1 &&
                        touched.address_1) ||
                    (errors.county &&
                        touched.county) ||
                    (errors.town &&
                        touched.town)
                }
                as="select"
                name="address"
                value={addressList.length === 1 ? 0 : null}
                onChange={(value) => {
                    setFieldValue(
                        "address_1",
                        addressList[
                            value.target.value
                            ].line_1
                    );
                    setFieldValue(
                        "address_2",
                        addressList[
                            value.target.value
                            ].line_2
                    );
                    setFieldValue(
                        "town",
                        addressList[
                            value.target.value
                            ].town_or_city
                    );
                    setFieldValue(
                        "county",
                        addressList[
                            value.target.value
                            ].county
                    );
                }}
            >
                <option value="">Select</option>

                {addressList.map(
                    (address, key) => {
                        return (
                            <option
                                value={key}
                                key={`${address.line_1}, ${address.town_or_city}, ${address.county}`}
                            >
                                {`${address.line_1}, ${address.town_or_city}, ${address.county}`}
                            </option>
                        );
                    }
                )}
            </StyledField>
            {manuallyEnterAddress ? (
                <SmallLabel>
                    <UnstyledButton
                        type="button"
                        onClick={() => setManuallyEnterAddress(false)}
                    >
                        Click here to close
                        manual address entry
                    </UnstyledButton>
                </SmallLabel>
            ) : (
                <SmallLabel>
                    Can't find your address?{" "}
                    <UnstyledButton
                        type="button"
                        onClick={() => setManuallyEnterAddress(true)}
                    >
                        Click here to enter it manually
                    </UnstyledButton>
                </SmallLabel>
            )}

            {manuallyEnterAddress && (
                <>
                    <StyledLabel htmlFor="address_1">
                        Address line 1{" "}
                        <Required />
                    </StyledLabel>
                    <StyledField
                        error={
                            errors.address_1 &&
                            touched.address_1
                        }
                        id="address_1"
                        name="address_1"
                    />
                    {errors.address_1 &&
                    touched.address_1 ? (
                        <Error>
                            {errors.address_1}
                        </Error>
                    ) : null}

                    <StyledLabel htmlFor="address_2">
                        Address line 2{" "}
                    </StyledLabel>
                    <StyledField
                        id="address_2"
                        name="address_2"
                    />

                    <StyledLabel htmlFor="town">
                        Town <Required />
                    </StyledLabel>
                    <StyledField
                        error={
                            errors.town &&
                            touched.town
                        }
                        id="town"
                        name="town"
                    />
                    {errors.town &&
                    touched.town ? (
                        <Error>
                            {errors.town}
                        </Error>
                    ) : null}

                    <StyledLabel htmlFor="county">
                        County <Required />
                    </StyledLabel>
                    <StyledField
                        error={
                            errors.county &&
                            touched.county
                        }
                        id="county"
                        name="county"
                    />
                    {errors.county &&
                    touched.county ? (
                        <Error>
                            {errors.county}
                        </Error>
                    ) : null}
                </>
            )}

            <StyledLabel htmlFor="telephone">
                Telephone <Required />
            </StyledLabel>
            <StyledField
                error={
                    errors.telephone &&
                    touched.telephone
                }
                id="telephone"
                name="telephone"
            />
            {errors.telephone &&
            touched.telephone ? (
                <Error>
                    {errors.telephone}
                </Error>
            ) : null}

            <StyledLabel htmlFor="email">
                Email <Required />
            </StyledLabel>
            <StyledField
                error={
                    errors.email &&
                    touched.email
                }
                id="email"
                name="email"
            />
            {errors.email && touched.email ? (
                <Error>{errors.email}</Error>
            ) : null}

            <SubmitSection>
                <SubmitButton
                    disabled={detailsFormDisabled}
                    onClick={() => setStep(3)}
                    hasArrow
                    color="red"
                    type="button"
                >
                    Next
                </SubmitButton>
            </SubmitSection>

            <div
                onClick={() => setStep(2)}
                style={{ marginTop: "1em", paddingBottom: "2em" }}
            >
                <UnstyledButton
                    type="button"
                >
                    {"< Go Back"}
                </UnstyledButton>
            </div>
        </>
    );
};

export default DetailsForm;