import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import LoadingPage from "../../../components/LoadingPage";

import CentreForm from "./CentreForm";

import {
    FlexRow,
    LimitedWidthRow,
    LittleLoader,
} from "../../../components/LayoutComponents";

const MyCentre = () => {
    const { uuid } = useParams("uuid");

    const [centre, setCentre] = useState({
        loaded: false,
        error: false,
        data: {},
    });

    const refreshCentre = (newCentre) => {
        setCentre({ loaded: true, error: false, data: newCentre });
    };

    useEffect(() => {
        axios
            .get(`/api/v1/centre/my-centre`)
            .then(({ data }) => {
                setCentre({ loaded: true, error: false, data: data.data });
            })
            .catch((e) => {
                setCentre({ loaded: true, error: true, data: {} });
            });
    }, []);

    const renderBody = () => {
        if (centre.loaded === false) {
            return <LoadingPage />;
        }

        return (
            <CentreForm
                centre={centre.data}
                refreshCentre={refreshCentre}
                successCallback={() => {}}
            />
        );
    };

    return (
        <LimitedWidthRow>
            <FlexRow style={{ marginBottom: "2rem" }}>
                <h3 style={{ flexGrow: "1" }}>My Centre</h3>
                {centre.loaded || <LittleLoader icon="circle-notch" spin />}
            </FlexRow>
            {renderBody()}
        </LimitedWidthRow>
    );
};

export default MyCentre;
