import React, {
    useState,
    useEffect,
    useCallback,
} from "react";
import { Link } from "react-router-dom";
import {
    useHistory,
    useParams,
    useLocation
} from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    SelectField,
    useAddToast,

} from "@cortexglobal/rla-components";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
} from "../table/StyledTable";

import { CubeLoader } from "../index";

import queryString from "querystring";
import {debounce} from "lodash";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const CompanyList = () => {
    const history = useHistory();
    const addToast = useAddToast();

    const {
        orderBy,
        orderDir,
        search,
        searchBy,
        perPage,
        page
    } = queryString.parse(useLocation().search.substring(1));

    const [searchValue, setSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(page === undefined ? 1 : page);
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);

    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCompanies, setTotalCompanies] = useState();
    const [totalPages, setTotalPages] = useState(0);

    const getCompanies = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/customers`, { params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData
                }})
            .then(({ data }) => {
                setLoading(false);
                setCompanies(data.data);
                setTotalCompanies(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting companies.",
                    showFor: 5000
                });
                setLoading(false);
            });
    }

    useEffect(() => {
        getCompanies();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${data.order_by ? `&orderBy=${data.order_by}`: ''}${data.order_dir ? `&orderDir=${data.order_dir}` : ''}${data.search_val ? `&search=${data.search_val}` : ''}${data.search_val ? `&searchBy=${data.search_by}` : ''}${data.global_search_val ? `&globalSearch=${data.global_search_val}`: ''}`
        });
    }

    const debounceSearch = useCallback(debounce((callback) => {
        callback()
    }, 500), []);

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch((() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1
            });
            getCompanies({
                search_by: searchByValue,
                search_val: value,
                page: 1
            });
        }));
    }

    const handleGlobalSearch = ({ value }) => {
        setGlobalSearchValue(value);
        setSearchValue("");
        setSearchByValue("");

        debounceSearch((() => {
            setParams({
                global_search_val: value,
                page: 1
            });
            getCompanies({
                global_search_val: value,
                page: 1
            });
        }));
    }


    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1
        });
        getCompanies({
            order_by: sortByValue,
            order_dir: value,
            page: 1
        });
    }

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1
            });
            getCompanies({
                per_page: value,
                page: 1
            });
        }
    }

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected);

        setParams({
            page: selected + 1
        });
        getCompanies({
            page: selected + 1
        });
    }

    return (
        <>
            <PageTitle title="Companies" />
            <Row style={{ overflow: 'scroll', maxWidth: '100%' }}>
                <Column>
                    <SearchSection>
                        <SearchSection>
                            <InputField
                                name="search"
                                placeholder="Search"
                                style={{
                                    maxWidth: '400px',
                                    display: 'inline-block',
                                    margin: '0'
                                }}
                                value={globalSearchValue}
                                onChange={handleGlobalSearch}
                            />
                            <Button style={{ marginLeft: '1rem'}}>Search</Button>
                        </SearchSection>
                    </SearchSection>
                    <Panel style={{ paddingTop: '0' }}>
                        <StyledTable
                            className="dashboard-table"
                        >
                            <thead>
                            <tr>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={orderByValue === "trade_team_id" ? orderDirectionValue : undefined}
                                    searchValue={searchByValue === "trade_team_id" ? searchValue : ""}
                                    onSortChange={handleSortChange}
                                    onSearchChange={handleSearch}
                                    hasSearch={true}
                                    name="trade_team_id"
                                >
                                    Distrigo Number
                                </StyledTableHeader>

                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={orderByValue === "trade_team_customer" ? orderDirectionValue : undefined}
                                    searchValue={searchByValue === "trade_team_customer" ? searchValue : ""}
                                    onSortChange={handleSortChange}
                                    onSearchChange={handleSearch}
                                    hasSearch={true}
                                    name="trade_team_customer"
                                >
                                    Customer
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={orderByValue === "trade_team_customer_type" ? orderDirectionValue : undefined}
                                    searchValue={searchByValue === "trade_team_customer_type" ? searchValue : ""}
                                    onSortChange={handleSortChange}
                                    onSearchChange={handleSearch}
                                    hasSearch={true}
                                    name="trade_team_customer_type"
                                >
                                    Customer Type
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={orderByValue === "address_1" ? orderDirectionValue : undefined}
                                    searchValue={searchByValue === "address_1" ? searchValue : ""}
                                    onSortChange={handleSortChange}
                                    onSearchChange={handleSearch}
                                    hasSearch={true}
                                    name="address_1"
                                >
                                    Address
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={orderByValue === "postcode" ? orderDirectionValue : undefined}
                                    searchValue={searchByValue === "postcode" ? searchValue : ""}
                                    onSortChange={handleSortChange}
                                    onSearchChange={handleSearch}
                                    hasSearch={true}
                                    name="postcode"
                                >
                                    Postcode
                                </StyledTableHeader>
                                <StyledTableHeader />
                            </tr>
                            </thead>

                            {!loading &&
                                <tbody>
                                {companies.map(customer => (
                                    <StyledTableRow
                                        key={customer.trade_team_id}
                                    >
                                        <td>
                                            {customer.trade_team_id}
                                        </td>
                                        <td>
                                            {customer.trade_team_customer}
                                        </td>
                                        <td>
                                            {customer.trade_team_customer_type}
                                        </td>
                                        <td>
                                            {customer.address_1}
                                        </td>
                                        <td>
                                            {customer.postcode}
                                        </td>
                                        <td>
                                            <Link to={`/companies/${customer.customer_id}`}>
                                                <Button>View Details</Button>
                                            </Link>
                                        </td>
                                    </StyledTableRow>
                                ))}
                                </tbody>
                            }
                        </StyledTable>

                        {loading &&
                            <CubeLoader />
                        }

                        {!companies.length && !loading ?
                            <p style={{textAlign: 'center', margin: '3em 0'}}>No results</p>
                            :
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{margin: '0 0 0 1em'}}
                                        value={perPageValue}
                                        options={[
                                            {value: 10, text: '10'},
                                            {value: 20, text: '20'},
                                            {value: 30, text: '30'},
                                            {value: 40, text: '40'},
                                            {value: 50, text: '50'},
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={selectedPageValue - 1}
                                    total={totalCompanies}
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                                <TotalContainer>Total: {totalCompanies}</TotalContainer>
                            </PaginationContainer>
                        }
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default CompanyList;
