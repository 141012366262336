import React from "react";

import { VehicleConfirmationBox } from "./VehicleConfirmation";
import { DetailsConfirmationBox } from "./DetailsConfirmation";
import { PorscheCentreConfirmationBox } from "./PorscheCentreConfirmation";
import {
    CustomRadio,
    RadioCheck,
    RadioContainer,
    RadioContainerOuter,
    UnstyledButton,
} from "../../../components/registration/registrationComponents";
import {
    Error,
    Loader,
    Required,
    SubmitButton,
    SubmitSection,
} from "../../../components/FormComponents";

const Confirmation = ({
    centre,
    customer,
    vehicle,
    setFieldValue,
    values,
    touched,
    errors,
    submitting,
    setStep,
    newPorscheCentre,
    validationMessage,
    validationErrors,
    setValidationMessage,
    setValidationErrors
}) => {
    return (
        <>
            <h3>Vehicle Details: </h3>
            <VehicleConfirmationBox vehicle={values} />

            <h3>Your Details: </h3>
            <DetailsConfirmationBox
                customer={values}
                validationErrors={validationErrors}
                setStep={setStep}
                setValidationMessage={setValidationMessage}
                setValidationErrors={setValidationErrors}
            />

            <h3>Centre Details: </h3>
            <PorscheCentreConfirmationBox centre={centre} newPorscheCentre={newPorscheCentre}/>

            <p style={{ margin: "2rem 0 0 0" }}>
                Please read and accept the full Terms and Conditions & Privacy
                Notice in the links provided below
            </p>
            <RadioContainerOuter>
                <RadioContainer>
                    <CustomRadio
                        onChange={(value) => {
                            setFieldValue(
                                "confirmed_membership",
                                value.target.checked
                            );
                        }}
                        checked={values.confirmed_membership}
                        value={values.confirmed_membership}
                        type="checkbox"
                    />
                    <RadioCheck square={true} className="checkmark" />I agree to
                    the{" "}
                    <a
                        href="https://www.porsche.com/filestore.aspx/download.pdf?pool=uk&type=download&id=classic-register-termsandconditions&lang=none&filetype=default"
                        target="_blank"
                    >
                        Terms and Conditions
                    </a>
                    <Required />
                </RadioContainer>
            </RadioContainerOuter>
            {errors.confirmed_membership && touched.confirmed_membership ? (
                <Error>{errors.confirmed_membership}</Error>
            ) : null}

            <RadioContainerOuter>
                <RadioContainer>
                    <CustomRadio
                        onChange={(value) => {
                            setFieldValue(
                                "privacy_policy",
                                value.target.checked
                            );
                        }}
                        checked={values.privacy_policy}
                        value={values.privacy_policy}
                        type="checkbox"
                    />
                    <RadioCheck square={true} className="checkmark" />I agree to
                    the{" "}
                    <a
                        href="https://www.porsche.com/uk/privacy-policy/"
                        target="_blank"
                    >
                        Privacy Notice
                    </a>{" "}
                    <Required />
                </RadioContainer>
            </RadioContainerOuter>
            {errors.privacy_policy && touched.privacy_policy ? (
                <Error>{errors.privacy_policy}</Error>
            ) : null}

            {validationMessage && <Error>{validationMessage}</Error>}

            <SubmitSection>
                <SubmitButton
                    disabled={!values.confirmed_membership || !values.privacy_policy}
                    hasArrow
                    color="red"
                    type="submit"
                >
                    Submit
                </SubmitButton>
                {submitting && <Loader />}
            </SubmitSection>

            <div
                onClick={() => setStep(3)}
                style={{ marginTop: "1em", paddingBottom: "2em" }}
            >
                <UnstyledButton type="button">{"< Go Back"}</UnstyledButton>
            </div>
        </>
    );
};

export default Confirmation;
