import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    useAddToast,
    InputField,
    SelectField,
    Pagination,
    Modal,
    DatePicker
} from "@cortexglobal/rla-components";
import {
    Link,
    useHistory,
    useLocation
} from "react-router-dom";
import { debounce } from "lodash";

import {
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    CubeLoader,
    ButtonDropdown
} from "@cortexglobal/lens-components";

import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "querystring";

const CustomMappingSection = styled.div`
    padding: 1rem;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const MappableEntryData = styled.span`
    color: #555;
    font-style: italic;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SelectedCustomerMapping = styled.div`
    position: absolute;
    background: white;
    bottom: -10px;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    align-items: center;
    box-shadow: 0 0 12px rgba(0,0,0,0.1);
    width: calc(100% - 150px);
    position: fixed;
`;

const ViewCustomerMappingSideColumn = styled.div`
    width: 24%;
    margin-right: 1rem;
    font-weight: bold;
`;

const ViewCustomerMappingMainColumn = styled.div`
    width: 38%;
`;

const ViewCustomerMappingTitle = styled.div`
    display: flex;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
`;

const Filters = styled.div`
    margin-top: 1rem;
    background: #eee;
    padding: 2rem 1rem 1rem;
    display: flex;
    align-items: center;
`;

const ViewCustomerMappingRow = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const CustomerMapping = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const {
        orderBy,
        orderDir,
        search,
        searchBy,
        perPage,
        page
    } = queryString.parse(useLocation().search.substring(1));

    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(page === undefined ? 0 : page);

    const [data, setData] = useState({
        data: []
    });
    const [loading, setLoading] = useState(true);
    const [multiLoading, setMultiLoading] = useState(false);

    const [selectedCustomerMapping, setSelectedCustomerMapping] = useState([]);

    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);

    const [viewCustomerMapping, setViewCustomerMapping] = useState();

    const [createNewDN, setCreateNewDN] = useState();

    const [filtersShowing, setFiltersShowing] = useState(false);
    const [filters, setFilters] = useState({
        date_from: undefined,
        date_to: undefined,
        distributor: undefined,
        customer_type: undefined,
        postcode: undefined,
        business_name: undefined
    });

    const [queueType, setQueueType] = useState("queue");

    const getCustomerMapping = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/customers/mapping-intervention`, { params: {
                    backlog: false,
                    queue_type: queueType,
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    filters: filters,
                    ...newData
                }})
            .then(({ data }) => {
                setLoading(false);
                setData(data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting customer mapping.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getCustomerMapping();
    }, []);

    const convertMap = (customerMap) => {
        return {
            id: customerMap.mappable_id,
            customer_suggestion: customerMap.customer_suggestion.customer_id,
            customer_from_sync_field: customerMap.mappable_entry.sync_field,
            customer_from: customerMap.mappable_entry.id
        };
    }

    const handleSelectAll = () => {
        setSelectedCustomerMapping(data.data.map(customerMap => convertMap(customerMap)));
    };

    const handleClickMapping = (customerMap) => {
        if (selectedCustomerMapping.some(({ id }) => id === customerMap.mappable_id)) {
            setSelectedCustomerMapping([
                ...selectedCustomerMapping.filter(selectedCustomerMap => selectedCustomerMap.id !== customerMap.mappable_id)
            ]);
        } else {
            setSelectedCustomerMapping([
                ...selectedCustomerMapping,
                {
                    ...convertMap(customerMap)
                }
            ]);
        }
    };

    const cancelAllLoading = () => {
        setMultiLoading(false);
        setLoading(false);
        setSelectedCustomerMapping([]);
    }

    const handleAcceptCustomerMapping = (customerMapping) => {
        const customerMap = customerMapping
            .filter(({ customer_suggestion }) => !!customer_suggestion)
            .map(customerMap => {
                const { id, ...newMap } = customerMap;
                return {
                    ...newMap,
                    decision: "accepted"
                };
            });

        axios
            .put(`/api/v1/customers/mapping-intervention`, { mappings: customerMap })
            .then(({ data }) => {
                console.log(data);
                cancelAllLoading();
                // setData(data);
            })
            .catch((e) => {
                cancelAllLoading();
                addToast({
                    type: "alert",
                    content: "There was an error accepting customer mapping.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const handleRejectCustomerMapping = (customerMapping) => {
        const customerMap = customerMapping
            .map(customerMap => ({
                ...customerMap,
                decision: "rejected"
            }));

        axios
            .put(`/api/v1/customers/mapping-intervention`, { mappings: customerMap })
            .then(({ data }) => {
                console.log(data);
                cancelAllLoading();
                // setData(data);
            })
            .catch((e) => {
                cancelAllLoading();
                addToast({
                    type: "alert",
                    content: "There was an error rejecting customer mapping.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            per_page: perPageValue,
            page: selectedPageValue,
            queue_type: queueType,
            ...newData
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${data.order_by ? `&orderBy=${data.order_by}`: ''}${data.order_dir ? `&orderDir=${data.order_dir}` : ''}${data.queue_type ? `&queueType=${data.queue_type}`: ''}`
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1
        });
        getCustomerMapping({
            order_by: sortByValue,
            order_dir: value,
            page: 1
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1
            });
            getCustomerMapping({
                per_page: value,
                page: 1
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected);

        setParams({
            page: selected + 1
        });
        getCustomerMapping({
            page: selected + 1
        });
    };

    return (
        <>
            <PageTitle title="Customer Mapping">
                <SelectField
                    value={queueType}
                    onChange={({ value }) => {
                        setQueueType(value);
                        setParams({
                            queue_type: value,
                        });
                        getCustomerMapping({
                            queue_type: value,
                        });
                    }}
                    options={[
                        {
                            text: 'Queue',
                            value: 'queue'
                        },
                        {
                            text: 'Accepted',
                            value: 'accepted'
                        },
                        {
                            text: 'Rejected',
                            value: 'rejected'
                        }
                    ]}
                />
            </PageTitle>

            <Row style={{ maxWidth: '100%' }}>
                <Column>
                    <Panel style={{ marginBottom: '4em' }}>
                        <CustomMappingSection>
                            <SearchSection>
                                <Button onClick={() => handleSelectAll()}>Select All</Button>
                                <SearchSection>
                                    <div style={{ marginRight: '1rem' }}>
                                        <DatePicker
                                            label="From"
                                            value={filters.date_from}
                                            onChange={({ value }) => setFilters({
                                                ...filters,
                                                date_from: value
                                            })}
                                        />
                                    </div>

                                    <div style={{ marginRight: '1rem' }}>
                                        <DatePicker
                                            label="To"
                                            value={filters.date_to}
                                            onChange={({ value }) => setFilters({
                                                ...filters,
                                                date_to: value
                                            })}
                                        />
                                    </div>
                                    <Button onClick={() => setFiltersShowing(!filtersShowing)}>View Filters</Button>
                                </SearchSection>
                            </SearchSection>

                            {filtersShowing &&
                                <Filters>
                                    <div style={{ marginRight: '1rem' }}>
                                        <InputField
                                            name="business_name"
                                            onChange={({ value }) => setFilters({ ...filters, business_name: value })}
                                            value={filters.business_name}
                                            label="Business Name"
                                        />
                                    </div>

                                    <div style={{ marginRight: '1rem' }}>
                                        <InputField
                                            name="postcode"
                                            onChange={({ value }) => setFilters({ ...filters, postcode: value })}
                                            value={filters.postcode}
                                            label="Postcode"
                                        />
                                    </div>

                                    <div style={{ marginRight: '1rem' }}>
                                        <SelectField
                                            name="customer_type"
                                            label="Customer Type"
                                            value={filters.customer_type}
                                            options={[]}
                                            onChange={({ value }) => setFilters({ ...filters, customer_type: value })}
                                        />
                                    </div>

                                    <div style={{ marginRight: '1rem' }}>
                                        <SelectField
                                            name="distributor"
                                            label="Distributor"
                                            value={filters.distributor}
                                            options={[]}
                                            onChange={({ value }) => setFilters({ ...filters, distributor: value })}
                                        />
                                    </div>

                                    <Button onClick={() => getCustomerMapping()}>Filter</Button>
                                </Filters>
                            }

                            <StyledTable
                                selectable={true}
                                className="dashboard-table"
                            >
                                <thead>
                                    <tr>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={orderByValue === "dealer_code" ? orderDirectionValue : undefined}
                                            onSortChange={handleSortChange}
                                            name="dealer_code"
                                        >
                                            DN
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={orderByValue === "trade_team_customer" ? orderDirectionValue : undefined}
                                            onSortChange={handleSortChange}
                                            name="trade_team_customer"
                                        >
                                            Business Name
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={orderByValue === "postcode" ? orderDirectionValue : undefined}
                                            onSortChange={handleSortChange}
                                            name="postcode"
                                        >
                                            Postcode
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={orderByValue === "customer_type" ? orderDirectionValue : undefined}
                                            onSortChange={handleSortChange}
                                            name="customer_type"
                                        >
                                            Customer Type
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={orderByValue === "address_1" ? orderDirectionValue : undefined}
                                            onSortChange={handleSortChange}
                                            name="address_1"
                                        >
                                            Address
                                        </StyledTableHeader>
                                        <StyledTableHeader />
                                    </tr>
                                </thead>


                                {!loading &&
                                <tbody>
                                {data.data.map(customerMap => (
                                    <StyledTableRow
                                        selected={selectedCustomerMapping.some(selectedCustomerMap => selectedCustomerMap.id === customerMap.mappable_id)}
                                        onClick={() => handleClickMapping(customerMap)}
                                        key={customerMap.mappable_id}
                                    >
                                        <td>
                                            <MappableEntryData>
                                                {customerMap.mappable_entry.dealer_code ? customerMap.mappable_entry.dealer_code : <>&nbsp;</>}
                                            </MappableEntryData>
                                            <br />
                                            &nbsp;
                                        </td>
                                        <td>
                                            <MappableEntryData>
                                                {customerMap.mappable_entry.trade_team_customer ? customerMap.mappable_entry.trade_team_customer : <>&nbsp;</>}
                                            </MappableEntryData>
                                            <br />
                                            {customerMap.customer_suggestion.trade_team_customer ? customerMap.customer_suggestion.trade_team_customer : <>&nbsp;</>}
                                        </td>
                                        <td>
                                            <MappableEntryData>
                                                {customerMap.mappable_entry.postcode ? customerMap.mappable_entry.postcode : <>&nbsp;</>}
                                            </MappableEntryData>
                                            <br />
                                            {customerMap.customer_suggestion.postcode ? customerMap.customer_suggestion.postcode : <>&nbsp;</>}
                                        </td>
                                        <td>
                                            <MappableEntryData>
                                                {customerMap.mappable_entry.customer_type ? customerMap.mappable_entry.customer_type : <>&nbsp;</>}
                                            </MappableEntryData>
                                            <br />
                                            {customerMap.customer_suggestion.customer_type ? customerMap.customer_suggestion.customer_type : <>&nbsp;</>}
                                        </td>
                                        <td>
                                            <MappableEntryData>
                                                {customerMap.mappable_entry.address_1 ? customerMap.mappable_entry.address_1 : <>&nbsp;</>}
                                            </MappableEntryData>
                                            <br />
                                            {customerMap.customer_suggestion.address_1 ? customerMap.customer_suggestion.address_1 : <>&nbsp;</>}
                                        </td>
                                        <td>
                                            <ButtonDropdown
                                                hidePrimaryAction={true}
                                                name="primary"
                                                actions={[
                                                    {
                                                        name: "Accept",
                                                        onClick: () => {
                                                            handleAcceptCustomerMapping([{...convertMap(customerMap)}]);
                                                        }
                                                    },
                                                    {
                                                        name: "Reject",
                                                        onClick: () => {
                                                            handleRejectCustomerMapping([{...convertMap(customerMap)}]);
                                                        }
                                                    },
                                                    ...(customerMap.customer_suggestion.constructor === Array
                                                        ? [{
                                                                name: "Create New DN",
                                                                onClick: () => setCreateNewDN(customerMap.mappable_entry)
                                                            }]
                                                        : []),
                                                    {
                                                        name: "Manual Match",
                                                        onClick: () => {
                                                        }
                                                    },
                                                    {
                                                        name: "View",
                                                        onClick: () => setViewCustomerMapping(customerMap)
                                                    },
                                                ]}
                                            />
                                        </td>
                                    </StyledTableRow>
                                ))}
                                </tbody>
                                }
                            </StyledTable>

                            {loading &&
                                <CubeLoader />
                            }

                            {(!loading && !data.data.length) &&
                                <p style={{textAlign: 'center', margin: '3em 0'}}>No results</p>
                            }

                            {(!loading && data.meta) &&
                                <PaginationContainer>
                                    <PerPageContainer>
                                        Per Page:
                                        <SelectField
                                            name="perPage"
                                            style={{ margin: '0 0 0 1em' }}
                                            value={perPageValue}
                                            options={[
                                                {value: 10, text: '10'},
                                                {value: 20, text: '20'},
                                                {value: 30, text: '30'},
                                                {value: 40, text: '40'},
                                                {value: 50, text: '50'},
                                            ]}
                                            onChange={handlePerPage}
                                        />
                                    </PerPageContainer>
                                    <Pagination
                                        currentPage={selectedPageValue - 1}
                                        total={data.meta.total}
                                        pageCount={data.meta.last_page}
                                        onPageChange={handlePageChange}
                                        previousLabel="&laquo;"
                                        nextLabel="&raquo;"
                                    />
                                    <TotalContainer>Total: {data.meta.total}</TotalContainer>
                                </PaginationContainer>
                            }
                        </CustomMappingSection>
                    </Panel>
                </Column>
            </Row>

            {!!selectedCustomerMapping.length &&
            <SelectedCustomerMapping>
                <span><b>Selected {selectedCustomerMapping.length} Customer Mapping(s)</b></span>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '1em' }}>
                        <ButtonDropdown
                            onClick={() => setSelectedCustomerMapping([])}
                            actions={[]}
                            name="primary">
                            Cancel
                        </ButtonDropdown>
                    </span>
                    <span>
                        <ButtonDropdown
                            onClick={() => {
                                setMultiLoading(true);
                                handleAcceptCustomerMapping(selectedCustomerMapping);
                            }}
                            name="primary"
                            actions={[
                                {
                                    name: "Reject",
                                    onClick: () => {
                                        setMultiLoading(true);
                                        handleRejectCustomerMapping(selectedCustomerMapping);
                                    }
                                }
                            ]}>
                            Accept
                        </ButtonDropdown>
                    </span>

                    {multiLoading &&
                    <span style={{
                        marginLeft: '2em',
                    }}>
                            <CubeLoader size={20} margin="0"/>
                        </span>
                    }
                </span>
            </SelectedCustomerMapping>
            }

            {!!viewCustomerMapping &&
                <Modal
                    visible={!!viewCustomerMapping}
                    onClose={() => setViewCustomerMapping()}
                >
                    <ViewCustomerMappingTitle>
                        <ViewCustomerMappingSideColumn />
                        <ViewCustomerMappingMainColumn>Suggested Mapping</ViewCustomerMappingMainColumn>
                        <ViewCustomerMappingMainColumn>Incoming Record</ViewCustomerMappingMainColumn>
                    </ViewCustomerMappingTitle>
                    <div>
                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Distrigo Number
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.customer_suggestion.trade_team_id}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.trade_team_id}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Company Number
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.customer_suggestion.company_number}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.company_number}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                RRDI
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {/*  Not sure what goes here  */}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {/*  Not sure what goes here  */}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Business Name
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.customer_suggestion.trade_team_customer}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.trade_team_customer}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Postcode
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.customer_suggestion.postcode}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.postcode}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>

                        <ViewCustomerMappingRow>
                            <ViewCustomerMappingSideColumn>
                                Address
                            </ViewCustomerMappingSideColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.customer_suggestion.address_1}
                            </ViewCustomerMappingMainColumn>
                            <ViewCustomerMappingMainColumn>
                                {viewCustomerMapping.mappable_entry.address_1}
                            </ViewCustomerMappingMainColumn>
                        </ViewCustomerMappingRow>
                    </div>
                    <div style={{ marginTop: '1rem', textAlign: 'right' }}>
                        <Button
                            onClick={() => {
                                handleAcceptCustomerMapping([{...convertMap(viewCustomerMapping)}]);
                            }}
                            style={{ marginRight: '1rem' }}>
                            Reject Link
                        </Button>
                        <Button
                            onClick={() => {
                                handleRejectCustomerMapping([{...convertMap(viewCustomerMapping)}]);
                            }}
                        >
                            Accept Link
                        </Button>
                    </div>
                </Modal>
            }

            {createNewDN &&
                <Modal
                    visible={!!createNewDN}
                    onClose={() => setCreateNewDN()}
                >
                    <div style={{ padding: "2rem" }}>
                        <h2>Create New DN</h2>

                        <InputField
                            label="Trade Team Customer Name"
                            name="trade_team_customer"
                            value={createNewDN.trade_team_customer}
                        />

                        <InputField
                            label="Trade Team Customer Type"
                            name="trade_team_customer_type"
                            value={createNewDN.trade_team_customer_type}
                        />

                        <InputField
                            label="Addres 1"
                            name="address_1"
                            value={createNewDN.address_1}
                        />

                        <InputField
                            label="Address 2"
                            name="address_2"
                            value={createNewDN.address_2}
                        />

                        <InputField
                            label="town"
                            name="town"
                            value={createNewDN.town}
                        />

                        <InputField
                            label="Postcode"
                            name="postcode"
                            value={createNewDN.postcode}
                        />

                        <InputField
                            label="Phone Number"
                            name="phone"
                            value={createNewDN.phone}
                        />

                        <InputField
                            label="Mobile Number"
                            name="mobile"
                            value={createNewDN.mobile}
                        />

                        <InputField
                            label="Email"
                            name="email"
                            value={createNewDN.email}
                        />

                        <InputField
                            label="Company Number"
                            name="company_number"
                            value={createNewDN.company_number}
                        />
                        <Button>
                            Create
                        </Button>
                    </div>
                </Modal>
            }
        </>
    );
};

export default CustomerMapping;
