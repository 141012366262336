import React, { useState, useEffect } from "react";
import axios from "axios";
import { isEmpty } from "lodash";

import { Modal, Pagination } from "@cortexglobal/rla-components";
import { ButtonDropdown } from "@cortexglobal/lens-components";

import {
    FlexRow,
    LimitedWidthRow,
    LittleLoader,
} from "../../../components/LayoutComponents";

import {
    Table,
    TableData,
    TableHeader,
    TableRow,
} from "../../../components/TableComponents";

import CentreForm from "./CentreForm";
import LoadingPage from "../../../components/LoadingPage";
import CentreRow from "./CentreRow";

const Centres = (props) => {
    const [centres, setCentres] = useState({
        data: [],
        loading: true,
    });

    const [pagination, setPagination] = useState({});
    const [adding, setAdding] = useState(false);

    const closeAddModal = () => {
        setAdding(false);
    };

    const addCentre = (newCentre) => {
        setCentres({
            ...centres,
            data: [{ new: true, ...newCentre }, ...centres.data],
        });
    };

    const refreshCentre = (newCentre) => {
        setCentres({
            ...centres,
            data: centres.data.map((centre) =>
                newCentre.uuid === centre.uuid
                    ? { new: true, ...centre, ...newCentre }
                    : centre
            ),
        });
    };

    const getList = (page) => {
        setCentres({ ...centres, loading: true });

        const params = {
            page: page === undefined ? 1 : page,
        };

        axios
            .get(`/api/v1/centre`, { params: params })
            .then(({ data }) => {
                setCentres({ loading: false, data: data.data });
                setPagination(data.meta);
            })
            .catch((e) => {
                setCentres({ ...centres, loading: false });
            });
    };

    useEffect(() => {
        getList(1);
    }, []);

    const renderBody = () => {
        if (centres.loading) {
            return <LoadingPage title="Loading centre data" />;
        }

        return (
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Centre Name</TableHeader>
                        <TableHeader>Participating</TableHeader>
                        <TableHeader>Email</TableHeader>
                        <TableHeader>Telephone</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Actions</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {isEmpty(centres.data) ? (
                        <TableRow>
                            <TableData colSpan="100%">
                                No centres found
                            </TableData>
                        </TableRow>
                    ) : (
                        centres.data.map((centre) => {
                            return (
                                <CentreRow
                                    key={centre.uuid}
                                    centre={centre}
                                    refreshCentre={refreshCentre}
                                />
                            );
                        })
                    )}
                </tbody>
            </Table>
        );
    };

    return (
        <LimitedWidthRow>
            <FlexRow style={{ marginBottom: "2rem" }}>
                <h3 style={{ flexGrow: "1" }}>Centres</h3>
                {centres.loading && <LittleLoader icon="circle-notch" spin />}

                <div style={{ alignSelf: "center" }}>
                    <ButtonDropdown onClick={() => setAdding(true)}>
                        Add
                    </ButtonDropdown>
                </div>
            </FlexRow>

            {renderBody()}

            {!isEmpty(pagination) && (
                <Pagination
                    pageRangeDisplayed={5}
                    currentPage={pagination.current_page - 1}
                    pageCount={pagination.last_page}
                    onPageChange={(e) => getList(e.selected + 1)}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
            )}

            {adding && (
                <Modal
                    visible={adding}
                    onClose={() => setAdding(false)}
                    maxWidth="75%"
                >
                    <div style={{ padding: "2rem" }}>
                        <CentreForm
                            centre={{}}
                            refreshCentre={addCentre}
                            successCallback={closeAddModal}
                        />
                    </div>
                </Modal>
            )}
        </LimitedWidthRow>
    );
};

export default Centres;
