import React, { useState } from "react";
import moment from "moment";

import { TableData, TableRow } from "../../../components/TableComponents";

const EmailRow = ({ email, refreshEmail }) => {
    const parseDate = (date) => {
        if (!date) {
            return "";
        }

        return moment.utc(date).format("DD/MM/YYYY HH:mm");
    };

    return (
        <TableRow>
            <TableData>{email.identifier}</TableData>
            <TableData>{email.to_email}</TableData>
            <TableData>{email.subject}</TableData>
            <TableData>{parseDate(email.sent)}</TableData>
            <TableData>{parseDate(email.delivered)}</TableData>
            <TableData>{parseDate(email.opened)}</TableData>
            <TableData>{parseDate(email.clicked)}</TableData>
            <TableData>{parseDate(email.complained)}</TableData>
            <TableData>{parseDate(email.failed)}</TableData>
            <TableData>{parseDate(email.unsubscribed)}</TableData>
        </TableRow>
    );
};

export default EmailRow;
