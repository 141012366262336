import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    UserInfoTitle,
    Row,
    Column,
    SidePanel,
    Menu,
    MenuItem,
    Button,
    CloseButton,
    UserInfoIcon,
    StyledLink,
} from "@cortexglobal/rla-components";

import { AuthContext, logout as logoutAction } from "@cortexglobal/lens-auth";

import { SubmitButton } from "../FormComponents";

import Logo from "../Logo";
import { LimitedWidthRow } from "../LayoutComponents";
const HeaderOuter = styled.div`
    border-bottom: 0.0625rem solid #e3e4e5;
    margin-bottom: 2.9375rem;
`;
const Header = styled(LimitedWidthRow)`
    display: flex;
    min-height: 80px;
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
`;
const LogoSection = styled.div`
    align-items: flex-start;
    margin-right: 2rem;
`;
const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    border: 0.0625rem solid #e3e4e5;
    border-top: none;
    width: 8.3125rem;
    height: 5rem;
    padding: 1.75rem 0.25rem 1.875rem 0.25rem;
    margin-bottom: -0.9375rem;
`;

const MenuLink = styled(Link)`
    height: 100%;
    align-items: center;
    display: flex;
    text-decoration: none;

    &:hover {
        color: #d5001c;
        border-bottom: 2px solid #d5001c;
    }
`;

const MenuLinkContainer = styled.div`
    margin-right: 1rem;
    height: 123px;
`;

const HeaderSection = styled.div`
    flex-grow: 1;
    height: 123px;
    display: flex;
    align-items: center;
`;

const UserInfoSubTitle = styled.p`
    text-align: right;
    font-size: 0.8rem;
`;

const MenuButton = (props) => {
    return (
        <Button
            {...props}
            style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "90%",
                padding: "0.75rem 1rem",
            }}
        >
            {props.children}
        </Button>
    );
};

const PrivateHeader = (theme) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const { setLoadingState, user } = useContext(AuthContext);

    const logout = () => {
        setLoadingState("loading");
        logoutAction();
    };

    return (
        <>
            <HeaderOuter>
                <Header>
                    <HeaderSection>
                        <LogoSection>
                            <LogoContainer>
                                <Logo />
                            </LogoContainer>
                        </LogoSection>

                        <h1
                            style={{
                                margin: "0 2rem 0 0",
                                fontSize: "1.5rem",
                            }}
                        >
                            Classic Register
                        </h1>

                        <MenuLinkContainer>
                            <MenuLink to="/centre-area">Dashboard</MenuLink>
                        </MenuLinkContainer>
                        <MenuLinkContainer>
                            <MenuLink to="/centre-area/register">
                                Registration Form
                            </MenuLink>
                        </MenuLinkContainer>
                    </HeaderSection>
                    <div>
                        <UserInfoTitle>
                            {get(user, "name", "...")}
                        </UserInfoTitle>
                        <UserInfoSubTitle>
                            {get(user, "centre.name", "Head Office")}
                        </UserInfoSubTitle>
                    </div>
                    <div>
                        <UserInfoIcon
                            style={{
                                margin: "1rem 0 1.1rem 1rem",
                            }}
                            onClick={() => setMenuVisible(!menuVisible)}
                        >
                            <FontAwesomeIcon icon="bars" size="lg" />
                        </UserInfoIcon>
                    </div>
                </Header>
            </HeaderOuter>
            <SidePanel
                visible={menuVisible}
                onClose={() => setMenuVisible(false)}
                width="350px"
                zIndex={2000}
            >
                <Row style={{ paddingTop: "1rem" }}>
                    <Column medium={9}>
                        <p>&nbsp;</p>
                    </Column>
                    <Column medium={3}>
                        <CloseButton onClick={() => setMenuVisible(false)} />
                    </Column>
                </Row>

                <Menu>
                    <div
                        style={{
                            textAlign: "center",
                            margin: "1rem 0 2rem 0",
                        }}
                    >
                        <Logo />
                    </div>
                    <MenuItem>
                        <StyledLink to="/centre-area">Dashboard</StyledLink>
                    </MenuItem>
                    <MenuItem>
                        <StyledLink to="/centre-area/emails">Emails</StyledLink>
                    </MenuItem>
                    {!!user.centre && (
                        <>
                            <MenuItem>
                                <StyledLink to="/centre-area/register">
                                    Registration Form
                                </StyledLink>
                            </MenuItem>
                            <MenuItem>
                                <StyledLink to="/centre-area/centre">
                                    My Centre
                                </StyledLink>
                            </MenuItem>
                        </>
                    )}
                    {user.admin && (
                        <>
                            <MenuItem>
                                <StyledLink to="/centre-area/centres">
                                    Centres
                                </StyledLink>
                            </MenuItem>
                            <MenuItem>
                                <StyledLink to="/centre-area/users">
                                    Users
                                </StyledLink>
                            </MenuItem>
                            <MenuItem>
                                <StyledLink to="/centre-area/reports">
                                    Reporting
                                </StyledLink>
                            </MenuItem>
                        </>
                    )}
                    <MenuItem>
                        <StyledLink to="/centre-area/profile">
                            My Profile
                        </StyledLink>
                    </MenuItem>
                </Menu>

                <div
                    style={{
                        padding: "2rem",
                        textAlign: "right",
                    }}
                >
                    <SubmitButton onClick={logout} hasArrow={true}>
                        Logout
                    </SubmitButton>
                </div>
            </SidePanel>
        </>
    );
};

export default withTheme(PrivateHeader);
