import React from "react";
import styled from "styled-components";

import { SubmitButton } from "../../../components/FormComponents";
import { DetailsContainer, DetailsLabel } from "../RenewalForm";

const Registration = styled.div`
    background: #ffcf1d;
    font-weight: bold;
    padding: 0.5em;
    border-radius: 3px;
    text-transform: uppercase;
    display: inline-block;
`;

export const VehicleConfirmationBox = ({ vehicle }) => {
    return (
        <DetailsContainer style={{ marginBottom: "2em" }}>
            <table>
                <tbody>
                <tr>
                    <DetailsLabel>
                        Registration:
                    </DetailsLabel>
                    <td>
                        <Registration>
                            {vehicle.registration}
                        </Registration>
                    </td>
                </tr>
                <tr>
                    <DetailsLabel>
                        Model:
                    </DetailsLabel>
                    <td>
                        {vehicle.model}
                    </td>
                </tr>
                <tr>
                    <DetailsLabel>
                        Year:
                    </DetailsLabel>
                    <td>
                        {vehicle.year}
                    </td>
                </tr>
                <tr>
                    <DetailsLabel>
                        Vin:
                    </DetailsLabel>
                    <td>
                        {vehicle.vin}
                    </td>
                </tr>
                </tbody>
            </table>
        </DetailsContainer>
    )
}

const VehicleConfirmation = ({
    vehicle,
    setStep
}) => {
    return (
        <>
            <h3>Do you still own this vehicle?</h3>

            <VehicleConfirmationBox vehicle={vehicle} />

            <SubmitButton
                onClick={() => setStep(1.5)}
                style={{ marginRight: "1em" }}
                color="grey"
                type="button"
            >
                No
            </SubmitButton>
            <SubmitButton
                onClick={() => setStep(2)}
                hasArrow
                color="red"
                type="button"
            >
                Yes, I still own this vehicle
            </SubmitButton>
        </>
    );
};

export default VehicleConfirmation;