import React from "react";
import moment from "moment";
import { isEmpty, get } from "lodash";

import {
    TableList,
    Pagination,
    Loading,
    Button,
    useAddToast,
} from "@cortexglobal/rla-components";
import axios from "axios";

const ReportListing = (props) => {
    const addToast = useAddToast();

    const {
        reports = [],
        pagination = {},
        reload = () => {},
        loadingState = "loaded",
    } = props;

    const downloadReport = (reportUuid) => {
        // setLoading(true);
        axios
            .get(`/api/v1/reporting/${reportUuid}`)
            .then(({ data }) => {
                window.open(data.data.link);
            })
            .catch((e) => {
                // setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error processing your report.",
                    showFor: 5000,
                });
            });
    };

    const renderBody = () => {
        {
            if (loadingState === "loading") {
                return (
                    <tr>
                        <td colSpan="100%">
                            <Loading />
                        </td>
                    </tr>
                );
            }

            if (isEmpty(reportLog)) {
                return (
                    <tr>
                        <td colSpan="100%">No reports generated</td>
                    </tr>
                );
            }

            return reports.map((reportLog) => {
                const dateReportCreated = moment(reportLog.created_at).format(
                    "DD/MM/YYYY HH:mm"
                );
                const start = moment(reportLog.requested_date_from).format(
                    "DD/MM/YYYY"
                );
                const end = reportLog.requested_date_to
                    ? moment(reportLog.requested_date_to).format("DD/MM/YYYY")
                    : "-";
                return (
                    <tr key={reportLog.uuid}>
                        <td>{dateReportCreated}</td>
                        <td>{start}</td>
                        <td>{end}</td>
                        <td>{reportLog.report_type}</td>
                        <td>{reportLog.status}</td>
                        <td>
                            <Button
                                disabled={reportLog.status !== "File Ready"}
                                onClick={() => {
                                    downloadReport(reportLog.uuid);
                                }}
                            >
                                Download
                            </Button>
                        </td>
                    </tr>
                );
            });
        }
    };

    return (
        <>
            <TableList>
                <thead>
                    <tr>
                        <th>Date Report Requested</th>
                        <th>Date Requested To</th>
                        <th>Date Requested From</th>
                        <th>Report Type</th>
                        <th>Report Status</th>
                        <th>Download Report</th>
                    </tr>
                </thead>
                <tbody>{renderBody()}</tbody>
            </TableList>

            {!isEmpty(pagination) && (
                <Pagination
                    pageRangeDisplayed={5}
                    currentPage={pagination.current_page - 1}
                    pageCount={pagination.last_page}
                    onPageChange={(e) => reload(e.selected + 1)}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
            )}
        </>
    );
};

export default ReportListing;
