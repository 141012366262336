import React, { useState } from "react";

import {
    Error,
    Required,
    SmallLabel,
    StyledField,
    StyledLabel,
    SubmitButton,
    SubmitSection
} from "../../../components/FormComponents";
import {
    CustomRadio,
    RadioCheck,
    RadioContainer,
    RadioContainerOuter,
    UnstyledButton
} from "../../../components/registration/registrationComponents";

const PorscheCentreForm = ({
    setFieldValue,
    values,
    settings,
    touched,
    errors,
    setStep,
    centres,
    setNewPorscheCentre,
    newPorscheCentre
}) => {
    const [chooseDifferentCentre, setChooseDifferentCentre] = useState(false);

    // console.log(values.centre_uuid, newPorscheCentre);

    // console.log(settings
    //     .data
    //     .centres[0]
    //     .uuid, values.centre_uuid)

    return (
        <>
            <StyledLabel>
                Centre Locator
                <Required />
            </StyledLabel>
            <SmallLabel>
                Select your preferred
                centre from the list
                below
            </SmallLabel>

            <RadioContainerOuter>
                <RadioContainer>
                    <CustomRadio
                        onChange={() => {
                            setNewPorscheCentre(settings
                                .data
                                .centres[0].name);
                            setFieldValue(
                                "centre_uuid",
                                settings
                                    .data
                                    .centres[0]
                                    .uuid
                            );
                        }}
                        checked={
                            values.centre_uuid ===
                            settings
                                .data
                                .centres[0]
                                .uuid
                        }
                        value={
                            settings
                                .data
                                .centres[0]
                                .uuid
                        }
                        type="checkbox"
                    />
                    <RadioCheck className="checkmark" />
                    {
                        settings.data
                            .centres[0]
                            .name
                    }{" "}
                    (1st closest)
                </RadioContainer>
                {settings.data.centres[0].distance.toFixed(
                    1
                )}{" "}
                Miles
            </RadioContainerOuter>
            <RadioContainerOuter>
                <RadioContainer>
                    <CustomRadio
                        onChange={() => {
                            setNewPorscheCentre(settings
                                .data
                                .centres[1].name);
                            setFieldValue(
                                "centre_uuid",
                                settings
                                    .data
                                    .centres[1]
                                    .uuid
                            );
                        }}
                        checked={
                            values.centre_uuid ===
                            settings
                                .data
                                .centres[1]
                                .uuid
                        }
                        value={
                            settings
                                .data
                                .centres[1]
                                .uuid
                        }
                        type="checkbox"
                    />
                    <RadioCheck className="checkmark" />
                    {
                        settings.data
                            .centres[1]
                            .name
                    }{" "}
                    (2nd closest)
                </RadioContainer>
                {settings.data.centres[1].distance.toFixed(
                    1
                )}{" "}
                Miles
            </RadioContainerOuter>
            <RadioContainerOuter>
                <RadioContainer>
                    <CustomRadio
                        onChange={() => {
                            setNewPorscheCentre(settings
                                .data
                                .centres[2].name);
                            setFieldValue(
                                "centre_uuid",
                                settings
                                    .data
                                    .centres[2]
                                    .uuid
                            );
                        }}
                        checked={
                            values.centre_uuid ===
                            settings
                                .data
                                .centres[2]
                                .uuid
                        }
                        value={
                            settings
                                .data
                                .centres[2]
                                .uuid
                        }
                        type="checkbox"
                    />
                    <RadioCheck className="checkmark" />
                    {
                        settings.data
                            .centres[2]
                            .name
                    }{" "}
                    (3rd closest)
                </RadioContainer>
                {settings.data.centres[2].distance.toFixed(
                    1
                )}{" "}
                Miles
            </RadioContainerOuter>

            {chooseDifferentCentre ? (
                <UnstyledButton
                    type="button"
                    onClick={
                        () => setChooseDifferentCentre(false)
                    }
                >
                    Close different
                    centre selector
                </UnstyledButton>
            ) : (
                <UnstyledButton
                    type="button"
                    onClick={
                        () => setChooseDifferentCentre(true)
                    }
                >
                    Click here if you
                    would like to select
                    a different centre
                </UnstyledButton>
            )}

            {chooseDifferentCentre && (
                <div
                    style={{
                        marginTop: "2rem",
                    }}
                >
                    <StyledLabel htmlFor="centre_uuid">
                        Select Centre
                    </StyledLabel>

                    <StyledField
                        value={
                            values.centre_uuid
                        }
                        as="select"
                        name="centre_uuid"
                        onChange={(value) =>
                            {
                                setNewPorscheCentre(centres.filter(centre => centre.uuid === value.target.value)[0].name);
                                setFieldValue(
                                    "centre_uuid",
                                    value.target
                                        .value
                                )
                            }

                        }
                    >
                        <option value="">
                            Select
                        </option>

                        {centres.map(
                            ({
                                 uuid,
                                 name,
                                 distance,
                             }) => (
                                <option
                                    value={uuid}
                                    key={uuid}
                                >
                                    {name}{" "}
                                    {typeof distance !==
                                    "undefined" &&
                                    `${distance.toFixed(
                                        2
                                    )} miles`}
                                </option>
                            )
                        )}
                    </StyledField>
                </div>
            )}

            {errors && errors.centre_uuid &&
            touched.centre_uuid ? (
                <Error
                    style={{
                        marginTop: "1rem",
                    }}
                >
                    {errors.centre_uuid}
                </Error>
            ) : null}

            <SubmitSection>
                <SubmitButton
                    onClick={() => setStep(4)}
                    hasArrow
                    color="red"
                    type="button"
                >
                    Next
                </SubmitButton>
            </SubmitSection>

            <div
                onClick={() => setStep(3)}
                style={{ marginTop: "1em" }}
            >
                <UnstyledButton
                    type="button"
                >
                    {"< Go Back"}
                </UnstyledButton>
            </div>
        </>
    );
};

export default PorscheCentreForm;