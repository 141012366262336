import React, { useEffect, useState } from "react";
import {Button, Modal, Pagination, SelectField, useAddToast} from "@cortexglobal/rla-components";
import axios from "axios";
import { CubeLoader } from "../index";
import queryString from "querystring";
import {
    useLocation
} from "react-router-dom";
import { Link } from "react-router-dom";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
} from "../table/StyledTable";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyLinkedAccounts = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    communications,
    meta,
}) => {
    return (
        <>
            <StyledTable
                className="linked-accounts-table"
            >
                <thead>
                <tr>
                    <StyledTableHeader
                        name="communication_name"
                    >
                        Communication Name
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="status"
                    >
                        Status
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="sent_at"
                    >
                        Sent at
                    </StyledTableHeader>
                    {communications.some(({ opened_at }) => !!opened_at) &&
                    <StyledTableHeader
                        name="clicked_at"
                    >
                        opened at
                    </StyledTableHeader>
                    }
                    {communications.some(({ clicked_at }) => !!clicked_at) &&
                        <StyledTableHeader
                            name="clicked_at"
                        >
                            clicked at
                        </StyledTableHeader>
                    }
                    {communications.some(({ failed_at }) => !!failed_at) &&
                    <StyledTableHeader
                        name="failed_at"
                    >
                        failed at
                    </StyledTableHeader>
                    }
                </tr>
                </thead>
                <tbody>
                {communications.map((communication) => (
                    <StyledTableRow
                        key={communication.uuid}
                    >
                        <td>
                            {communication.communication.title}
                        </td>
                        <td>
                            {communication.status}
                        </td>
                        {communications.some(({sent_at}) => !!sent_at) &&
                        <td>
                            {communication.sent_at}
                        </td>
                        }
                        {communications.some(({opened_at}) => !!opened_at) &&
                        <td>
                            {communication.opened_at}
                        </td>
                        }
                        {communications.some(({clicked_at}) => !!clicked_at) &&
                        <td>
                            {communication.clicked_at}
                        </td>
                        }
                        {communications.some(({failed_at}) => !!failed_at) &&
                        <td>
                            {communication.failed_at}
                        </td>
                        }
                    </StyledTableRow>
                ))}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{ margin: '0 0 0 1em' }}
                        value={perPageValue}
                        options={[
                            {value: 10, text: '10'},
                            {value: 20, text: '20'},
                            {value: 30, text: '30'},
                            {value: 40, text: '40'},
                            {value: 50, text: '50'},
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={selectedPageValue - 1}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>
        </>
    );
};

export default CompanyLinkedAccounts;