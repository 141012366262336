import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import Logo from "../../components/Logo";

import {
    Container,
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    LogoContainer,
} from "../../components/FormComponents";

import { ThankyouBanner } from "../../components/registration/registrationComponents";
import LoadingPage from "../../components/LoadingPage";

const DetailSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
});

const PasswordChangeSchema = Yup.object().shape({
    current_password: Yup.string().required("Required"),
    new_password: Yup.string().required("Required"),
    confirm_password: Yup.string().required("Required"), //TODO And matches new
});

const ProfileForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");
    const [profile, setProfile] = useState({
        loaded: false,
        error: false,
        data: {},
    });

    useEffect(() => {
        axios
            .get(`/api/v1/profile`)
            .then(({ data }) => {
                setProfile({ loaded: true, error: false, data: data.data });
            })
            .catch((e) => {
                setProfile({ loaded: true, error: true, data: {} });
            });
    }, []);

    const handleDetailsOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        axios
            .put(`/api/v1/profile`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    const handlePasswordOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);
        axios
            .put(`/api/v1/profile/password-update`, values)
            .then(({ data }) => {
                setSubmitting(false);
                setSubmitSuccessful(true);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);
            });
    };

    if (!profile.loaded) {
        return <LoadingPage title="Loading your details" />;
    }

    return (
        <>
            {submitSuccessful ? (
                <>
                    <LogoContainer>
                        <Logo />
                    </LogoContainer>
                    <ThankyouBanner />
                    <Container>
                        <div style={{ padding: "3rem 0 " }}>
                            <h1>Profile submitted successfully</h1>
                        </div>
                    </Container>
                </>
            ) : (
                <>
                    <Container>
                        <>
                            <h1
                                className="title"
                                style={{ marginBottom: "3rem" }}
                            >
                                Your Details
                            </h1>
                            <Formik
                                initialValues={{
                                    name: profile.data.name,
                                    first_name: profile.data.first_name,
                                    last_name: profile.data.last_name,
                                    email: profile.data.email,
                                }}
                                validationSchema={DetailSchema}
                                onSubmit={handleDetailsOnSubmit}
                            >
                                {({ errors, touched }) => {
                                    return (
                                        <Form>
                                            <StyledLabel htmlFor="first_name">
                                                First Name <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="first_name"
                                                name="first_name"
                                            />
                                            {errors.first_name &&
                                            touched.first_name ? (
                                                <Error>
                                                    First Name is required
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="last_name">
                                                Last Name <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="last_name"
                                                name="last_name"
                                            />
                                            {errors.last_name &&
                                            touched.last_name ? (
                                                <Error>
                                                    Last Name is required
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="email">
                                                Email <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="email"
                                                name="email"
                                            />
                                            {errors.email && touched.email ? (
                                                <Error>Email is required</Error>
                                            ) : null}

                                            {/* <StyledLabel htmlFor="password">
                                            Password <Required />
                                        </StyledLabel>
                                        <StyledField
                                            id="password"
                                            name="password"
                                        />
                                        {errors.password &&
                                        touched.password ? (
                                            <Error>
                                                Password is required
                                            </Error>
                                        ) : null} */}

                                            {submitError && (
                                                <Error>{submitError}</Error>
                                            )}
                                            <SubmitSection>
                                                <SubmitButton
                                                    hasArrow={true}
                                                    type="submit"
                                                >
                                                    Submit
                                                </SubmitButton>
                                                {submitting && <Loader />}
                                            </SubmitSection>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>
                    </Container>
                    <Container>
                        <>
                            <h1
                                className="title"
                                style={{ marginBottom: "3rem" }}
                            >
                                Reset Password
                            </h1>
                            <Formik
                                initialValues={{
                                    current_password: "",
                                    new_password: "",
                                    confirm_password: "",
                                }}
                                validationSchema={PasswordChangeSchema}
                                onSubmit={handlePasswordOnSubmit}
                            >
                                {({ errors, touched }) => {
                                    return (
                                        <Form>
                                            <StyledLabel htmlFor="current_password">
                                                Current Password <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="current_password"
                                                name="current_password"
                                                type="password"
                                            />
                                            {errors.current_password &&
                                            touched.current_password ? (
                                                <Error>
                                                    Current Password is required
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="new_password">
                                                New Password <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="new_password"
                                                name="new_password"
                                                type="password"
                                            />
                                            {errors.new_password &&
                                            touched.new_password ? (
                                                <Error>
                                                    New Password is required
                                                </Error>
                                            ) : null}

                                            <StyledLabel htmlFor="confirm_password">
                                                Confirm Password <Required />
                                            </StyledLabel>
                                            <StyledField
                                                id="confirm_password"
                                                name="confirm_password"
                                                type="password"
                                            />
                                            {errors.confirm_password &&
                                            touched.confirm_password ? (
                                                <Error>
                                                    {errors.confirm_password}
                                                </Error>
                                            ) : null}

                                            {submitError && (
                                                <Error>{submitError}</Error>
                                            )}
                                            <SubmitSection>
                                                <SubmitButton
                                                    hasArrow={true}
                                                    type="submit"
                                                >
                                                    Reset Password
                                                </SubmitButton>
                                                {submitting && <Loader />}
                                            </SubmitSection>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </>
                    </Container>
                </>
            )}
        </>
    );
};

export default ProfileForm;
