import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Pagination,
    Loading,
    Button,
    useAddToast,
    LoadingIconSmall,
} from "@cortexglobal/rla-components";

import { isEmpty } from "lodash";
import moment from "moment";

import {
    Table,
    TableHeader,
    TableRow,
    TableData,
} from "../../../components/TableComponents";
import { PaginationContainer } from "../../../components/PaginationComponents";
import ButtonDropdown from "@cortexglobal/lens-components/lib/ButtonDropdown";

const UserReports = (props) => {
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loadingState, setLoadingState] = useState("loaded");
    const [loadingReport, setLoadingReport] = useState("");

    const addToast = useAddToast();

    const getList = (page) => {
        setLoadingState("loading");

        const params = {
            page: page === undefined ? 1 : page,
        };

        axios
            .get(`/api/v1/reporting`, { params: params })
            .then(({ data }) => {
                setReports(data.data);
                setPagination(data.meta);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    useEffect(() => {
        getList(1);
    }, [props.reload]);

    const downloadReport = (reportUuid) => {
        axios
            .get(`/api/v1/reporting/${reportUuid}/download`)
            .then(({ data }) => {
                window.open(data.data.link);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error processing your report.",
                    showFor: 5000,
                });
            });
    };

    const getReport = (reportUuid) => {
        setLoadingReport(reportUuid);
        axios
            .get(`/api/v1/reporting/${reportUuid}`)
            .then(({ data }) => {
                setLoadingReport("");
                setReports(
                    reports.map((report) => {
                        return report.uuid === reportUuid ? data.data : report;
                    })
                );
            })
            .catch((e) => {
                setLoadingReport("");
                addToast({
                    type: "alert",
                    content: "There was an error fetching the report.",
                    showFor: 5000,
                });
            });
    };

    const renderBody = () => {
        {
            if (loadingState === "loading") {
                return (
                    <TableRow>
                        <TableData colSpan="100%">
                            <Loading />
                        </TableData>
                    </TableRow>
                );
            }

            if (isEmpty(reports)) {
                return (
                    <TableRow>
                        <TableData colSpan="100%">
                            No reports generated
                        </TableData>
                    </TableRow>
                );
            }

            return reports.map((reportLog) => {
                const dateReportCreated = moment(reportLog.created_at).format(
                    "DD/MM/YYYY HH:mm"
                );
                const start = moment(reportLog.requested_date_from).format(
                    "DD/MM/YYYY"
                );
                const end = reportLog.requested_date_to
                    ? moment(reportLog.requested_date_to).format("DD/MM/YYYY")
                    : "-";

                return (
                    <TableRow
                        key={reportLog.uuid}
                        style={
                            reportLog.is_very_recent === true
                                ? {
                                      backgroundColor: "#EAFFE8",
                                  }
                                : {}
                        }
                    >
                        <TableData>
                            {reportLog.is_very_recent === true ? (
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        marginRight: "7px",
                                    }}
                                >
                                    New
                                </span>
                            ) : (
                                ""
                            )}
                            {reportLog.name}
                        </TableData>
                        <TableData>{start}</TableData>
                        <TableData>{end}</TableData>
                        <TableData>{dateReportCreated}</TableData>

                        <TableData>{reportLog.status}</TableData>
                        <TableData>
                            {reportLog.status === "Ready" && (
                                <ButtonDropdown
                                    disabled={reportLog.status !== "Ready"}
                                    onClick={() => {
                                        downloadReport(reportLog.uuid);
                                    }}
                                >
                                    Download
                                </ButtonDropdown>
                            )}
                            {reportLog.status !== "Ready" &&
                                loadingReport !== reportLog.uuid && (
                                    <Button
                                        disabled={
                                            reportLog.status === "File Ready" ||
                                            reportLog.status === "Ready" ||
                                            loadingReport === reportLog.uuid
                                        }
                                        onClick={() => {
                                            getReport(reportLog.uuid);
                                        }}
                                    >
                                        Check
                                    </Button>
                                )}
                            {loadingReport === reportLog.uuid && (
                                <LoadingIconSmall />
                            )}
                        </TableData>
                    </TableRow>
                );
            });
        }
    };

    return (
        <>
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Report</TableHeader>
                        <TableHeader>From</TableHeader>
                        <TableHeader>To</TableHeader>
                        <TableHeader>Date Requested</TableHeader>

                        <TableHeader>Status</TableHeader>
                        <TableHeader>Actions</TableHeader>
                    </TableRow>
                </thead>
                <tbody>{renderBody()}</tbody>
            </Table>

            {!isEmpty(pagination) && (
                <PaginationContainer>
                    <Pagination
                        pageRangeDisplayed={5}
                        currentPage={pagination.current_page - 1}
                        pageCount={pagination.last_page}
                        onPageChange={(e) => getList(e.selected + 1)}
                        previousLabel="&laquo;"
                        nextLabel="&raquo;"
                    />
                </PaginationContainer>
            )}
        </>
    );
};

export default UserReports;
