import React, { useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import { TableList, Pagination, Loading } from "@cortexglobal/rla-components";

const UploadListing = (props) => {
    const {
        uploads = [],
        pagination = {},
        reload = () => {},
        loadingState = "loaded",
    } = props;

    const renderBody = () => {
        {
            if (loadingState === "loading") {
                return (
                    <tr>
                        <td colSpan="100%">
                            <Loading />
                        </td>
                    </tr>
                );
            }

            return uploads.map((upload) => {
                const created = moment(upload.created_at).format(
                    "DD/MM/YYYY HH:mm"
                );

                const totalImports = upload.available_imports.length;

                return (
                    <tr key={upload.uuid}>
                        <td>{upload.identifier}</td>
                        <td>{upload.original_filename}</td>
                        <td>{upload.import_type}</td>
                        <td>{created}</td>
                        <td>{upload.status}</td>
                        <td>{totalImports}</td>
                        <td></td>
                    </tr>
                );
            });
        }
    };

    return (
        <>
            <TableList>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Uploaded</th>
                        <th>Status</th>
                        <th>Available Imports</th>
                        <th>Actions</th>
                    </tr>
                </thead>


                <tbody>{renderBody()}</tbody>
            </TableList>

            {uploads.length === 0 && <p style={{ padding: "1rem" }}>No Uploads</p>}

            {!isEmpty(pagination) && (
                <Pagination
                    pageRangeDisplayed={5}
                    currentPage={pagination.current_page - 1}
                    pageCount={pagination.last_page}
                    onPageChange={(e) => reload(e.selected + 1)}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
            )}
        </>
    );
};

export default UploadListing;
