import {
    generateTheme,
    defaultThemeOptions,
} from "@cortexglobal/rla-components";

export const colors = {
    ...defaultThemeOptions.colors,
    primary: "#dddddd",
    secondary: "#9C2018",
    accent: "#9C2018",

    //neutralDarker:
    neutralDark: "#707070",
    neutral: "#DFE0E1",
    neutralLight: "#F5F5F5",

    lightGray: "#f5f5f5",
    mediumGray: "#e8e8e8",
    darkGray: "#53565a",

    gray100: "#f9f9f9",
    gray200: "#e8e8e8",
    gray300: "#cbc9c7",
    gray400: "#bbc0c7",
    gray500: "#aaafb7",
    gray600: "#9a9fa7",
    gray700: "#53565a",
    gray800: "#404346",
    gray900: "#2b2d2f",
    black: "#040404",

    alert: "#D54644",
    warning: "#ff9800",
    success: "#4caf50",
    info: "#00bcd4",
    background: "#ffffff",
    platinum: "#a2b8e4",
    gold: "#ffdb04",
    silver: "#dadada",
    bronze: "#ff8604",
    highlight: "#e6c2c1",
};

const siteTheme = {
    body: {
        background: colors.white,
        fontSize: 16,
    },
    button: {
        types: {
            iconText: {
                icon: "chevron-right",
                boxShadow: "none",
                foregroundColor: colors.black,
                backgroundColor: colors.white,
                backgroundAlpha: 0,
                padding: 0,
                textTransform: "none",
                iconColor: "inherit",
            },
            default: {
                hollow: false,
                foregroundColor: colors.black,
                backgroundColor: colors.white,
                padding: 2,
            },
            clear: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.secondary,
            },
            submit: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.secondary,
                padding: 2,
            },
            secondary: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.gray700,
                padding: 2,
            },
            alert: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.alert,
                padding: 2,
            },
            linkStyle: {
                boxShadow: "none",
                textDecoration: "underline",
                foregroundColor: colors.black,
                backgroundColor: colors.secondary,
                backgroundAlpha: 0,
                padding: 0,
                textTransform: "none",
            },
        },
    },
    checkbox: {
        color: colors.accent,
        background: colors.white,
        stroke: colors.black,
    },
    input: {
        borderColor: colors.black,
        radius: 0,
        background: colors.white,
        disabledBackground: colors.gray200,
        color: colors.black,
        focusColor: colors.primary,
        iconColor: colors.primary,
        iconBackground: colors.lightGray,
        iconScale: 0.4,
        error: {
            color: colors.alert,
            borderColor: colors.alert,
        },
    },
    link: {
        color: colors.black,
        fontWeight: "bold",
        textDecoration: "none",
    },
    logo: {
        src: `/img/logo.png`,
        height: "80px",
    },
    modal: { color: colors.black },
    navigation: {
        background: colors.accent,
        boxShadow: `1px 1px 1px 1px ${colors.gray500}`,
        subNavigation: {
            background: colors.gray100,
            boxShadow: `1px 1px 1px 1px ${colors.gray500}`,
        },
        navLink: {
            margin: 1.4,
            padding: 1,
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 18,
            color: colors.white,
            activeColor: colors.white,
        },
        mobileNav: {
            buttonColor: colors.primary,
        },
        subNavLink: {
            fontSize: 16,
            color: colors.accent,
            textTransform: "none",
            fontWeight: "normal",
        },
    },
    pagination: {
        border: "none",
        color: colors.secondary,
        background: colors.white,
        activeColor: colors.black,
        activeBackground: colors.gray200,
        hoverBackground: "none",
    },
    pageTitle: {
        background: colors.gray200,
    },
    panel: {
        boxShadow: "0 0 7px 2px rgba(37,37,37,.05)",
    },
    search: {
        badge: {
            badgeType: {
                draft: {
                    background: colors.accent,
                    color: colors.gray200,
                },
                published: {
                    background: colors.accent,
                    color: colors.white,
                },
            },
        },
        image: {
            height: "250px",
        },
    },
    tabordion: {
        borderColor: colors.gray200,
        tabStyle: "linetabs",
        default: {
            color: colors.black,
            background: "none",
            hover: colors.gray100,
        },
        active: {
            color: colors.secondary,
            background: "none",
            borderColor: colors.accent,
        },
    },
    table: {
        types: {
            default: {
                background: colors.white,
                backgroundAlt: colors.gray200,
                rowHover: colors.gray300,
                rowHighlight: colors.highlight,
                thBackground: colors.accent,
                thColor: colors.white,
                tdLineColor: colors.mediumGray,
            },
            subtle: {
                background: colors.white,
                backgroundAlt: colors.lightGray,
                rowHover: "#545963",
                rowHighlight: colors.highlight,
                thBackground: colors.gray300,
                thColor: colors.black,
                tdLineColor: colors.mediumGray,
            },
        },
    },
    footer: {
        color: colors.white,
        background: colors.footer,
    },
    userInfo: {
        backgroundColor: "none",
    },
};

const theme = generateTheme({ colors: colors }, siteTheme);

export default theme;
