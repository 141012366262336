import React, { useState } from "react";
import { TableData, TableRow } from "../../../components/TableComponents";

import { ButtonDropdown } from "@cortexglobal/lens-components";
import { Modal } from "@cortexglobal/rla-components";

import CentreForm from "./CentreForm";

const CentreRow = ({ centre, refreshCentre }) => {
    const [editing, setEditing] = useState(false);

    // dont need this yet, will probably need this in future so commenting out for now

    // const generateActions = () => {
    //     return [
    //         {
    //             name: "Edit",
    //             onClick: () => {
    //                 setEditing(true);
    //             },
    //         },
    //     ];
    // };

    const closeEditModal = () => {
        setEditing(false);
    };

    return (
        <>
            <TableRow style={centre.new ? { backgroundColor: "#EAFFE8" } : {}}>
                <TableData>{centre.name}</TableData>
                <TableData>{centre.participating ? "Yes" : ""}</TableData>
                <TableData>{centre.email}</TableData>
                <TableData>{centre.telephone}</TableData>
                <TableData>{centre.status_wording}</TableData>
                <TableData>
                    <ButtonDropdown onClick={() => setEditing(true)}>
                        Edit
                    </ButtonDropdown>
                </TableData>
            </TableRow>

            {editing && (
                <Modal
                    visible={editing}
                    onClose={() => setEditing(false)}
                    maxWidth="75%"
                >
                    <div style={{ padding: "2rem" }}>
                        <CentreForm
                            centre={centre}
                            refreshCentre={refreshCentre}
                            successCallback={closeEditModal}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default CentreRow;
