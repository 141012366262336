import React from "react";
import moment from "moment";
import { get } from "lodash";

import {
    Table,
    TableHeader,
    TableData,
    TableRow,
} from "../../../components/TableComponents";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ViewRegistration = ({ registration }) => {
    const { customer, centre, vehicle, warnings } = registration;

    const parseDate = (date, format) => {
        format = format === undefined ? "DD/MM/YYYY HH:mm" : format;

        return date ? moment(date).format(format) : "";
    };

    const parseUser = (user) => {
        const name = get(user, "name", "") || "customer";

        return name === "customer" ? name : "user " + name;
    };

    const warningsTotal = warnings.length;

    return (
        <div style={{ padding: "2rem" }}>
            <h3>View</h3>
            {warningsTotal > 0 && (
                <>
                    <Table>
                        <tbody>
                            <TableRow>
                                <TableHeader colSpan="100%">
                                    Actions Required
                                </TableHeader>
                            </TableRow>

                            {warnings.map((w) => {
                                return (
                                    <TableRow style={{ color: "red" }}>
                                        <TableData>
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    marginRight: "5px",
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                            {w}
                                        </TableData>
                                    </TableRow>
                                );
                            })}
                        </tbody>
                    </Table>
                    <br />
                </>
            )}

            <Table>
                <tbody>
                    <TableRow>
                        <TableHeader colSpan="100%">Registration</TableHeader>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">ID</TableData>
                        <TableData>{registration.identifier}</TableData>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">Status</TableData>
                        <TableData>
                            {registration.registration_status_wording}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">Added</TableData>
                        <TableData>
                            {parseDate(registration.created_at)} By{" "}
                            {parseUser(registration.created_by)}
                        </TableData>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">Last Updated</TableData>
                        <TableData>
                            {parseDate(registration.updated_at)}
                        </TableData>
                    </TableRow>

                    {registration.status === "approved" && (
                        <TableRow>
                            <TableData className="heading">Approved</TableData>

                            <TableData>
                                {parseDate(registration.approved_at)} By{" "}
                                {parseUser(registration.approved_by)}
                            </TableData>
                        </TableRow>
                    )}

                    <TableRow>
                        <TableData className="heading">
                            Membership Start
                        </TableData>
                        {registration.status === "approved" ||
                        registration.status === "terminated" ? (
                            <TableData>
                                {parseDate(
                                    registration.membership_start_at,
                                    "DD/MM/YYYY"
                                )}
                            </TableData>
                        ) : (
                            <TableData>
                                Start date is set upon approval
                            </TableData>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">
                            Membership End
                        </TableData>
                        {registration.status === "approved" ||
                        registration.status === "terminated" ? (
                            <TableData>
                                {parseDate(
                                    registration.membership_end_at,
                                    "DD/MM/YYYY"
                                )}
                            </TableData>
                        ) : (
                            <TableData>End date is set upon approval</TableData>
                        )}
                    </TableRow>

                    {registration.status === "declined" && (
                        <>
                            <TableRow>
                                <TableData className="heading">
                                    Declined
                                </TableData>
                                <TableData>
                                    {parseDate(registration.declined_at)} By{" "}
                                    {parseUser(registration.declined_by)}
                                </TableData>
                            </TableRow>

                            <TableRow>
                                <TableData className="heading">
                                    Reason Provided
                                </TableData>
                                <TableData>
                                    {registration.declined_notes || "-"}
                                </TableData>
                            </TableRow>
                        </>
                    )}

                    {registration.status === "terminated" && (
                        <>
                            <TableRow>
                                <TableData className="heading">
                                    Terminated
                                </TableData>
                                <TableData>
                                    {parseDate(registration.terminated_at)} By{" "}
                                    {parseUser(registration.terminated_by)}
                                </TableData>
                            </TableRow>

                            <TableRow>
                                <TableData className="heading">
                                    Reason Provided
                                </TableData>
                                <TableData>
                                    {registration.terminated_notes || "-"}
                                </TableData>
                            </TableRow>
                        </>
                    )}

                    <TableRow>
                        <TableData className="heading">
                            Welcome Pack Dispatched
                        </TableData>
                        <TableData>
                            {parseDate(
                                registration.welcome_pack_dispatched_at
                            ) || "Not dispatched"}
                        </TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Renewal Link</TableData>
                        {registration.registration_status ===
                            "expired-renewable" ||
                        registration.registration_status ===
                            "active-renewable" ? (
                            <TableData>
                                <a href={registration.renewal_link}>
                                    {registration.renewal_link}
                                </a>
                            </TableData>
                        ) : (
                            <TableData>
                                Renewal is only available to active
                                registrations 30 days before and up to 60 days
                                following membership expiry
                            </TableData>
                        )}
                    </TableRow>
                </tbody>
            </Table>

            <br />

            <Table>
                <tbody>
                    <TableRow>
                        <TableHeader colSpan="100%">Centre</TableHeader>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">Name</TableData>
                        <TableData>{centre.name}</TableData>
                    </TableRow>
                </tbody>
            </Table>

            <br />

            <Table>
                <tbody>
                    <TableRow>
                        <TableHeader colSpan="100%">Vehicle</TableHeader>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">Vin</TableData>
                        <TableData>{vehicle.vin}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Model</TableData>
                        <TableData>{vehicle.model}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Registration</TableData>
                        <TableData>{vehicle.registration}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Year</TableData>
                        <TableData>{vehicle.year}</TableData>
                    </TableRow>
                </tbody>
            </Table>

            <br />

            <Table>
                <tbody>
                    <TableRow>
                        <TableHeader colSpan="100%">Customer</TableHeader>
                    </TableRow>
                    <TableRow>
                        <TableData className="heading">BP Number</TableData>
                        <TableData>{customer.bp_number || "-"}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Name</TableData>
                        <TableData>{customer.full_name}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Address</TableData>
                        <TableData>{customer.full_address}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Telephone</TableData>
                        <TableData>{customer.telephone}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Email</TableData>
                        <TableData>{customer.email}</TableData>
                    </TableRow>

                    <TableRow>
                        <TableData className="heading">Marketing</TableData>
                        <TableData>
                            Opted {customer.marketing === true ? "In" : "Out"}
                        </TableData>
                    </TableRow>

                    {customer.marketing === true && (
                        <TableRow>
                            <TableData className="heading">
                                Opt In Date
                            </TableData>
                            <TableData>
                                {parseDate(customer.subscribed_at)}
                            </TableData>
                        </TableRow>
                    )}

                    {customer.marketing !== true && (
                        <TableRow>
                            <TableData className="heading">
                                Opt Out Date
                            </TableData>
                            <TableData>
                                {parseDate(customer.unsubscribed_at)}
                            </TableData>
                        </TableRow>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default ViewRegistration;
