import React from "react";
import styled, { keyframes } from "styled-components";

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
`;

export const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;
