import React from "react";

import { SubmitButton, Error } from "../../../components/FormComponents";
import { DetailsContainer, DetailsLabel } from "../RenewalForm";
import { UnstyledButton } from "../../../components/registration/registrationComponents";

export const DetailsConfirmationBox = ({
    customer,
    validationErrors,
    setStep,
    setValidationErrors,
    setValidationMessage
}) => {
    return (
        <DetailsContainer style={{ marginBottom: "2em" }}>
            <table>
                <tbody>
                    <tr>
                        <DetailsLabel>Title:</DetailsLabel>
                        <td>{customer.title}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>First Name:</DetailsLabel>
                        <td>{customer.first_name}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>Last Name:</DetailsLabel>
                        <td>{customer.last_name}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>Address 1:</DetailsLabel>
                        <td>{customer.address_1}</td>
                    </tr>

                    <tr>
                        <DetailsLabel>Address 2:</DetailsLabel>
                        <td>{customer.address_2}</td>
                    </tr>

                    <tr>
                        <DetailsLabel>Town:</DetailsLabel>
                        <td>{customer.town}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>County:</DetailsLabel>
                        <td>{customer.county}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>Postcode:</DetailsLabel>
                        <td>{customer.postcode}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>Telephone:</DetailsLabel>
                        <td>{customer.telephone}</td>
                    </tr>
                    <tr>
                        <DetailsLabel>Email:</DetailsLabel>
                        <td>{customer.email}</td>
                    </tr>
                </tbody>
            </table>

            <br />

            {validationErrors && Object.values(validationErrors).map(error =>
                <>
                    <Error>{error}</Error>
                    <SubmitButton
                        onClick={() => {
                            setStep(2.5);
                            setValidationErrors([]);
                            setValidationMessage("");
                        }}
                        type="button"
                    >
                        Edit details
                    </SubmitButton>
                </>
            )}
        </DetailsContainer>
    );
};
const DetailsConfirmation = ({ customer, setStep }) => {
    return (
        <>
            <h3>Are these details still correct?</h3>

            <DetailsConfirmationBox customer={customer} />

            <SubmitButton
                onClick={() => setStep(2.5)}
                style={{ marginRight: "1em" }}
                color="grey"
                type="button"
            >
                No, I need to update my details
            </SubmitButton>
            <SubmitButton
                onClick={() => setStep(3)}
                hasArrow
                color="red"
                type="button"
            >
                Yes, these details are correct
            </SubmitButton>

            <div onClick={() => setStep(1)} style={{ marginTop: "1em" }}>
                <UnstyledButton type="button">{"< Go Back"}</UnstyledButton>
            </div>
        </>
    );
};

export default DetailsConfirmation;
