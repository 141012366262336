import React from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalErrorMessage = ({ children }) => {
    return (
        <p style={{ margin: "30px 0", color: "red" }}>
            <span
                style={{
                    display: "inline-block",
                    marginRight: "5px",
                }}
            >
                <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            {children}
        </p>
    );
};

export default ModalErrorMessage;
