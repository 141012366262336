import React from "react";
import styled from "styled-components";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledTable = styled.table`
    margin: 1rem 0;
    width: 100%;
    border-spacing: 0;
    
    th {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        background: #002a5b;
        color: white;
        text-align: left;
        padding: 10px;
        vertical-align: bottom;
    }
    
    td {
        text-align: left;
        padding: 10px;
    }
    
    tr {
        &:nth-child(even) {
            background-color: #efefef; 
        }
        
        ${props => props.selectable && `
            cursor: pointer;
            
            &:hover {
                box-shadow: inset 0 0 0px 2px black;
            }
        `}
    }
`;

export const StyledTableRow = styled.tr`
    ${props => props.selected && `
        background: #c1ddff !important;
    `}
`

const SortControlContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    cursor: pointer;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${props => props.isactive === "true" ? 'white' : '#cacaca'};
`;

const SortControl = ({ direction, onClick }) => {
    return (
        <SortControlContainer onClick={onClick}>
            <StyledFontAwesomeIcon isactive={(direction === 'asc').toString()} icon={faChevronUp} />
            <StyledFontAwesomeIcon isactive={(direction === 'desc').toString()} icon={faChevronDown} />
        </SortControlContainer>
    );
}

const TableHeaderTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
`;

const TableHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTh = styled.th`
    vertical-align: baseline !important;
`;

export const StyledTableHeader = ({
    children,
    isSortable = false,
    sortDirection,
    hasSearch = false,
    onSearchChange,
    onSortChange,
    searchValue,
    searchOptions,
    name
}) => {
    return (
        <StyledTh>
            <TableHeaderContainer>
                <TableHeaderTitle>
                    {children}
                    {isSortable &&
                        <SortControl
                            onClick={() => {
                                onSortChange(sortDirection === "asc" ? "desc" : "asc", name);
                            }}
                            direction={sortDirection}
                        />
                    }
                </TableHeaderTitle>
                {(hasSearch && !searchOptions) &&
                    <input
                        name="search"
                        onChange={value => onSearchChange(value.target.value, name)}
                        value={searchValue}
                    />
                }
                {(hasSearch && searchOptions) &&
                    <select
                        name="optionSearch"
                        onChange={value => onSearchChange(value.target.value, name)}
                        value={searchValue}
                    >
                        <option value="">Select a value</option>
                        {searchOptions.map(({ label, value }) => (
                            <option value={value}>{label}</option>
                        ))}
                    </select>
                }
            </TableHeaderContainer>
        </StyledTh>
    )
};
