import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { Modal, Tooltip, useAddToast } from "@cortexglobal/rla-components";
import { ButtonDropdown } from "@cortexglobal/lens-components";
import { LittleLoader } from "../../components/LayoutComponents";
import { TableData, TableRow } from "../../components/TableComponents";
import EditRegistration from "./forms/EditRegistration";
import ActivityLog from "./ActivityLog";
import ViewRegistration from "./forms/ViewRegistration";
import StyledCheckbox from "@cortexglobal/rla-components/lib/components/form/styledElements/styledCheckbox";

import BPModal from "./forms/BPModal";
import RenewalModal from "./forms/RenewalModal";
import ApprovalModal from "./forms/ApprovalModal";
import DeclineModal from "./forms/DeclineModal";
import TerminationModal from "./forms/TerminationModal";

const RegistrationRow = ({
    userCentre,
    registration,
    refreshRegistrations,
    refreshRegistration,
    activities,
    setActivities,
    isSelected,
    handleSelectRegistration,
}) => {
    const addToast = useAddToast();

    const [updating, setUpdating] = useState(false);
    const [editing, setEditing] = useState(false);
    const [viewing, setViewing] = useState(false);
    const [viewingActivity, setViewingActivity] = useState(false);

    const [statusApproveModalShowing, setStatusApproveModalShowing] = useState(
        false
    );
    const [statusRenewalModalShowing, setStatusRenewalModalShowing] = useState(
        false
    );
    const [
        statusDeclinedModalShowing,
        setStatusDeclinedModalShowing,
    ] = useState(false);
    const [
        statusTerminationModalShowing,
        setStatusTerminationModalShowing,
    ] = useState(false);
    const [BPNumberModal, setBPNumberModal] = useState(false);

    const { customer, vehicle, centre, warnings } = registration;
    const warningsTotal = warnings.length;

    const setWelcomePackToDispached = () => {
        setUpdating(true);

        return axios
            .post(
                `api/v1/registrations/${registration.uuid}/welcome-pack-dispatched`
            )
            .then(({ data }) => {
                refreshRegistration(data.data);
                setUpdating(false);

                addToast({
                    type: "success",
                    content: "Registration welcome pack dispatched.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdating(false);

                addToast({
                    type: "alert",
                    content:
                        "There was an error processing welcome pack dispatch.",
                    showFor: 5000,
                });
            });
    };

    const handleEditSubmit = (values, { setErrors }) => {
        setUpdating(true);
        return axios
            .put(`api/v1/registrations/${registration.uuid}`, values)
            .then(({ data }) => {
                setEditing(false);

                refreshRegistrations().then(() => {
                    setUpdating(false);
                });

                addToast({
                    type: "success",
                    content: "Registration successfully updated.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setUpdating(false);

                addToast({
                    type: "alert",
                    content:
                        "There was an error processing registration update.",
                    showFor: 5000,
                });
            });
    };

    const generateActions = (registration) => {
        let actions = [
            {
                name: "View",
                onClick: () => setViewing(true),
            },
        ];

        if (
            registration.status === "awaiting-approval" ||
            registration.status === "approved"
        ) {
            actions.push({
                name: "Edit",
                onClick: () => setEditing(true),
                disabled: true,
            });
        }

        if (registration.customer !== undefined) {
            actions.push({
                name: "View Emails",
                onClick: () => {
                    window.location = `/centre-area/customer/${registration.customer.uuid}/emails`;
                },
            });
        }

        if (
            registration.registration_status === "active" &&
            !registration.welcome_pack_dispatched_at
        ) {
            actions.push({
                name: "Welcome Pack Dispatched",
                onClick: () => setWelcomePackToDispached(),
            });
        }

        if (registration.status === "awaiting-approval") {
            actions.push({
                name: "Approve",
                onClick: () => setStatusApproveModalShowing(true),
            });

            actions.push({
                name: "Decline",
                onClick: () => setStatusDeclinedModalShowing(true),
            });
        }

        if (
            registration.registration_status === "active-renewable" ||
            registration.registration_status === "expired-renewable"
        ) {
            actions.push({
                name: "Renew",
                onClick: () => setStatusRenewalModalShowing(true),
            });
        }

        if (registration.status === "approved") {
            actions.push({
                name: "Terminate",
                onClick: () => setStatusTerminationModalShowing(true),
            });
        }

        if (
            registration.status == "awaiting-approval" &&
            !registration.customer.bp_number
        ) {
            actions.push({
                name: "Add BP Number",
                onClick: () => setBPNumberModal(true),
            });
        }

        if (
            registration.status === "approved" ||
            registration.status === "terminated"
        ) {
            actions.push({
                name: "Benefit Claims",
                onClick: () => setViewingActivity(true),
            });
        }

        return actions;
    };

    return (
        <>
            <TableRow>
                <TableData>
                    <StyledCheckbox
                        checked={isSelected}
                        onChange={() => {
                            handleSelectRegistration(registration.uuid);
                        }}
                        style={{ marginTop: "20px" }}
                    />
                </TableData>
                <TableData>{registration.identifier}</TableData>
                <TableData>
                    {customer.title +
                        " " +
                        customer.last_name +
                        ", " +
                        customer.first_name}
                </TableData>

                {userCentre === null && <TableData>{centre.name}</TableData>}

                <TableData>
                    {customer.bp_number ? (
                        customer.bp_number
                    ) : registration.status === "awaiting-approval" ? (
                        <ButtonDropdown
                            name="bp_number"
                            onClick={() => setBPNumberModal(true)}
                            actions={[]}
                        >
                            Add BP Number
                        </ButtonDropdown>
                    ) : (
                        "N/A"
                    )}
                </TableData>
                <TableData>
                    {moment.utc(registration.created_at).format("DD/MM/YYYY")}
                </TableData>
                <TableData>
                    {registration.status === "awaiting-approval"
                        ? "N/A"
                        : registration.membership_start_at &&
                          moment
                              .utc(registration.membership_start_at)
                              .format("DD/MM/YYYY")}
                </TableData>
                <TableData>
                    <div style={{ display: "flex" }}>
                        <span
                            style={
                                moment()
                                    .add(2, "M")
                                    .isAfter(
                                        moment.utc(
                                            registration.membership_end_at
                                        )
                                    )
                                    ? {
                                          color: "red",
                                      }
                                    : {}
                            }
                        >
                            {registration.status === "awaiting-approval"
                                ? "N/A"
                                : registration.membership_end_at &&
                                  moment
                                      .utc(registration.membership_end_at)
                                      .format("DD/MM/YYYY")}
                        </span>

                        {moment()
                            .add(2, "M")
                            .isAfter(
                                moment.utc(registration.membership_end_at)
                            ) && (
                            <span
                                style={{
                                    bottom: "8px",
                                    position: "relative",
                                    marginLeft: "0.5em",
                                }}
                            >
                                <Tooltip text="Membership will end soon" />
                            </span>
                        )}
                    </div>
                </TableData>
                <TableData style={{ color: warningsTotal ? "red" : "green" }}>
                    {warningsTotal}
                </TableData>

                <TableData>
                    {registration.registration_status_wording}
                </TableData>

                <TableData className="center">
                    {updating ? (
                        <LittleLoader icon="circle-notch" spin />
                    ) : (
                        <>
                            <ButtonDropdown
                                actions={generateActions(registration)}
                                onClick={() => setViewing(true)}
                            >
                                View
                            </ButtonDropdown>

                            <span
                                style={{
                                    display: "inline-block",
                                    bottom: "8px",
                                    position: "relative",
                                    width: "25px",
                                    marginLeft: "5px",
                                    color: "red",
                                }}
                            >
                                {!!warningsTotal && (
                                    <Tooltip text="Actions required" />
                                )}
                            </span>
                        </>
                    )}
                </TableData>
            </TableRow>

            {editing && (
                <Modal
                    visible={editing}
                    onClose={() => setEditing(false)}
                    maxWidth="50%"
                >
                    <div style={{ padding: "2rem" }}>
                        <EditRegistration
                            registration={registration}
                            handleEditSubmit={handleEditSubmit}
                            setEditing={setEditing}
                        />
                    </div>
                </Modal>
            )}

            {viewing && (
                <Modal
                    visible={viewing}
                    onClose={() => setViewing(false)}
                    maxWidth="75%"
                >
                    <ViewRegistration registration={registration} />
                </Modal>
            )}

            {viewingActivity && (
                <Modal
                    visible={viewingActivity}
                    onClose={() => setViewingActivity(false)}
                    maxWidth="50%"
                >
                    <ActivityLog
                        registration={registration}
                        refreshRegistrations={refreshRegistrations}
                        refreshRegistration={refreshRegistration}
                        activities={activities}
                        setActivities={setActivities}
                    />
                </Modal>
            )}

            {statusDeclinedModalShowing && (
                <DeclineModal
                    registration={registration}
                    refreshRegistration={refreshRegistration}
                    visible={statusDeclinedModalShowing}
                    setVisible={setStatusDeclinedModalShowing}
                    setUpdating={setUpdating}
                />
            )}

            {statusTerminationModalShowing && (
                <TerminationModal
                    registration={registration}
                    refreshRegistration={refreshRegistration}
                    visible={statusTerminationModalShowing}
                    setVisible={setStatusTerminationModalShowing}
                    setUpdating={setUpdating}
                />
            )}

            {statusApproveModalShowing && (
                <ApprovalModal
                    registration={registration}
                    refreshRegistration={refreshRegistration}
                    visible={statusApproveModalShowing}
                    setVisible={setStatusApproveModalShowing}
                    setUpdating={setUpdating}
                />
            )}

            {statusRenewalModalShowing && (
                <RenewalModal
                    registration={registration}
                    refreshRegistration={refreshRegistration}
                    visible={statusRenewalModalShowing}
                    setVisible={() => setStatusRenewalModalShowing()}
                    setUpdating={setUpdating}
                />
            )}

            {BPNumberModal && (
                <BPModal
                    registration={registration}
                    refreshRegistration={refreshRegistration}
                    visible={!!BPNumberModal}
                    setVisible={() => setBPNumberModal()}
                    setUpdating={setUpdating}
                />
            )}
        </>
    );
};

export default RegistrationRow;
