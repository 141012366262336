import React from "react";
import moment from "moment";
import { isEmpty, get } from "lodash";

import { TableList, Pagination, Loading } from "@cortexglobal/rla-components";

const ImportListing = (props) => {
    const {
        imports = [],
        pagination = {},
        reload = () => {},
        loadingState = "loaded",
    } = props;

    const renderBody = () => {
        {
            if (loadingState === "loading") {
                return (
                    <tr>
                        <td colSpan="100%">
                            <Loading />
                        </td>
                    </tr>
                );
            }

            return imports.map((importLog) => {
                const start = moment(importLog.start_time).format(
                    "DD/MM/YYYY HH:mm"
                );
                const end = importLog.end_time
                    ? moment(importLog.end_time).format("DD/MM/YYYY HH:mm")
                    : "-";
                return (
                    <tr key={importLog.uuid}>
                        <td>{importLog.identifier}</td>
                        <td>{get(importLog, "upload.original_filename")}</td>
                        <td>{get(importLog, "import.name", "-")}</td>
                        <td>{start}</td>
                        <td>{end}</td>
                        <td>{importLog.imported_count}</td>
                        <td>{importLog.updated_count}</td>
                        <td>{importLog.new_count}</td>
                        <td>{importLog.deleted_count}</td>
                        <td>{importLog.status}</td>
                        <td></td>
                    </tr>
                );
            });
        }
    };

    return (
        <>
            <TableList>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>File</th>
                        <th>Import</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Imported</th>
                        <th>Updated</th>
                        <th>New</th>
                        <th>Deleted</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{renderBody()}</tbody>
            </TableList>

            {imports.length === 0 && <p style={{ padding: "1rem" }}>No Imports</p>}

            {!isEmpty(pagination) && (
                <Pagination
                    pageRangeDisplayed={5}
                    currentPage={pagination.current_page - 1}
                    pageCount={pagination.last_page}
                    onPageChange={(e) => reload(e.selected + 1)}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
            )}
        </>
    );
};

export default ImportListing;
