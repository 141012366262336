import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    Modal,
    useAddToast, SelectField
} from "@cortexglobal/rla-components";
import {
    useHistory,
    useLocation
} from "react-router-dom";

import { StyledTableHeader, StyledTable } from "../table/StyledTable";
import queryString from "querystring";
import CubeLoader from "../CubeLoader";

import { ButtonDropdown } from "../index";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const Validation = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const {
        perPage,
        page
    } = queryString.parse(useLocation().search.substring(1));

    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(page === undefined ? 0 : page);

    const [data, setData] = useState([]);
    const [meta, setMeta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [validationLoading, setValidationLoading] = useState(false);

    const [currentValidationValue, setCurrentValidationValue] = useState();
    const [acceptValidationModalVisible, setAcceptValidationModalVisible] = useState(false);
    const [rejectValidationModalVisible, setRejectValidationModalVisible] = useState(false);

    const [manualOverride, setManualOverride] = useState();
    const [customValue, setCustomValue] = useState();

    const getValidation = (newData) => {
        axios
            .get(`/api/v1/validation/intervention`, { params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    backlog: false,
                    ...newData
                }})
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting validation data.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getValidation();
    }, []);

    const handleAccept = (validationValue) => {
        setCurrentValidationValue(validationValue);
        setAcceptValidationModalVisible(true);
    }

    const handleReject = (validationValue) => {
        setCurrentValidationValue(validationValue);
        setRejectValidationModalVisible(true);
    }

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            history.push({
                search: `?page=${selectedPageValue}&perPage=${value}`
            });
            getValidation({
                per_page: value,
                page: 1
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected);

        history.push({
            search: `?page=${selected}&perPage=${perPageValue}`
        });
        getValidation({
            page: selected + 1
        });
    };

    const handleValidation = (validation, value) => {
        console.log(validation, value)
        setValidationLoading(true);
        axios
            .put(`/api/v1/validation/intervention`, [
                {
                    class_type: validation.class_type,
                    field_changed: validation.field_changed,
                    field_value: value,
                    record_id: validation.production_record.id,
                    draft_id: validation.draft_id
                }
            ])
            .then(({ data }) => {
                setValidationLoading(false);
                getValidation();
            })
            .catch((e) => {
                setValidationLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error validating.",
                    showFor: 5000
                });
            });
    };

    return (
        <>
            <PageTitle title="Validation" />
            {loading ?
                <CubeLoader/>
                :
                <Column>
                    <Panel style={{paddingTop: '0'}}>
                        <StyledTable className="dashboard-table">
                            <thead>
                            <tr>
                                <StyledTableHeader>
                                    Distrigo Number
                                </StyledTableHeader>
                                <StyledTableHeader>
                                    Company Name
                                </StyledTableHeader>
                                <StyledTableHeader>
                                    Field
                                </StyledTableHeader>
                                <StyledTableHeader>
                                    Original Value
                                </StyledTableHeader>
                                <StyledTableHeader>
                                    New Value
                                </StyledTableHeader>
                                <StyledTableHeader hasSearch={false}/>
                            </tr>
                            </thead>
                            <tbody>
                            {data.data.map(validation => (
                                <tr key={validation.id}>
                                    <td>
                                        <Link to={`/companies/${validation.production_record.id}`}>
                                            {validation.production_record.trade_team_id}
                                        </Link>
                                    </td>

                                    <td>
                                        {validation.production_record.trade_team_customer}
                                    </td>

                                    <td>
                                        {validation.field_changed}
                                    </td>

                                    <td>
                                        {validation.old_value}
                                    </td>

                                    <td>
                                        {validation.new_value}
                                    </td>
                                    <td>
                                        <ButtonDropdown
                                            onClick={() => {
                                                handleAccept(validation);
                                            }}
                                            name="primary"
                                            actions={[
                                                {
                                                    name: "Reject Change",
                                                    onClick: () => {
                                                        handleReject(validation);
                                                    }
                                                },
                                                {
                                                    name: "Manual Override",
                                                    onClick: () => {
                                                        setManualOverride(validation);
                                                        setCustomValue(validation.new_value);
                                                    }
                                                }
                                            ]}>
                                            Accept Change
                                        </ButtonDropdown>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </StyledTable>

                        {(!loading && !data.data.length) &&
                        <p style={{textAlign: 'center', margin: '3em 0'}}>No results</p>
                        }

                        {(!loading && data.meta) &&
                        <PaginationContainer>
                            <PerPageContainer>
                                Per Page:
                                <SelectField
                                    name="perPage"
                                    style={{ margin: '0 0 0 1em' }}
                                    value={perPageValue}
                                    options={[
                                        {value: 10, text: '10'},
                                        {value: 20, text: '20'},
                                        {value: 30, text: '30'},
                                        {value: 40, text: '40'},
                                        {value: 50, text: '50'},
                                    ]}
                                    onChange={handlePerPage}
                                />
                            </PerPageContainer>
                            <Pagination
                                currentPage={selectedPageValue - 1}
                                total={data.meta.total}
                                pageCount={data.meta.last_page}
                                onPageChange={handlePageChange}
                                previousLabel="&laquo;"
                                nextLabel="&raquo;"
                            />
                            <TotalContainer>Total: {data.meta.total}</TotalContainer>
                        </PaginationContainer>
                        }

                    </Panel>
                </Column>
            }

            {acceptValidationModalVisible &&
                <Modal visible={acceptValidationModalVisible} onClose={() => setAcceptValidationModalVisible(false) }>
                    <h3>Confirm accept change for: {currentValidationValue.field_changed}</h3>

                    <p><strong>Original Value: </strong> {currentValidationValue.old_value}</p>
                    <p><strong>New Value: </strong> {currentValidationValue.new_value}</p>
                    <div style={{display: "flex" }}>
                        <Button style={{ marginRight: '1em' }} onClick={() => setAcceptValidationModalVisible(false)}>Cancel</Button>
                        <Button
                            style={{ marginRight: "2rem" }}
                            onClick={() => {
                                handleValidation(currentValidationValue, currentValidationValue.new_value);
                            }}
                        >
                            Accept
                        </Button>
                        {validationLoading &&
                            <CubeLoader
                                size={20}
                                margin={0}
                            />
                        }
                    </div>
                </Modal>
            }

            {rejectValidationModalVisible &&
                <Modal visible={rejectValidationModalVisible} onClose={() => setRejectValidationModalVisible(false) }>
                    <h3>Confirm reject change for: {currentValidationValue.field_changed}</h3>
                    <p><strong>Original Value: </strong> {currentValidationValue.old_value}</p>
                    <p><strong>New Value: </strong> {currentValidationValue.new_value}</p>
                    <div style={{display: "flex" }}>
                        <Button style={{ marginRight: '1em' }} onClick={() => setRejectValidationModalVisible(false)}>Cancel</Button>
                        <Button
                            style={{ marginRight: "2rem" }}
                            onClick={() => {
                                handleValidation(currentValidationValue, currentValidationValue.old_value);
                            }}
                        >
                            Reject
                        </Button>
                        {validationLoading && <CubeLoader size={20} margin={0} />}
                    </div>
                </Modal>
            }

            {manualOverride &&
            <Modal
                visible={!!manualOverride}
                onClose={() => {
                    setManualOverride();
                    setCustomValue();
                }}
            >
                <div style={{ padding: "2rem" }}>
                    <h2>Manual Override</h2>
                    <p>Field: <b>{manualOverride.field_changed}</b></p>
                    <p>Old Value: <b>{manualOverride.old_value}</b></p>
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        New Value:
                        <InputField
                            style={{ marginLeft: "0.5rem", marginBottom: "0" }}
                            value={customValue}
                            onChange={({ value }) => setCustomValue(value)}
                        />
                    </p>
                    <div style={{display: "flex" }}>
                        <Button
                            style={{ marginRight: "2rem" }}
                            onClick={() => handleValidation(manualOverride, customValue)}
                        >
                            Submit
                        </Button>
                        {validationLoading && <CubeLoader size={20} margin={0} />}
                    </div>
                </div>
            </Modal>
            }

        </>
    );
};

export default Validation;
