import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    Modal,
    useAddToast,
    SelectField,
    FormLabel,
    FormikInput,
    FormikCheckbox,
} from "@cortexglobal/rla-components";
import * as Yup from "yup";
import {
    useHistory,
    useLocation
} from "react-router-dom";
import { Formik, Form } from "formik";

import { StyledTableHeader, StyledTable } from "../table/StyledTable";
import queryString from "querystring";
import CubeLoader from "../CubeLoader";

import { ButtonDropdown } from "../index";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const EditUserSchema = Yup.object().shape({
    uuid: Yup.string().required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
});

const UserSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    is_admin: Yup.boolean(),
});

const Users = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const {
        perPage,
        page
    } = queryString.parse(useLocation().search.substring(1));

    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(page === undefined ? 0 : page);

    const [data, setData] = useState([]);
    const [meta, setMeta] = useState([]);
    const [loading, setLoading] = useState(true);
    const [usersLoading, setUsersLoading] = useState(false);

    const [createUserModalShowing, setCreateUserModalShowing] = useState(false);

    const [currentValidationValue, setCurrentValidationValue] = useState();
    const [acceptValidationModalVisible, setAcceptValidationModalVisible] = useState(false);
    const [rejectValidationModalVisible, setRejectValidationModalVisible] = useState(false);

    const [editUser, setEditUser] = useState();
    // const [customValue, setCustomValue] = useState();

    const getUsers = (newData) => {
        axios
            .get(`/api/v1/users`, { params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    ...newData
                }})
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting users data.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getUsers();
    }, []);

    // const handleAccept = (validationValue) => {
    //     setCurrentValidationValue(validationValue);
    //     setAcceptValidationModalVisible(true);
    // }
    //
    // const handleReject = (validationValue) => {
    //     setCurrentValidationValue(validationValue);
    //     setRejectValidationModalVisible(true);
    // }

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            history.push({
                search: `?page=${selectedPageValue}&perPage=${value}`
            });
            getUsers({
                per_page: value,
                page: 1
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected);

        history.push({
            search: `?page=${selected}&perPage=${perPageValue}`
        });
        getUsers({
            page: selected + 1
        });
    };

    const handleDelete = (uuid) => {
        setLoading(true);
        axios
            .delete(`/api/v1/users/${uuid}`)
            .then(({ data }) => {
                setLoading(false);
                getUsers();
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error deleting user.",
                    showFor: 5000
                });
            });
    }

    const handlePasswordReset = (uuid) => {
        setLoading(true);
        axios
            .post(`/api/v1/auth/password/${uuid}`)
            .then(({ data }) => {
                setLoading(false);
                getUsers();
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error requesting password reset.",
                    showFor: 5000
                });
            });
    }

    const handleEditUserSubmit = values => {
        setUsersLoading(true);
        axios
            .put(`/api/v1/users/${values.uuid}`, values)
            .then(({ data }) => {
                setUsersLoading(false);
                setEditUser(false);
                getUsers();
            })
            .catch((e) => {
                setUsersLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error validating.",
                    showFor: 5000
                });
            });
    };

    const handleCreateUserSubmit = values => {
        setUsersLoading(true);
        axios
            .post(`/api/v1/users`, values)
            .then(({ data }) => {
                setCreateUserModalShowing(false);
                setUsersLoading(false);
                setLoading(true);
                getUsers();
            })
            .catch((e) => {
                setUsersLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error validating.",
                    showFor: 5000
                });
            });
    }

    return (
        <>
            <PageTitle title="Users" />
            {loading ?
                <CubeLoader/>
                :
                <Row stlye={{ maxWidth:'100%' }}>
                    <Button
                        onClick={() => setCreateUserModalShowing(true)}
                        style={{ float: 'right', marginRight: '1rem' }}
                    >
                        Create User
                    </Button>

                    <Column>
                        <Panel style={{paddingTop: '0'}}>
                            <StyledTable className="dashboard-table">
                                <thead>
                                <tr>
                                    <StyledTableHeader>
                                        Uuid
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        Email
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        Name
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        Status
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        Action
                                    </StyledTableHeader>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.map(users => (
                                    <tr key={users.id}>
                                        <td>
                                            {users.uuid}
                                        </td>

                                        <td>
                                            {users.email}
                                        </td>

                                        <td>
                                            {users.name}
                                        </td>

                                        <td>
                                            {users.status_wording}
                                        </td>
                                        <td>
                                            <ButtonDropdown
                                                onClick={() => {
                                                    setEditUser(users);
                                                }}
                                                name="primary"
                                                actions={[
                                                    {
                                                        name: "Delete User",
                                                        onClick: () => {
                                                            handleDelete(users.uuid);
                                                        }
                                                    },
                                                    {
                                                        name: "Request Password Reset",
                                                        onClick: () => {
                                                            handlePasswordReset(users.uuid);
                                                        }
                                                    }
                                                ]}>
                                                Edit User
                                            </ButtonDropdown>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </StyledTable>

                            {(!loading && !data.data.length) &&
                            <p style={{textAlign: 'center', margin: '3em 0'}}>No results</p>
                            }

                            {(!loading && data.meta) &&
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{ margin: '0 0 0 1em' }}
                                        value={perPageValue}
                                        options={[
                                            {value: 10, text: '10'},
                                            {value: 20, text: '20'},
                                            {value: 30, text: '30'},
                                            {value: 40, text: '40'},
                                            {value: 50, text: '50'},
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={selectedPageValue - 1}
                                    total={data.meta.total}
                                    pageCount={data.meta.last_page}
                                    onPageChange={handlePageChange}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                                <TotalContainer>Total: {data.meta.total}</TotalContainer>
                            </PaginationContainer>
                            }

                        </Panel>
                    </Column>
                </Row>
            }

            {editUser &&
            <Modal
                visible={!!editUser}
                onClose={() => {
                    setEditUser();
                }}
            >
                <div style={{ padding: "2rem" }}>
                    <h2>Edit User</h2>
                    <Formik
                        initialValues={{
                            uuid: editUser.uuid,
                            first_name: editUser.first_name,
                            last_name: editUser.last_name,
                            email: editUser.email,
                        }}
                        onSubmit={handleEditUserSubmit}
                        validationSchema={EditUserSchema}
                    >

                        {({ values }) => {
                            return (
                                <Form>
                                    <FormikInput
                                        value={values.uuid}
                                        width="100%"
                                        labelWidth={100}
                                        name="uuid"
                                        label="UUID"
                                    />
                                    <FormikInput
                                        value={values.first_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="first_name"
                                        label="First Name"
                                    />
                                    <FormikInput
                                        value={values.last_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="last_name"
                                        label="Last Name"
                                    />
                                    <FormikInput
                                        value={values.email}
                                        width="100%"
                                        labelWidth={100}
                                        name="email"
                                        label="Email"
                                    />
                                    <div style={{display: "flex", justifyContent: 'flex-end' }}>
                                        <Button
                                            style={{ marginRight: "2rem" }}
                                        >
                                            Submit
                                        </Button>
                                        {usersLoading && <CubeLoader size={20} margin={0} />}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            }

            {createUserModalShowing &&
            <Modal
                visible={!!createUserModalShowing}
                onClose={() => {
                    setCreateUserModalShowing(false);
                }}
            >
                <div style={{ padding: "2rem" }}>
                    <h2>Create User</h2>
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            email: "",
                        }}
                        onSubmit={handleCreateUserSubmit}
                        validationSchema={UserSchema}
                    >

                        {({ values }) => {
                            return (
                                <Form>
                                    <FormikInput
                                        value={values.first_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="first_name"
                                        label="First Name"
                                    />
                                    <FormikInput
                                        value={values.last_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="last_name"
                                        label="Last Name"
                                    />
                                    <FormikInput
                                        value={values.email}
                                        width="100%"
                                        labelWidth={100}
                                        name="email"
                                        label="Email"
                                    />
                                    <FormikInput
                                        value={values.password}
                                        width="100%"
                                        labelWidth={100}
                                        name="password"
                                        type='password'
                                        label="Password"
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="is_admin"
                                        label="Admin"
                                        value={values.is_admin}
                                    />
                                    <div style={{display: "flex", justifyContent: 'flex-end' }}>
                                        <Button
                                            style={{ marginRight: "2rem" }}
                                        >
                                            Submit
                                        </Button>
                                        {usersLoading && <CubeLoader size={20} margin={0} />}
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal>
            }
        </>
    );
};

export default Users;
