import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import {
    LimitedWidthRow,
    FlexRow,
    LittleLoader,
} from "../../../components/LayoutComponents";

import { DatePicker, useAddToast } from "@cortexglobal/rla-components";

import { SubmitButton } from "../../../components/FormComponents";

import UserReports from "./UserReports";
import Select from "@cortexglobal/rla-components/lib/components/form/standardReturnElements/select";
import LoadingPage from "../../../components/LoadingPage";
import { isEmpty } from "lodash";

const Reports = (props) => {
    const initialStartDate = new moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD");
    const initialEndDate = new moment().format("YYYY-MM-DD");

    const [reports, setReports] = useState({
        data: [],
        loading: true,
    });

    const addToast = useAddToast();

    // const [fields, setFields] = useState([]);
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const [report, setReport] = useState({});
    const [reportLoading, setReportLoading] = useState(false);

    const [reloadList, setReloadList] = useState();

    useEffect(() => {
        getSettings();
    }, []);

    const setCurrentReport = (alias) => {
        setReport(reports.data.filter((r) => r.alias === alias)[0]);
    };

    const getSettings = () => {
        axios
            .get(`/api/v1/reporting/settings`)
            .then(({ data }) => {
                data.data.sort((a, b) => {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                });

                setReports({ loading: false, data: data.data });
            })
            .catch((e) => {
                setReports({ loading: false, ...reports });
                addToast({
                    type: "alert",
                    content:
                        "There was an error loading the reporting settings.",
                    showFor: 5000,
                });
            });
    };

    const handleReporting = () => {
        if (!isEmpty(report) && report.alias) {
            setReportLoading(true);

            axios
                .post(`/api/v1/reporting`, {
                    date_from: startDate,
                    date_to: endDate,
                    type: report.alias,
                })
                .then(({ data }) => {
                    setReloadList(Math.random());
                    setReport("");
                    setStartDate(initialStartDate);
                    setEndDate(initialEndDate);
                    setReportLoading(false);
                })
                .catch((e) => {
                    setReportLoading(false);
                    addToast({
                        type: "alert",
                        content: "There was an error processing your report.",
                        showFor: 5000,
                    });
                });
        }
    };

    const renderBody = () => {
        if (reports.loading === true) {
            return <LoadingPage title="Loading reports settings" />;
        }

        return (
            <>
                <p>
                    Select your report from the options below. The report will
                    be generated on the server (note: depending on the report
                    you've selected this may take a while - use the 'check'
                    button to check on progress). When the report is ready
                    you'll be able to download the report in your browser.
                </p>

                <Select
                    label="Report"
                    id="report"
                    name="report"
                    value={report.alias}
                    options={reports.data.map((report) => {
                        return {
                            text: report.name,
                            value: report.alias,
                        };
                    })}
                    onChange={({ value }) => setCurrentReport(value)}
                />

                {report && !isEmpty(report.fields) && (
                    <>
                        {report.fields.includes("dateFrom") && (
                            <DatePicker
                                label="From"
                                id="date-from-datepicker"
                                name="date-from"
                                value={startDate}
                                onChange={({ value }) => setStartDate(value)}
                                dateFormat="DD/MM/YYYY"
                            />
                        )}

                        {report.fields.includes("dateTo") && (
                            <DatePicker
                                label="To"
                                id="date-to-datepicker"
                                name="date-to"
                                value={endDate}
                                onChange={({ value }) => setEndDate(value)}
                                dateFormat="DD/MM/YYYY"
                            />
                        )}
                    </>
                )}

                {report && !isEmpty(report.description) && (
                    <p>{report.description}</p>
                )}

                {report && !isEmpty(report.tip) && (
                    <p>
                        <strong>Note:</strong> {report.tip}
                    </p>
                )}

                <div style={{ textAlign: "right" }}>
                    <SubmitButton
                        style={{
                            marginRight: "1rem",
                        }}
                        onClick={() => {
                            handleReporting();
                        }}
                        type="button"
                        disabled={report === "" || reports.loading}
                    >
                        Generate Report
                    </SubmitButton>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <UserReports reload={reloadList} />
                </div>
            </>
        );
    };

    return (
        <LimitedWidthRow>
            <FlexRow style={{ marginBottom: "2rem" }}>
                <h3 style={{ flexGrow: "1" }}>Reporting</h3>
                {reportLoading === true && (
                    <LittleLoader icon="circle-notch" spin />
                )}
            </FlexRow>

            {renderBody()}
        </LimitedWidthRow>
    );
};

export default Reports;
