import React from "react";
import styled from "styled-components";
import { Route, Switch, withRouter } from "react-router-dom";

import { SiteWrapper, MainContentWrapper } from "@cortexglobal/rla-components";

import PrivateHeader from "./PrivateHeader";
import Footer from "./Footer";

import PageNotFound from "../PageNotFound";

import Dashboard from "../../modules/admin/Dashboard";
import RegisterFormDealer from "../../modules/admin/RegisterFormDealer";
import ProfileForm from "../../modules/admin/ProfileForm";
import Reports from "../../modules/admin/reports/Reports";
import EmailCampaigns from "../../modules/admin/emails/EmailCampaigns";

import Users from "../../modules/admin/users/Users";

import Centres from "../../modules/admin/centres/Centres";
import MyCentre from "../../modules/admin/centres/MyCentre";

const StyledSiteWrapper = styled(SiteWrapper)`
    flex-direction: row;
`;

const SiteMain = styled.div`
    flex-grow: 1;
    flex-direction: column;
    display: flex;
`;

const PrivateApp = (props) => {
    return (
        <StyledSiteWrapper>
            <SiteMain>
                <PrivateHeader />
                <MainContentWrapper style={{ position: "relative" }}>
                    <Switch>
                        <Route
                            exact
                            path="/centre-area"
                            component={Dashboard}
                        />
                        <Route
                            exact
                            path="/centre-area/emails"
                            component={EmailCampaigns}
                        />
                        <Route
                            exact
                            path="/centre-area/customer/:uuid/emails"
                            component={EmailCampaigns}
                        />
                        <Route
                            path="/centre-area/register"
                            component={RegisterFormDealer}
                        />
                        <Route
                            path="/centre-area/centre"
                            component={MyCentre}
                        />
                        <Route
                            path="/centre-area/profile"
                            component={ProfileForm}
                        />
                        <Route
                            path="/centre-area/reports"
                            component={Reports}
                        />
                        <Route path="/centre-area/users" component={Users} />
                        <Route
                            path="/centre-area/centres"
                            component={Centres}
                        />
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </MainContentWrapper>
                <Footer />
            </SiteMain>
        </StyledSiteWrapper>
    );
};

export default withRouter(PrivateApp);
