import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import {
    StyledField,
    StyledLabel,
    SmallLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
} from "../../../components/FormComponents";

const Schema = Yup.object().shape({
    title: Yup.string().required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    address_1: Yup.string().required("Required"),
    // address_2: Yup.string(),
    town: Yup.string().required("Required"),
    county: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
    telephone: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
});

const EditRegistration = ({ registration, handleEditSubmit, setEditing }) => {
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const [settings, setSettings] = useState({
        state: "loading",
        data: {},
        distancesLoaded: false,
    });

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = () => {
        axios
            .get(`/api/v1/register/settings`)
            .then(({ data }) => {
                data.state = "loaded";
                setSettings({ ...settings, ...data });
            })
            .catch(({ response: { data } }) => {
                data.state = "error";
                setSettings({ ...settings, ...data });
            });
    };

    const handleSubmit = (values, options) => {

        setSubmitting(true);
        return handleEditSubmit(values, options).catch((e) => {
            setSubmitting(false);
        });
    };
    const { customer, vehicle } = registration;

    return (
        <Formik
            initialValues={{
                bp_number: customer.bp_number,
                title: customer.title,
                first_name: customer.first_name,
                last_name: customer.last_name,
                address_1: customer.address_1,
                address_2: customer.address_2,
                town: customer.town,
                county: customer.county,
                postcode: customer.postcode,
                telephone: customer.telephone,
                email: customer.email,

                model: vehicle.model,
                registration: vehicle.registration,
                vin: vehicle.vin,
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched, setFieldValue, values }) => {
                const titles =
                    settings.state === "loading"
                        ? ["Loading..."]
                        : settings.data.titles;

                return (
                    <Form>
                        <h3>Vehicle</h3>

                        <StyledLabel htmlFor="model">
                            Model <Required />
                        </StyledLabel>
                        <StyledField id="model" name="model" />
                        {errors.model && touched.model ? (
                            <Error>Model is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="registration">
                            Registration <Required />
                        </StyledLabel>
                        <StyledField id="registration" name="registration" />
                        {errors.registration && touched.registration ? (
                            <Error>Registration is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="vin">
                            Vin <Required />
                        </StyledLabel>
                        <StyledField id="vin" name="vin" />
                        {errors.vin && touched.vin ? (
                            <Error>vin is required</Error>
                        ) : null}

                        <br />
                        <br />
                        <br />

                        <h3>Customer</h3>

                        <StyledLabel htmlFor="bp_number">
                            BP Number <Required />
                        </StyledLabel>
                        <StyledField id="bp_number" name="bp_number" />
                        {errors.bp_number && touched.bp_number ? (
                            <Error>BP Number is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="title">
                            Title <Required />
                        </StyledLabel>
                        <StyledField
                            style={{ width: "auto" }}
                            as="select"
                            name="title"
                            value={values.title}
                            onChange={(value) =>
                                setFieldValue("title", value.target.value)
                            }
                        >
                            <option value="">Select</option>

                            {titles.map((title) => {
                                return (
                                    <option value={title} key={title}>
                                        {title}
                                    </option>
                                );
                            })}
                        </StyledField>
                        {errors.title && touched.title ? (
                            <Error>Title is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="first_name">
                            First name <Required />
                        </StyledLabel>
                        <StyledField id="first_name" name="first_name" />
                        {errors.first_name && touched.first_name ? (
                            <Error>First name is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="last_name">
                            Last Name <Required />
                        </StyledLabel>
                        <StyledField id="last_name" name="last_name" />
                        {errors.last_name && touched.last_name ? (
                            <Error>Last name is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="address_1">
                            Address line 1 <Required />
                        </StyledLabel>
                        <StyledField id="address_1" name="address_1" />
                        {errors.address_1 && touched.address_1 ? (
                            <Error>Address line 1 is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="address_2">
                            Address line 2{" "}
                        </StyledLabel>
                        <StyledField id="address_2" name="address_2" />

                        <StyledLabel htmlFor="town">
                            Town <Required />
                        </StyledLabel>
                        <StyledField id="town" name="town" />
                        {errors.town && touched.town ? (
                            <Error>Town is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="county">
                            County <Required />
                        </StyledLabel>
                        <StyledField id="county" name="county" />
                        {errors.county && touched.county ? (
                            <Error>County is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="postcode">
                            Postcode <Required />
                        </StyledLabel>
                        <StyledField
                            style={{
                                maxWidth: "300px",
                                marginRight: "1rem",
                                marginBottom: "0",
                            }}
                            id="postcode"
                            name="postcode"
                        />
                        {errors.postcode && touched.postcode ? (
                            <Error>Postcode is required</Error>
                        ) : null}
                        <StyledLabel htmlFor="telephone">
                            Telephone <Required />
                        </StyledLabel>
                        <StyledField id="telephone" name="telephone" />
                        {errors.telephone && touched.telephone ? (
                            <Error>Telephone is required</Error>
                        ) : null}

                        <StyledLabel htmlFor="email">
                            Email <Required />
                        </StyledLabel>
                        <StyledField id="email" name="email" />
                        {errors.email && touched.email ? (
                            <Error>Email is required and must be valid</Error>
                        ) : null}
                        {submitError && <Error>{submitError}</Error>}
                        <SubmitSection>
                            <SubmitButton
                                style={{ marginRight: "1em" }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setEditing(false);
                                }}
                            >
                                Cancel
                            </SubmitButton>
                            <SubmitButton
                                hasArrow
                                color="red"
                                type="submit"
                            >
                                Submit
                            </SubmitButton>
                            {submitting && <Loader />}
                        </SubmitSection>
                    </Form>
                );
            }}
        </Formik>
    );
};
export default EditRegistration;
