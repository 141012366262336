import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { useAddToast } from "@cortexglobal/rla-components";

import {
    StyledField,
    StyledLabel,
    Error,
    SubmitSection,
    SubmitButton,
    Loader,
    Required,
    StyledCheckboxField,
} from "../../../components/FormComponents";

import LoadingPage from "../../../components/LoadingPage";
import ErrorPage from "../../../components/ErrorPage";

const Schema = Yup.object().shape({
    uuid: Yup.string().nullable(true),
    name: Yup.string().required("Required"),
    group: Yup.string().nullable(true),
    external_ref: Yup.string().required("Required"),
    participating: Yup.boolean(),
    address_1: Yup.string().required("Required"),
    address_2: Yup.string().nullable(true),
    town: Yup.string().required("Required"),
    county: Yup.string().nullable(true),
    postcode: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    telephone: Yup.string().required("Required"),
    website: Yup.string().nullable(true),
    facebook: Yup.string().nullable(true),
    twitter: Yup.string().nullable(true),
    youtube: Yup.string().nullable(true),
    instagram: Yup.string().nullable(true),
    facebook_tag: Yup.string().nullable(true),
    twitter_tag: Yup.string().nullable(true),
    youtube_tag: Yup.string().nullable(true),
    instagram_tag: Yup.string().nullable(true),
});

const CentreForm = ({ centre, refreshCentre, successCallback }) => {
    const [submitting, setSubmitting] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);

    const addToast = useAddToast();

    const handleOnSubmit = (values, { setErrors }) => {
        setSubmitting(true);

        const submit = !centre.uuid
            ? axios.post(`/api/v1/centre`, values)
            : axios.put(`/api/v1/centre/${centre.uuid}`, values);

        submit
            .then(({ data }) => {
                setLoaded(true);
                setError(false);
                refreshCentre(data.data);

                setSubmitting(false);
                setSubmitSuccessful(true);

                addToast({
                    type: "success",
                    content: "Centre updated successfully.",
                    showFor: 5000,
                });

                successCallback();
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });

                setSubmitting(false);

                addToast({
                    type: "alert",
                    content: "There was an error processing centre details.",
                    showFor: 5000,
                });
            });
    };

    if (!loaded) {
        return <LoadingPage title="Loading centre details" />;
    }

    if (error) {
        return (
            <ErrorPage>
                <p>
                    An error occurred whilst loading the centre details, please
                    try again later.
                </p>
            </ErrorPage>
        );
    }

    return (
        <Formik
            initialValues={{
                uuid: centre.uuid,
                name: centre.name,
                group: centre.group,
                external_ref: centre.external_ref,
                participating: centre.participating,
                address_1: centre.address_1,
                address_2: centre.address_2,
                town: centre.town,
                county: centre.county,
                postcode: centre.postcode,
                email: centre.email,
                telephone: centre.telephone,
                website: centre.website,
                facebook: centre.facebook,
                twitter: centre.twitter,
                youtube: centre.youtube,
                instagram: centre.instagram,
                facebook_tag: centre.facebook_tag,
                twitter_tag: centre.twitter_tag,
                youtube_tag: centre.youtube_tag,
                instagram_tag: centre.instagram_tag,
            }}
            validationSchema={Schema}
            onSubmit={handleOnSubmit}
        >
            {({ errors, touched }) => {
                return (
                    <Form>
                        <h3>Centre Details</h3>

                        <StyledLabel htmlFor="name">
                            Centre Name <Required />
                        </StyledLabel>
                        <StyledField id="name" name="name" />
                        {errors.name && touched.name ? (
                            <Error>{errors.name || "Name is required"}</Error>
                        ) : null}

                        <StyledLabel htmlFor="external_ref">
                            Centre Reference <Required />
                        </StyledLabel>
                        <StyledField id="external_ref" name="external_ref" />
                        {errors.external_ref && touched.external_ref ? (
                            <Error>
                                {errors.external_ref ||
                                    "Centre Identifier is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="group">Centre Group</StyledLabel>
                        <StyledField id="group" name="group" />
                        {errors.group && touched.group ? (
                            <Error>{errors.group || "Group is required"}</Error>
                        ) : null}

                        <StyledLabel htmlFor="participating">
                            Participating <Required />
                        </StyledLabel>
                        <StyledCheckboxField
                            id="participating"
                            name="participating"
                            type="checkbox"
                            defaultChecked={centre.participating}
                        />
                        {errors.participating && touched.participating ? (
                            <Error>
                                {errors.participating ||
                                    "Participation is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="address_1">
                            Address 1 <Required />
                        </StyledLabel>
                        <StyledField id="address_1" name="address_1" />
                        {errors.address_1 && touched.address_1 ? (
                            <Error>
                                {errors.address_1 || "Address 1 is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="address_2">Address 2</StyledLabel>
                        <StyledField id="address_2" name="address_2" />
                        {errors.address_2 && touched.address_2 ? (
                            <Error>
                                {errors.address_2 || "Address 2 is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="town">
                            Town <Required />
                        </StyledLabel>
                        <StyledField id="town" name="town" />
                        {errors.town && touched.town ? (
                            <Error>{errors.town || "Town is required"}</Error>
                        ) : null}

                        <StyledLabel htmlFor="county">County</StyledLabel>
                        <StyledField id="county" name="county" />
                        {errors.county && touched.county ? (
                            <Error>
                                {errors.county || "County is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="postcode">
                            Postcode <Required />
                        </StyledLabel>
                        <StyledField id="postcode" name="postcode" />
                        {errors.postcode && touched.postcode ? (
                            <Error>
                                {errors.postcode || "Postcode is required"}
                            </Error>
                        ) : null}

                        <h3 style={{ marginTop: "2rem" }}>Contact Details</h3>

                        <StyledLabel htmlFor="telephone">
                            Telephone <Required />
                        </StyledLabel>
                        <StyledField id="telephone" name="telephone" />
                        {errors.telephone && touched.telephone ? (
                            <Error>
                                {errors.telephone || "Telephone is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="email">
                            Email <Required />
                        </StyledLabel>
                        <StyledField id="email" name="email" />
                        {errors.email && touched.email ? (
                            <Error>{errors.email || "Email is required"}</Error>
                        ) : null}

                        <StyledLabel htmlFor="website">Website</StyledLabel>
                        <StyledField
                            id="website"
                            name="website"
                            placeholder="www..."
                        />
                        {errors.website && touched.website ? (
                            <Error>
                                {errors.website || "Website is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="facebook">
                            Facebook Link
                        </StyledLabel>
                        <StyledField id="facebook" name="facebook" />
                        {errors.facebook && touched.facebook ? (
                            <Error>
                                {errors.facebook || "Facebook is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="facebook_tag">
                            Facebook Handle
                        </StyledLabel>
                        <StyledField
                            id="facebook_tag"
                            name="facebook_tag"
                            placeholder="@..."
                        />
                        {errors.facebook_tag && touched.facebook_tag ? (
                            <Error>
                                {errors.facebook_tag ||
                                    "Facebook handle is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="twitter">
                            Twitter Link
                        </StyledLabel>
                        <StyledField id="twitter" name="twitter" />
                        {errors.twitter && touched.twitter ? (
                            <Error>
                                {errors.twitter || "Twitter is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="twitter_tag">
                            Twitter Handle
                        </StyledLabel>
                        <StyledField
                            id="twitter_tag"
                            name="twitter_tag"
                            placeholder="@..."
                        />
                        {errors.twitter_tag && touched.twitter_tag ? (
                            <Error>
                                {errors.twitter_tag ||
                                    "Twitter handle is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="youtube">
                            Youtube Link
                        </StyledLabel>
                        <StyledField id="youtube" name="youtube" />
                        {errors.youtube && touched.youtube ? (
                            <Error>
                                {errors.youtube || "Youtube is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="youtube_tag">
                            Youtube Handle
                        </StyledLabel>
                        <StyledField
                            id="youtube_tag"
                            name="youtube_tag"
                            placeholder="@..."
                        />
                        {errors.youtube_tag && touched.youtube_tag ? (
                            <Error>
                                {errors.youtube_tag ||
                                    "Youtube handle is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="instagram">
                            Instagram Link
                        </StyledLabel>
                        <StyledField id="instagram" name="instagram" />
                        {errors.instagram && touched.instagram ? (
                            <Error>
                                {errors.instagram || "Instagram is required"}
                            </Error>
                        ) : null}

                        <StyledLabel htmlFor="instagram_tag">
                            Instagram Handle
                        </StyledLabel>
                        <StyledField id="instagram_tag" name="instagram_tag" />
                        {errors.instagram_tag && touched.instagram_tag ? (
                            <Error>
                                {errors.instagram_tag ||
                                    "Instagram handle is required"}
                            </Error>
                        ) : null}

                        {submitError && <Error>{submitError}</Error>}
                        {submitSuccessful && (
                            <div
                                style={{
                                    padding: "3rem 0 0",
                                }}
                            >
                                <h4>Centre updated successfully</h4>
                            </div>
                        )}
                        <SubmitSection>
                            <SubmitButton hasArrow={true} type="submit">
                                Submit
                            </SubmitButton>
                            {submitting && <Loader />}
                        </SubmitSection>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CentreForm;
