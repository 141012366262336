import React, { createElement, useState, useEffect } from "react";
import axios from "axios";
import { Route, Redirect } from "react-router-dom";
import { Error } from "@cortexglobal/rla-components";
import LoadingPage from "../LoadingPage";
import { getItem, setItem } from "@cortexglobal/cortex-utilities";

// auth
import { AuthContext } from "@cortexglobal/lens-auth";
import { setupAuth } from "@cortexglobal/lens-auth";

import { useLocation } from "react-router-dom";

const PrivateRoute = (props) => {
    const { component } = props;

    const [auth, setAuth] = useState({
        loadingState: "loading",
        loggedIn: false,
        user: {},
    });

    const query = new URLSearchParams(useLocation().search);
    const urlToken = query.get("token");
    useEffect(() => {
        const localToken = getItem("access_token");
        // console.log(localToken);
        if (localToken && localToken.access_token !== null) {
            setupAuth().then((authDetails) => {
                setAuth(authDetails);
            });
        } else if (urlToken) {
            axios
                .post(`/api/v1/login-token`, { token: urlToken })
                .then(({ data }) => {

                    Promise.all([
                        setItem("token", data.data.access_token),
                        setItem(
                            "access_token",
                            `Bearer ${data.data.access_token}`
                        ),
                    ]).then((values) => {
                        setupAuth().then((authDetails) => {
                            setAuth(authDetails);
                        });
                    });
                })
                .catch(({ data }) => {
                    // console.log(data);
                });
        } else {
            setAuth({
                loadingState: "loaded",
                loggedIn: false,
                user: {},
            });
        }
    }, []);

    const setLoadingState = (state) => {
        const states = ["loading", "loaded", "error"];

        if (!states.includes(state)) {
            throw new Error("Auth loading state not recognised");
        }

        setAuth({ ...auth, loadingState: state });
    };

    const render = (loadingState, loggedIn) => {
        switch (true) {
            case loadingState === "loading":
                return <LoadingPage title="Loading" />;

            case loadingState === "loaded" && loggedIn:
                return (
                    <Route
                        {...props}
                        render={() => createElement(component, props)}
                    />
                );

            case loadingState === "loaded" && !loggedIn:
                return (
                    <Redirect
                        to={{
                            pathname: "/auth/login",
                            state: { from: props.location },
                        }}
                    />
                );

            default:
                return <Error />;
        }
    };

    return (
        <AuthContext.Provider value={{ ...auth, setLoadingState }}>
            {render(auth.loadingState, auth.loggedIn)}
        </AuthContext.Provider>
    );
};

export default PrivateRoute;
