import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";
import ImportListing from "./ImportListing";

const Imports = (props) => {
    const [imports, setImports] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loadingState, setLoadingState] = useState("loaded");

    const refreshList = () => {
        getList(1);
    };

    const getList = (page) => {
        setLoadingState("loading");

        const params = {
            page: page === undefined ? 1 : page,
        };

        axios
            .get(`/api/v1/imports`, { params: params })
            .then(({ data }) => {
                setImports(data.data);
                setPagination(data.meta);
                setLoadingState("loaded");
            })
            .catch((e) => {
                setLoadingState("error");
            });
    };

    useEffect(() => {
        getList(1);
    }, []);

    return (
        <>
            <PageTitle title="Imports" />

            <Row style={{ maxWidth: '100%' }}>
                <Column medium={12}>
                    <Panel>
                        <Row style={{ maxWidth: '100%' }}>
                            <Column medium={12}>
                                <ImportListing
                                    imports={imports}
                                    pagination={pagination}
                                    reload={getList}
                                    loadingState={loadingState}
                                />
                            </Column>
                        </Row>
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default Imports;
