import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Loading, PlainButton } from "@cortexglobal/rla-components";
import { Loader, SubmitButton } from "../../components/FormComponents";
import ActivityLogForm from "./forms/ActivityLogForm";
import {
    Table,
    TableHeader,
    TableRow,
    TableData,
} from "../../components/TableComponents";

const ActivityLog = ({
    registration,
    refreshRegistrations,
    refreshRegistration,
    activities,
    setActivities,
}) => {
    const [editingActivityUuid, setEditingActivityUuid] = useState("");
    const [adding, setAdding] = useState(false);
    const [error, setError] = useState(false);
    const [deleting, setDeleting] = useState();

    const [options, setOptions] = useState({
        loaded: false,
        data: {},
    });

    useEffect(() => {
        if (activities.loaded === true) {
            setOptions(activities);
        } else {
            axios
                .get(`/api/v1/activity-logs/settings`)
                .then(({ data }) => {
                    setActivities({ loaded: true, data });
                    setOptions({ loaded: true, data });
                })
                .catch(({ response: { data } }) => {
                    setError(true);
                });
        }
    }, []);

    const deleteActivity = (uuid) => {
        setDeleting(uuid);
        return axios
            .delete(`api/v1/activity-logs/${uuid}`)
            .then(({ data }) => {
                refreshRegistration({
                    ...registration,
                    activity_logs: registration.activity_logs.filter(
                        (log) => log.uuid !== uuid
                    ),
                });
                setDeleting();
            })
            .catch((e) => {
                setDeleting();
                // console.log(e);
            });
    };

    if (error) {
        return (
            <>
                <h3>Sorry</h3>
                <p>
                    There was an error loading the benefits, please try again
                    later or contact support
                </p>
            </>
        );
    }
    const { activity_logs } = registration;
    return (
        <div style={{ padding: "2rem" }}>
            <h3 style={{ marginBottom: "2rem" }}>
                Benefit Claims
            </h3>
            {!options.loaded ? (
                <Loading />
            ) : (
                <>
                    {registration.status === "approved" ? (
                        <div style={{ marginBottom: "2rem" }}>
                            {adding ? (
                                <ActivityLogForm
                                    options={options.data}
                                    registration={registration}
                                    setAdding={setAdding}
                                    setEditingActivityUuid={
                                        setEditingActivityUuid
                                    }
                                    refreshRegistrations={refreshRegistrations}
                                    refreshRegistration={refreshRegistration}
                                    onSubmitted={() => setAdding(false)}
                                />
                            ) : (
                                <SubmitButton
                                    onClick={() => setAdding(true)}
                                    hasArrow={true}
                                >
                                    Add a new Claim
                                </SubmitButton>
                            )}
                        </div>
                    ) : (
                        <p>
                            New claims can not be added to an inactive
                            registration.
                        </p>
                    )}

                    {!adding && (
                        <>
                            {!activity_logs || activity_logs.length === 0 ? (
                                <p>
                                    No claims have been recorded for this
                                    registration.
                                </p>
                            ) : (
                                <Table>
                                    <thead>
                                        <TableRow>
                                            <TableHeader>
                                                Claim Date
                                            </TableHeader>
                                            <TableHeader>Type</TableHeader>
                                            <TableHeader className="center">
                                                Actions
                                            </TableHeader>
                                        </TableRow>
                                    </thead>
                                    <tbody>
                                        {activity_logs.map((activity) => {
                                            if (
                                                editingActivityUuid ===
                                                activity.uuid
                                            ) {
                                                return (
                                                    <TableRow
                                                        key={activity.uuid}
                                                    >
                                                        <TableData colSpan="100%">
                                                            <ActivityLogForm
                                                                options={
                                                                    options.data
                                                                }
                                                                registration={
                                                                    registration
                                                                }
                                                                setAdding={
                                                                    setAdding
                                                                }
                                                                setEditingActivityUuid={
                                                                    setEditingActivityUuid
                                                                }
                                                                refreshRegistrations={
                                                                    refreshRegistrations
                                                                }
                                                                refreshRegistration={
                                                                    refreshRegistration
                                                                }
                                                                activity={
                                                                    activity
                                                                }
                                                                onSubmitted={() =>
                                                                    setEditingActivityUuid(
                                                                        ""
                                                                    )
                                                                }
                                                            />
                                                        </TableData>
                                                    </TableRow>
                                                );
                                            }
                                            return (
                                                <TableRow key={activity.uuid}>
                                                    <TableData>
                                                        {moment
                                                            .utc(
                                                                activity.redemption_date
                                                            )
                                                            .format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                    </TableData>
                                                    <TableData>
                                                        {
                                                            activity.activity_type_readable
                                                        }
                                                    </TableData>
                                                    <TableData className="center">
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            {deleting !==
                                                            activity.uuid ? (
                                                                <>
                                                                    <PlainButton
                                                                        style={{
                                                                            marginRight:
                                                                                "1rem",
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            onClick={() =>
                                                                                setEditingActivityUuid(
                                                                                    activity.uuid
                                                                                )
                                                                            }
                                                                            icon="edit"
                                                                        />
                                                                    </PlainButton>

                                                                    <PlainButton>
                                                                        <FontAwesomeIcon
                                                                            onClick={() =>
                                                                                deleteActivity(
                                                                                    activity.uuid
                                                                                )
                                                                            }
                                                                            icon="trash-alt"
                                                                        />
                                                                    </PlainButton>
                                                                </>
                                                            ) : (
                                                                <Loader />
                                                            )}
                                                        </div>
                                                    </TableData>
                                                </TableRow>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default ActivityLog;
