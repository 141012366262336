import React, { useState } from "react";
import { Modal } from "@cortexglobal/rla-components";
import { ButtonDropdown } from "@cortexglobal/lens-components";

import { TableData, TableRow } from "../../../components/TableComponents";
import UserForm from "./UserForm";

const UserRow = ({ user, refreshUser }) => {
    const [editing, setEditing] = useState(false);

    const generateActions = () => {
        return [
            {
                name: "Edit",
                onClick: () => {
                    setEditing(true);
                },
            },
        ];
    };

    const closeEditModal = () => {
        setEditing(false);
    };

    return (
        <>
            <TableRow style={user.new ? { backgroundColor: "#EAFFE8" } : {}}>
                <TableData>
                    {user.title + " " + user.last_name + ", " + user.first_name}
                </TableData>
                <TableData>
                    {user.centre
                        ? user.centre.name || "Head Office"
                        : "Head Office"}
                </TableData>
                <TableData>{user.email}</TableData>
                <TableData>{user.status_wording}</TableData>
                <TableData>
                    <ButtonDropdown
                        actions={generateActions()}
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </ButtonDropdown>
                </TableData>
            </TableRow>

            {editing && (
                <Modal
                    visible={editing}
                    onClose={() => setEditing(false)}
                    maxWidth="75%"
                >
                    <div style={{ padding: "2rem" }}>
                        <UserForm
                            user={user}
                            refreshUser={refreshUser}
                            successCallback={closeEditModal}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default UserRow;
