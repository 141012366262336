import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
    Error,
    Loader,
    Required,
    StyledField,
    StyledLabel,
    SubmitButton,
    SubmitSection,
} from "../../../components/FormComponents";

const Schema = Yup.object().shape({
    registration: Yup.string().required("Registration is required"),
    postcode: Yup.string().required("Postcode is required"),
});

export const PreStep = ({
    onSubmit,
    submitting
}) => {
    return (
        <>
            <h3>Before you begin, please enter your details:</h3>

            <Formik
                initialValues={{
                    registration: "",
                    postcode: ""
                }}
                validationSchema={Schema}
                onSubmit={onSubmit}
            >
                {({ errors, touched, setFieldValue, values }) => {
                    return (
                        <Form>
                            <StyledLabel htmlFor="registration">
                                Registration <Required />
                            </StyledLabel>
                            <StyledField
                                error={
                                    errors.registration &&
                                    touched.registration
                                }
                                id="registration"
                                name="registration"
                            />
                            {errors.registration &&
                            touched.registration ? (
                                <Error>
                                    {errors.registration}
                                </Error>
                            ) : null}

                            <StyledLabel htmlFor="postcode">
                                Postcode <Required />
                            </StyledLabel>
                            <StyledField
                                error={
                                    errors.postcode &&
                                    touched.postcode
                                }
                                id="postcode"
                                name="postcode"
                            />
                            {errors.postcode &&
                            touched.postcode ? (
                                <Error>
                                    {errors.postcode}
                                </Error>
                            ) : null}

                            <SubmitSection>
                                <SubmitButton hasArrow color="red" type="submit">
                                    Submit
                                </SubmitButton>
                                {submitting && <Loader />}
                            </SubmitSection>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default PreStep;