import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

// Sentry error reporting imports
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { merge, isEmpty } from "lodash";
import App from "./App";
import { ThemeProvider, ToastProvider } from "@cortexglobal/rla-components";
import theme from "./config/theme";
import TagManager from 'react-gtm-module'

require("./config/icons");
require("./config/axiosConfig");


const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (!isEmpty(sentryDsn)) {
    const sentrySamplerRate =
        process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 0.2;

    Sentry.init({
        dsn: sentryDsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: sentrySamplerRate,
    });
}
const tagManagerArgs = {
    gtmId: 'GTM-MSNT3W3'
}

TagManager.initialize(tagManagerArgs)

const renderApp = () => {
    return (
        <HelmetProvider>
            <ThemeProvider theme={merge(theme)}>
                <ToastProvider>
                    <Router>
                        <App />
                    </Router>
                </ToastProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
};

ReactDOM.render(renderApp(), document.getElementById("root"));
