import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Pagination, SelectField } from "@cortexglobal/rla-components";
import { isEmpty } from "lodash";
import LoadingPage from "../../../components/LoadingPage";
import {
    FlexRow,
    LimitedWidthRow,
    LittleLoader,
} from "../../../components/LayoutComponents";
import {
    Table,
    TableHeader,
    TableRow,
} from "../../../components/TableComponents";
import styled from "styled-components";
import EmailRow from "./EmailRow";
import { useParams } from "react-router-dom";
import { StyledInput } from "../../../components/FormComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const EmailCampaigns = (props) => {
    const [emails, setEmails] = useState({
        data: [],
        pagination: {},
        loading: true,
    });

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    const [search, setSearch] = useState("");

    const { uuid } = useParams("uuid");

    useEffect(() => {
        loadEmails();
    }, []);

    const loadEmails = (page = 1, perPage = 25, search = "") => {
        setEmails({ ...emails, loading: true, loaded: false });

        const url = uuid ? `api/v1/customer/${uuid}/emails` : "api/v1/emails";

        return axios
            .get(`${url}?page=${page}&per_page=${perPage}&search=${search}`)
            .then(({ data }) => {
                setEmails({
                    loaded: true,
                    loading: false,
                    data: data.data,
                    pagination: data.meta,
                });
            });
    };

    const refreshEmail = (newEmail) => {
        const newEmails = emails.data.map((email) =>
            newEmail.identifier === email.identifier
                ? { ...email, ...newEmail }
                : email
        );

        setEmails({
            ...emails,
            data: newEmails,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPage(value);

            loadEmails(page, value);
        }
    };

    return (
        <LimitedWidthRow>
            <FlexRow style={{ marginBottom: "2rem" }}>
                <h3 style={{ flexGrow: "1" }}>Emails</h3>
                {emails.loading && <LittleLoader icon="circle-notch" spin />}

                <FlexRow>
                    <StyledInput
                        style={{
                            marginBottom: "0",
                        }}
                        value={search}
                        onChange={(input) => setSearch(input.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                loadEmails(page, perPage, search);
                            }
                        }}
                    />
                    <Button onClick={() => loadEmails(page, perPage, search)}>
                        <FontAwesomeIcon icon="search" />
                    </Button>
                </FlexRow>
            </FlexRow>

            {emails.loaded ? (
                emails.data.length > 0 ? (
                    <>
                        <Table>
                            <thead>
                                <TableRow>
                                    <TableHeader>ID</TableHeader>
                                    <TableHeader>To</TableHeader>
                                    <TableHeader>Subject</TableHeader>
                                    <TableHeader>Sent</TableHeader>
                                    <TableHeader>Delivered</TableHeader>
                                    <TableHeader>Opened</TableHeader>
                                    <TableHeader>Clicked</TableHeader>
                                    <TableHeader>Complained</TableHeader>
                                    <TableHeader>Failed</TableHeader>
                                    <TableHeader>Unsubscribed</TableHeader>
                                </TableRow>
                            </thead>
                            <tbody>
                                {emails.data.map((email) => {
                                    return (
                                        <EmailRow
                                            email={email}
                                            refreshEmail={refreshEmail}
                                            key={email.identifier}
                                        />
                                    );
                                })}
                            </tbody>
                        </Table>
                    </>
                ) : (
                    <div>No emails found</div>
                )
            ) : (
                <LoadingPage title="Loading emails" />
            )}

            {!isEmpty(emails.pagination) && (
                <PaginationContainer>
                    <PerPageContainer>
                        Per Page:
                        <SelectField
                            name="perPage"
                            style={{
                                border: "1px solid grey",
                                margin: "0 0 0 1em",
                                width: "auto",
                                borderRadius: "3px",
                            }}
                            value={perPage || 25}
                            options={[
                                { value: 25, text: "25" },
                                { value: 50, text: "50" },
                                { value: 100, text: "100" },
                                { value: "all", text: "All" },
                            ]}
                            onChange={handlePerPage}
                        />
                    </PerPageContainer>
                    <Pagination
                        currentPage={page - 1}
                        total={emails.pagination.total}
                        pageCount={emails.pagination.last_page}
                        onPageChange={({ selected }) => {
                            setPage(selected + 1);
                            loadEmails(selected + 1, perPage);
                        }}
                        previousLabel="&laquo;"
                        nextLabel="&raquo;"
                    />
                </PaginationContainer>
            )}
        </LimitedWidthRow>
    );
};

export default EmailCampaigns;
