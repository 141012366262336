import React from "react";

const ModelButtons = ({ children }) => {
    return (
        <div
            style={{ display: "flex", alignItems: "center", marginTop: "40px" }}
        >
            {children}
        </div>
    );
};

export default ModelButtons;
