import React, { useState } from "react";
import { get, isEmpty } from "lodash";

import InputField from "@cortexglobal/rla-components/lib/components/form/standardReturnElements/input";
import { Loader, SubmitButton } from "../../../components/FormComponents";
import axios from "axios";

import { Modal, useAddToast } from "@cortexglobal/rla-components";

import ModalErrorMessage from "./components/ModalErrorMessage";
import ModelButtons from "./components/ModelButtons";

const BPModal = ({
    registration,
    refreshRegistration,
    visible,
    setVisible,
    maxWidth = "50%",
    setUpdating,
}) => {
    const { uuid, customer } = registration;

    const addToast = useAddToast();

    const [BPNumber, setBPNumber] = useState();
    const [loading, setLoading] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const handleBPNumber = () => {
        setLoading(true);
        setUpdating(true);

        axios
            .post(`api/v1/registrations/${uuid}/bp`, {
                bp_number: BPNumber,
            })
            .then(({ data }) => {
                refreshRegistration(data.data);

                setBPNumber("");
                setVisible(false);

                addToast({
                    type: "success",
                    content: "BP number successfully added.",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setValidationErrors(data.errors);
                    } else {
                        setBPNumber("");
                    }

                    if (data.message !== undefined) {
                        setErrorMessage(data.message);
                    }
                }

                addToast({
                    type: "alert",
                    content: "There was an error adding BP Number.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setLoading(false);
                setUpdating(false);
            });
    };

    return (
        <Modal
            visible={visible}
            onClose={() => {
                setVisible(false);
                setBPNumber("");
            }}
            maxWidth={maxWidth}
        >
            <div style={{ padding: "2rem" }}>
                <h3>Add BP Number</h3>

                {!isEmpty(errorMessage) && (
                    <ModalErrorMessage>{errorMessage}</ModalErrorMessage>
                )}

                <InputField
                    label="BP Number"
                    name="bp_number"
                    value={BPNumber}
                    onChange={({ value }) => setBPNumber(value)}
                    error={get(validationErrors, "bp_number")}
                />

                <ModelButtons>
                    <SubmitButton color="red" hasArrow onClick={handleBPNumber}>
                        Set BP Number
                    </SubmitButton>
                    {loading && <Loader />}
                </ModelButtons>
            </div>
        </Modal>
    );
};

export default BPModal;
