import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Logo from "../../components/Logo";
import LoadingPage from "../../components/LoadingPage";

import {
    Container,
    SubmitButton,
    LogoContainer,
} from "../../components/FormComponents";

import ErrorPage from "../../components/ErrorPage";
import VehicleConfirmation from "./steps/VehicleConfirmation";
import DetailsConfirmation from "./steps/DetailsConfirmation";
import DetailsForm from "./steps/DetailsForm";
import PorscheCentreConfirmation from "./steps/PorscheCentreConfirmation";
import PorscheCentreForm from "./steps/PorscheCentreForm";
import ProgressBar from "./steps/ProgressBar";
import { UnstyledButton } from "../../components/registration/registrationComponents";
import Confirmation from "./steps/Confirmation";
import RenewCheck from "./steps/RenewCheck";

import { useQueryString } from "@cortexglobal/lens-components";
import PreStep from "./steps/PreStep";

export const DetailsContainer = styled.div`
    padding: 2em;
    border: 2px solid black;

    td {
        padding-bottom: 7px;
    }
`;

export const DetailsLabel = styled.td`
    font-weight: bold;
    margin-right: 1em;
    display: flex;
    align-items: center;
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address_1: Yup.string().required("Address is required"),
    address_2: Yup.string().nullable(),
    town: Yup.string().required("Town is required"),
    county: Yup.string().required("County is required"),
    postcode: Yup.string().required("Postcode is required"),
    telephone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Telephone is required"),
    email: Yup.string().required("Email is required"),
    centre_uuid: Yup.string().required("Centre is required"),
    registration: Yup.string().required("Registration is required"),
    confirmed_membership: Yup.string().required(
        "Confirmed membership is required"
    ),
    privacy_policy: Yup.string().required("Privacy policy is required"),
    vin: Yup.string().required("VIN Number is required"),
    model: Yup.string().required("Model is required"),
    year: Yup.string().required("Year is required"),
});

const RenewalForm = () => {
    const history = useHistory();

    const [postcodeError, setPostcodeError] = useState(false);
    const [postcodeLoading, setPostcodeLoading] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [newCentresLoading, setNewCentresLoading] = useState(false);
    const [dataProtectionState, setDataProtectionState] = useState("unchecked");
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [validationMessage, setValidationMessage] = useState("");
    const [registrationError, setRegistrationError] = useState("");

    const [preLoading, setPreLoading] = useState(false);

    const [newPorscheCentre, setNewPorscheCentre] = useState();

    const [settings, setSettings] = useState({
        state: "pre",
        data: {},
        distancesLoaded: false,
    });

    const [step, setStep] = useState(0);

    const id = useQueryString().get("id");

    const setRenewalState = (state) => {
        setSettings({
            ...settings,
            state: state,
        });
    };

    const onPreSubmit = (values) => {
        setPreLoading(true);

        getData(values.registration, values.postcode);
    }

    const onSubmit = (values) => {
        if (!id) {
            return;
        }

        setRenewalState("pending");
        setSubmitting(true);

        axios
            .post(`/api/v1/renewal/${id}`, values)
            .then((data) => {
                setRenewalState("complete");
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setValidationErrors(data.errors);
                        setValidationMessage(data.message);
                        // console.log("?",data.errors);
                        // console.log("?",data.message);
                    } else {
                        setRenewalState("error");
                    }
                } else {
                    setRenewalState("error");
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleNewVehicleClick = () => {
        history.push("/");
    };

    const handlePostcodeSearch = (postcode) => {
        setPostcodeError(false);
        setPostcodeLoading(true);

        return axios
            .get(`/api/v1/register/postcode/${postcode}`)
            .then(({ data }) => {
                setAddressList(data.addresses);
                setPostcodeLoading(false);
                setSettings({
                    ...settings,
                    data: {
                        ...settings.data,
                        centres: data.centres.sort(
                            (a, b) => a.distance - b.distance
                        ),
                    },
                    distancesLoaded: true,
                });
            })
            .catch(({ response }) => {
                setPostcodeLoading(false);
                setPostcodeError(true);
            });
    };

    const getData = (reg, postcode) => {
        if (!id) {
            return;
        }

        setDataProtectionState("unchecked");

        axios
            .all([
                axios.get(`/api/v1/register/settings`),
                axios.get(
                    `/api/v1/renewal/${id}?registration=${reg}&postcode=${postcode}`
                ),
            ])
            .then(data => {
                setDataProtectionState("passed");
                setPreLoading(false);

                const renewal = data[1].data.data;

                setSettings({
                    data: data[0].data.data,
                    state: "loaded",
                    renewal: renewal,
                });

                setAddressList([
                    {
                        line_1: renewal.customer.address_1,
                        line_2: renewal.customer.address_2,
                        town_or_city: renewal.customer.town,
                        county: renewal.customer.county,
                    },
                ]);
            })
            .catch((e) => {
                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    const { message } = data;

                    if (status === 403) {
                        setDataProtectionState("error");
                    } else {
                        setRenewalState("error");

                        if (message) {
                            setRegistrationError(message);
                        }
                    }

                    if (message === "This registration has expired") {
                        history.push("/");
                    }

                    setSettings({
                        state: "error",
                    });
                }
            });
    };

    const handleNewCentreClick = (postcode) => {
        if (!settings.distancesLoaded) {
            setNewCentresLoading(true);
            handlePostcodeSearch(postcode).then(() => {
                setNewCentresLoading(false);
                setStep(3.5);
            });
        } else {
            setStep(3.5);
        }
    };

    // useEffect(() => {
    //     getData();
    // }, []);

    const renderStep = (
        touched,
        values,
        setFieldValue,
        errors,
        titles,
        centres
    ) => {
        if (step === 0) {
            return (
                <RenewCheck
                    setStep={setStep}
                    id={id}
                />
            );
        }

        if (step === 1) {
            return (
                <VehicleConfirmation
                    setStep={setStep}
                    vehicle={settings.renewal.vehicle}
                />
            );
        }

        if (step === 1.5) {
            return (
                <>
                    <h3>
                        Do you own a different classic Porsche you would like to
                        register?
                    </h3>

                    <SubmitButton
                        onClick={() => setRenewalState("goodbye")}
                        style={{ marginRight: "1em" }}
                        color="grey"
                        type="button"
                    >
                        No
                    </SubmitButton>
                    <SubmitButton
                        onClick={handleNewVehicleClick}
                        hasArrow
                        color="red"
                        type="button"
                    >
                        Yes
                    </SubmitButton>

                    <div
                        onClick={() => setStep(1)}
                        style={{ marginTop: "1em" }}
                    >
                        <UnstyledButton type="button">
                            {"< Go Back"}
                        </UnstyledButton>
                    </div>
                </>
            );
        }

        if (step === 2) {
            return (
                <DetailsConfirmation
                    customer={values}
                    setStep={setStep}
                />
            );
        }

        if (step === 2.5) {
            return (
                <DetailsForm
                    setStep={setStep}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    titles={titles}
                    errors={errors}
                    handlePostcodeSearch={handlePostcodeSearch}
                    postcodeError={postcodeError}
                    postcodeLoading={postcodeLoading}
                    addressList={addressList}
                />
            );
        }

        if (step === 3) {
            return (
                <PorscheCentreConfirmation
                    values={values}
                    newPorscheCentre={newPorscheCentre}
                    centre={settings.renewal.centre}
                    handleNewCentreClick={handleNewCentreClick}
                    setStep={setStep}
                    newCentresLoading={newCentresLoading}
                />
            );
        }

        if (step === 3.5) {
            return (
                <PorscheCentreForm
                    centres={centres}
                    setFieldValue={setFieldValue}
                    setNewPorscheCentre={setNewPorscheCentre}
                    values={values}
                    settings={settings}
                    touched={touched}
                    errros={errors}
                    setStep={setStep}
                />
            );
        }

        if (step === 4) {
            return (
                <Confirmation
                    newPorscheCentre={newPorscheCentre}
                    centre={settings.renewal.centre}
                    customer={settings.renewal.customer}
                    vehicle={settings.renewal.vehicle}
                    setFieldValue={setFieldValue}
                    values={values}
                    touched={touched}
                    settings={settings}
                    setStep={setStep}
                    submitting={submitting}
                    errors={errors}
                    validationMessage={validationMessage}
                    validationErrors={validationErrors}
                    setValidationMessage={setValidationMessage}
                    setValidationErrors={setValidationErrors}
                />
            );
        }
    };

    const render = (state) => {
        if (!id) {
            return (
                <ErrorPage>
                    There was an error whilst loading the Porsche Classic
                    Register renewal form, please call your local Porsche Centre
                    for assistance or try again later.
                </ErrorPage>
            );
        }

        if (dataProtectionState === "error") {
            return (
                <ErrorPage>
                    Data protection failed. Please check the details you have
                    provided.
                </ErrorPage>
            );
        }

        if (state === "pre") {
            return (
                <Container>
                    <PreStep
                        onSubmit={onPreSubmit}
                        submitting={preLoading}
                    />
                </Container>
            )
        }

        if (state === "loading") {
            return <LoadingPage />;
        }

        if (state === "error") {
            return (
                <ErrorPage>
                    {registrationError
                        ? registrationError
                        : "Register renewal form, please call your local Porsche Centre for assistance or try again later."}
                </ErrorPage>
            );
        }

        if (state === "complete") {
            return (
                <Container>
                    <h3 style={{ textAlign: "center", marginTop: "1em" }}>
                        Thank you. Your renewal application has been submitted.
                    </h3>
                </Container>
            );
        }

        if (state === "goodbye") {
            return (
                <Container>
                    <h3 style={{ textAlign: "center", marginTop: "1em" }}>
                        Thank you for your time as a Porsche Classic member
                    </h3>
                </Container>
            );
        }

        return (
            <Container>
                <ProgressBar
                    step={step}
                    setStep={setStep}
                />

                <h1 className="title" style={{ marginBottom: "3rem" }}>
                    Renew your Porsche Classic Register Membership
                </h1>

                <Formik
                    initialValues={{
                        title: settings.renewal.customer.title,
                        first_name: settings.renewal.customer.first_name,
                        last_name: settings.renewal.customer.last_name,
                        address_1: settings.renewal.customer.address_1,
                        address_2: settings.renewal.customer.address_2,
                        town: settings.renewal.customer.town,
                        county: settings.renewal.customer.county,
                        postcode: settings.renewal.customer.postcode,
                        telephone: settings.renewal.customer.telephone,
                        email: settings.renewal.customer.email,
                        centre_uuid: settings.renewal.centre.uuid,
                        vin: settings.renewal.vehicle.vin,
                        registration: settings.renewal.vehicle.registration,
                        confirmed_membership: false,
                        privacy_policy: false,
                        model: settings.renewal.vehicle.model,
                        year: settings.renewal.vehicle.year,
                    }}
                    validationSchema={Schema}
                    onSubmit={onSubmit}
                >
                    {({ errors, touched, setFieldValue, values }) => {
                        const titles =
                            state === "loading"
                                ? ["Loading..."]
                                : settings.data.titles;
                        const centres =
                            state === "loading"
                                ? { "": "Loading..." }
                                : settings.data.centres;
                        const models =
                            state === "loading"
                                ? { "": "Loading..." }
                                : settings.data.models;

                        return (
                            <Form>
                                {renderStep(
                                    touched,
                                    values,
                                    setFieldValue,
                                    errors,
                                    titles,
                                    centres,
                                    submitting
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        );
    };

    return (
        <>
            <LogoContainer>
                <Logo />
            </LogoContainer>
            {render(settings.state)}
        </>
    );
};

export default RenewalForm;
