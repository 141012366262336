import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Pagination,
    SelectField,
    useAddToast
} from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import axios from "axios";
import { CubeLoader } from "../index";
import queryString from "querystring";
import {
    useLocation
} from "react-router-dom";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
} from "../table/StyledTable";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center; 
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyContacts = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    contacts,
    meta,
}) => {

    console.log(contacts);

    return (
        <>
            <StyledTable
                className="contacts-table"
            >
                <thead>
                <tr>
                    <StyledTableHeader
                        name="name"
                    >
                        Name
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="email"
                    >
                        Email
                    </StyledTableHeader>
                    <StyledTableHeader
                        name="phone"
                    >
                        Phone
                    </StyledTableHeader>
                    <StyledTableHeader/>
                </tr>
                </thead>
                <tbody>
                {contacts.map(contact => (
                    <StyledTableRow
                        key={contact.id}
                    >
                        <td>
                            {contact.first_name} {contact.last_name}
                        </td>
                        <td>
                            {contact.email}
                        </td>
                        <td>
                            {contact.phone}
                        </td>
                        <td>
                            <Link to={`/contacts/${contact.id}`}>
                                <Button>View Contact</Button>
                            </Link>
                        </td>
                    </StyledTableRow>
                ))}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{ margin: '0 0 0 1em' }}
                        value={perPageValue}
                        options={[
                            {value: 10, text: '10'},
                            {value: 20, text: '20'},
                            {value: 30, text: '30'},
                            {value: 40, text: '40'},
                            {value: 50, text: '50'},
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={selectedPageValue - 1}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>
        </>
    );
};

export default CompanyContacts;