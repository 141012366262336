import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import { CubeLoader } from "../index";

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div`
    padding: 1em;
`;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const BoldTd = styled.td`
    font-weight: bold;
`;

const ViewContact = ({ match }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState();

    const getContact = () => {
        setLoading(true);

        axios
            .get(`/api/v1/contacts/${match.params.id}`)
            .then(({ data }) => {
                console.log(data.data);
                setContactData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting the contact.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getContact();
    }, []);

    if (loading) return <CubeLoader />

    return (
        <>
            <PageTitle title="Contact">
                <Link to="/contacts">
                    <Button>Back to contacts</Button>
                </Link>
            </PageTitle>

            {contactData &&
            <Row style={{ minWidth: '100%' }}>
                <Column>
                    <StyledPanel>
                        <PanelHeader>
                            Contact Details
                        </PanelHeader>
                        <PanelBody>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <BoldTd>Contact Name:</BoldTd>
                                    <td>
                                        {contactData.first_name} {contactData.last_name}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Contact Email:</BoldTd>
                                    <td>
                                        {contactData.email}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Telephone:</BoldTd>
                                    <td>
                                        {contactData.phone}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Job Title:</BoldTd>
                                    <td>
                                        {contactData.job_title}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Is Primary Contact:</BoldTd>
                                    <td>
                                        {contactData.primary_contact}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Can Send Email:</BoldTd>
                                    <td>
                                        {contactData.channel_email}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Can Send SMS:</BoldTd>
                                    <td>
                                        {contactData.channel_sms}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Can Send Post:</BoldTd>
                                    <td>
                                        {contactData.channel_post}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Permissions To Communicate For Perks:</BoldTd>
                                    <td>
                                        {contactData.permission_to_communicate_about_perks}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Permissions To Communicate For Parts:</BoldTd>
                                    <td>
                                        {contactData.permission_to_communicate_about_parts}
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Customer Linked:</BoldTd>
                                    <td>
                                        <Link to={`/companies/${contactData.customer_id}`}>
                                            <Button>View {contactData.customer}</Button>
                                        </Link>
                                    </td>
                                </tr>
                            </table>
                        </PanelBody>
                    </StyledPanel>
                </Column>

            </Row>
            }

        </>
    );
};

export default ViewContact;